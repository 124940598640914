import React, { Component } from "react";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area} from "recharts";
import { chartColors } from "../../../../../static/ChartColors";

class HistoricalChart extends Component {
    constructor(props) {
        super(props);

        // Get header row and data rows separately
        let header = props.chartData[0];
        let rows = props.chartData.slice(1);

        // Get indexes of timestamp and power columns
        let timestampIndex = header.indexOf('timestamp');
        let powerIndex = header.indexOf('power[kW]');
        let forecastIndex = header.indexOf('forecast[kW]')
        // let sunheightIndex = header.indexOf('sunheight');
        // Iterate over rows and create new objects with timestamp and power values
        let result = rows.map(row => {
            return {
                timestamp: row[timestampIndex],
                power: row[powerIndex],
                forecast: row[forecastIndex]
                // sunheight: row[sunheightIndex],
            };
        });

        let _latestTimestamp = result.map((res) => res.timestamp).sort((a, b) => new Date(b) - new Date(a))[0];

        // Subtract 48 hours (in milliseconds):
        const latestDate = new Date(_latestTimestamp);
        latestDate.setHours(latestDate.getHours() - 168);

        const dataInRange = this.getTimestampsInRange(result, latestDate, new Date(_latestTimestamp));
        const maxValue = Math.ceil((dataInRange.map((d) => d.power).sort((a, b) => b - a)[0])/100) * 100;


        this.state = {
            'data': result,
            'dataInRange': dataInRange,
            'maxValue': maxValue,
            'latestTimestamp': new Date(_latestTimestamp),
            'firstTimestamp': new Date(result[0].timestamp),
            'lastTimestamp': new Date(_latestTimestamp),
            'earliestTimestamp': latestDate,

        };
    }
    formatYAxisTick = (value) => Math.ceil(value);


    getTimestampsInRange(data, start, end) {
        const dataInRange = [];
        for (let i = 0; i < data.length; i++) {
            const timestamp = data[i].timestamp;
            if (new Date(timestamp) >= start && new Date(timestamp) <= end) {
                dataInRange.push(data[i]);
            }
        }

        return dataInRange;
    }


    handleChange = (event) => {
        const dataInRange = this.getTimestampsInRange(this.state.data, this.state.earliestTimestamp, new Date(event.target.value));
        this.setState({
            'latestTimestamp': new Date(event.target.value),
            'dataInRange': dataInRange,
            'maxValue': Math.ceil((dataInRange.map((d) => d.power).sort((a, b) => b - a)[0])/100) * 100,
        });
    }

    setEarliestTimestamp = (event) => {
        const dataInRange = this.getTimestampsInRange(this.state.data, new Date(event.target.value), this.state.latestTimestamp);
        this.setState({
            'earliestTimestamp': new Date(event.target.value),
            'dataInRange': dataInRange,
            'maxValue': Math.ceil((dataInRange.map((d) => d.power).sort((a, b) => b - a)[0])/100) * 100,
        });
    }



    render() {

        if (!this.props.chartData) {
            return(<div> Loading </div>)
          }
        // console.log(this.state.maxValue)

        return (
            <>
                {/* <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 10, marginTop: 40, }}>
                    <div style={{ paddingRight: 20, }}>
                        <label htmlFor="date" style={{ marginRight: 10, }}>Start:</label>
                        <input type="date" id="date" value={this.state.earliestTimestamp.toISOString().substr(0, 10)} onChange={this.setEarliestTimestamp} />
                    </div>
                    <div>
                        <label htmlFor="date" style={{ marginRight: 10, }}>End:</label>
                        <input type="date" id="date" value={this.state.latestTimestamp.toISOString().substr(0, 10)} onChange={this.handleChange} />
                    </div>
                </div> */}
                {/* <LineChart width={800} height={400} data={this.state.dataInRange} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <XAxis dataKey="timestamp" />
                    <YAxis type="number" domain={['dataMin', 1000]} />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="power" stroke="#8884d8" name="Predicted Power" />
                </LineChart> */}

                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 10, marginTop: 40, marginLeft:30 }}>
                        <div style={{ paddingRight: 20, }}>
                            <label htmlFor="date" style={{ marginRight: 10, }}>Start:</label>
                            <input type="date" min={this.state.firstTimestamp.toISOString().substr(0, 10)} max={this.state.lastTimestamp.toISOString().substr(0, 10)} id="date" value={this.state.earliestTimestamp.toISOString().substr(0, 10)} onChange={this.setEarliestTimestamp} />
                        </div>
                            <div>
                            <label htmlFor="date" style={{ marginRight: 10, }}>End:</label>
                            <input type="date" id="date" min={this.state.firstTimestamp.toISOString().substr(0, 10)} max={this.state.lastTimestamp.toISOString().substr(0, 10)} value={this.state.latestTimestamp.toISOString().substr(0, 10)} onChange={this.handleChange} />
                        </div>
                    </div>
                    <ResponsiveContainer width="100%" height={500}>
                        <LineChart
                            width={580}
                            height={300}
                            data={this.state.dataInRange}
                            margin={{ top: 20, right: 30, left: 5, bottom: 20 }}
                        >
                            {/* <XAxis dataKey="timestamp" label={{ value: 'Timestamp', position: 'insideBottom', dy: 10 }} /> */}
                            <XAxis dataKey="timestamp" />

                            <YAxis type="number" domain={[0, this.state.maxValue]} tickFormatter={this.formatYAxisTick} label={{ value: 'Power(kW)', angle: -90, position: 'insideLeft' }} />          <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend />
                            <Line dot={false} dataKey="power" type="monotone"stroke={chartColors['powerGenerated']} name="Generated Power" />
                            <Line dot={false} dataKey="forecast" type="monotone" stroke="#99cc00" name="Forecasted Power" />
                      
                            {/* <Area  dataKey="power" file={chartColors['powerGenerated']} stroke={chartColors['powerGenerated']} name="Generated Power" />
                            <Area dataKey="forecast" fill="#99cc00" stroke="#99cc00" name="Forecasted Power" /> */}
                      
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </>
        );
    }
}

export default HistoricalChart;