/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import DemoChart from "../../Charts/DemoChart";
import PlantsService from "../../../../app/modules/EForecast/pages/plants/PlantsService";

export function PowerWidget({ className, plant }) {

  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await PlantsService.getDemoData(plant?.id);
        setChartData(data);

      } catch (error) {
        console.error("Failed to fetch chart data:", error);
      }
    };

    fetchData();
  }, [plant]);

  return (
    <div className={`card card-custom bg-gray-100 ${className}`} >
      {/* Header */}
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark">Power Generation</h3>
      </div>

      {/* Body */}
      <div className="card-body p-1 position-relative overflow-hidden" style={{ height: "400px" }}>
        {/* Chart */}

        <div style={{ height: "100%" }}>
          <DemoChart chartData={chartData} />
        </div>
      </div>
    </div>
  );
}

