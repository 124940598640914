import React, { useState, useEffect } from "react";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ComposedChart,
    Line
} from "recharts";
import { chartColors } from "../../../../../../static/ChartColors";

export function Power_graph({ chartData }) {
    const [dataInRange, setDataInRange] = useState([]);

    useEffect(() => {
        if (chartData) {
            const { timestamps, temperatures, theoPower } = chartData;
        
            // Combine data into an array of objects and ensure proper date formatting
            const combinedData = timestamps.map((Timestamp, index) => ({
                timestamp: Timestamp, 
                Power: theoPower[index] / 1000,
                Temperature: temperatures[index]
            }));
        
            // Remove duplicate timestamps
            const uniqueData = combinedData.filter((item, index, self) => 
                index === self.findIndex((t) => t.timestamp === item.timestamp)
            );
        
            // Get the latest timestamp and calculate the start date (24 hours before)
            const latestTimestamp = uniqueData
                .map((data) => data.timestamp)
                .sort((a, b) => new Date(b) - new Date(a))[0];
            const latestDate = new Date(latestTimestamp);
            let startingDate = new Date(latestTimestamp);
            startingDate.setHours(latestDate.getHours() - 168);
        
            // Filter data within the range
            const filteredData = uniqueData.filter(
                (data) => new Date(data.timestamp) >= startingDate && new Date(data.timestamp) <= latestDate
            );
        
            setDataInRange(filteredData);
            console.log(uniqueData);
            console.log("Data is set");
            console.log("");
        }
        
    }, [chartData]);

    const formatYAxisTick = (value) => Math.ceil(value); // Round down to the nearest whole number

    const formatXAxisTick = (tick) => {
        const date = new Date(tick);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    };

    if (!chartData) return <div>Processing...</div>;

    return (
        <ComposedChart
            width={1000}
            height={500}
            data={dataInRange}
            margin={{ top: 20, right: 30, left: 5, bottom: 20 }}
        >
            <XAxis 
                dataKey="timestamp" 
                label="Timestamp" 
                height={100} 
                tickCount={3}
                //tickFormatter={formatXAxisTick} // Format the date for better readability
            />
            <YAxis yAxisId="left" label="Power" type="number" domain={[0, 'dataMax']} tickFormatter={formatYAxisTick} unit="kW" width={185} />
            <YAxis yAxisId="right" orientation="right" label="Temperature" unit="°C" domain={[-15, 50]} width={170} />
            <Tooltip labelFormatter={formatXAxisTick} />
            <Legend />
            <Area yAxisId="left" dataKey="Power" fill="#99cc00" stroke="#99cc00" type="monotone" />
            <Line yAxisId="right" dataKey="Temperature" stroke={chartColors['temperature']} dot={true} type="monotone" />
        </ComposedChart>
    );
}
