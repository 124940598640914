import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Modal, Button } from "react-bootstrap";
import { Card, CardHeader } from "@material-ui/core";
import { CardBody, CardHeaderToolbar, Pagination } from "../../../../../_metronic/_partials/controls";
import { NoRecordsFoundMessage, PleaseWaitMessage, sortCaret, headerSortingClasses } from "../../../../../_metronic/_helpers";
import Services from "./AdminService";



class AdminPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plantsData: [],
            customers: [],
            custom: true,
            totalSize: 0,
            sizePerPageList: [3, 5, 10],
            sizePerPage: 10,
            page: 1,
            openCreateModal: false,
            isChecked: false,
            processing: false,
            showCloseButton: false,
        };
        this.getPlants = this.getPlants.bind(this);
        this.hasJWT = this.hasJWT.bind(this);
    }

    hasJWT() {
        let flag = false;

        //check user has JWT token
        localStorage.getItem("token") ? flag = true : flag = false

        return flag;
    }

    async componentDidMount() {
        this.getPlants();
    }

    accept() {
        console.log('accept');
    }

    async getPlants() {
        let response = await Services.getAllForecasts();
        if (response?.status == 200) {
          this.setState({
            plantsData: response?.data?.data?.entities,
            totalSize: response?.data?.data?.totalCount,
          });
        }
        console.log(response.status)
        // in if (response.status = 422), missing = sign made me lose 2 hours
        if (response.status == 422) {
          console.log("here for some reason")
          this.setState({
            plantsData: [],
            totalSize: 0,
          });
        }
        console.log(this.state.plantsData)

    }
    handleClose = () => {
        // TODO: Refersh the page
        this.setState({ processing: false, showCloseButton: false });
        window.location.reload();
    }

    async trainModel(row) {
        this.setState({ processing: true });

        try {
        let response = await Services.createForecast(row);
        if (response.status === 200) {
            this.setState({ showCloseButton: true });
        }
        else {
            alert("Error in Model Training")
            this.setState({ showCloseButton: true });
        }
        } catch (error) {
        console.error("Error while training model:", error);
        this.setState({ showCloseButton: true });
    }
    }

    async generateForecast(row) {
        
        // alert("Going to generate forecast. Are you sure?")
        // let response = await Services.generateForecast(row);
        // console.log(response)
        // if (response.status == 200) {
        //     alert("Forecast Generated");

        //     this.handleClose();
        // }
        this.setState({ processing: true });

    try {
        let response = await Services.generateForecast(row);
        if (response.status === 200) {
            this.setState({ showCloseButton: true });
        }
        else {
            alert("Error in Forecast Generation")
            this.setState({ showCloseButton: true });
        }

        } catch (error) {
        console.error("Error while generating forecast:", error);
        this.setState({ showCloseButton: true });
    }
    }

    async cancelForecast(row) {

        this.setState({ processing: true });

    try{
        let response = await Services.cancelForecast(row);
        if (response.status == 200) {
             this.setState({ showCloseButton: true });
        }
        else {
            alert("Error in Cancelling Forecast")
            this.setState({ showCloseButton: true });
        }
    } catch (error) {
        console.error("Error while cancelling forecast:", error);
        this.setState({ showCloseButton: true });
    }
    }

    columns = [
        {
            dataField: "plantId",
            text: "Plant id",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        // {
        //     dataField: "plantName",
        //     text: "Plant name",
        //     sort: true,
        //     sortCaret: sortCaret,
        //     headerSortingClasses,
        // },
        {
            dataField: "duration",
            text: "Forecast Duration",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "interval",
            text: "Forecast Interval",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "startTime",
            text: "Forecast start time",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
            dataField: "updatePeriod",
            text: "updatePeriod",
            sort: false,
            sortCaret: sortCaret,
            headerSortingClasses,
        },
        {
          dataField: "approved",
          text: "approval status",
          sort: false,
          sortCaret: sortCaret,
          headerSortingClasses,
      },
        {
            dataField: "action",
            text: "Actions",
            formatter: (cell, row) => {
                const forecastStatus = row.approved;

                if (forecastStatus !== "false") {
                    return (
                        <Button style={{
                            backgroundColor: "#99cc00", 
                            color: "black", // Text color
                          }} onClick={() => this.generateForecast(row)}
                          >
                            Generate Forecast
                        </Button>
                    );
                }
                return (
                    <Button style={{
                        backgroundColor: "#736F90", // Dark shade of blue
                        color: "white", // Text color
                      }} onClick={() => this.trainModel(row)}
                      >
                        Train Model
                    </Button>
                );
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        },
        {
            dataField: "",
            text: "",
            formatter: (cell, row) => {
                const forecastStatus = row.approved;
                const isDisabled = forecastStatus === "true";
                return (
                    <Button style={{
                        backgroundColor: "#cc0000", 
                        color: "white", // Text color
                      }} onClick={() => this.cancelForecast(row)}
                      disabled={isDisabled}>
                        Reject Forecast
                    </Button>
                );
            },
            classes: "text-right pr-0",
            headerClasses: "text-right pr-3",
            style: {
                minWidth: "100px",
            },
        },



    ];

    showDetails = (id) => {
        this.props.history.push(`/plant-details?id=${id}`, { id: id });
    }

    render() {
        const paginationOptions = {
            custom: true,
            totalSize: this.state?.totalSize ?? 0,
            sizePerPageList: this.state?.sizePerPageList ?? 20,
            sizePerPage: this.state?.sizePerPage ?? 20,
            page: this.state?.page ?? 0,
        };

        return (
            this.hasJWT() ?
                <>
                    <Card>
                        <CardHeader title="Requested Forecasts">
                            <CardHeaderToolbar>
                                <button type="button" className="btn btn-primary" onClick={this.toggleCreatePlantModal}>
                                    New Plant
                                </button>

                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>
                            {this.state.isChecked}
                            {this.state.isChecked && (
                                <div className="dropdown-menu show">
                                    <a className="dropdown-item" href="#">Action</a>
                                    <a className="dropdown-item" href="#">Another action</a>
                                </div>
                            )}
                            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                                {({ paginationProps, paginationTableProps }) => {
                                    return (
                                        <Pagination paginationProps={paginationProps}>
                                            <BootstrapTable
                                                wrapperClasses="table-responsive"
                                                bordered={false}
                                                classes="table table-head-custom table-vertical-center"
                                                bootstrap4
                                                remote
                                                keyField="id"
                                                data={
                                                    this.state.plantsData === null
                                                        ? []
                                                        : this.state.plantsData
                                                }
                                                columns={this.columns}
                                                defaultSorted={this.defaultSorted}
                                                onTableChange={() => { }}
                                                {...paginationTableProps}
                                            >
                                                <PleaseWaitMessage plantsData={this.state.plantsData} />
                                                <NoRecordsFoundMessage plantsData={this.state.plantsData} />
                                            </BootstrapTable>
                                        </Pagination>
                                    );
                                }}
                            </PaginationProvider>
                        </CardBody>
                    </Card>
                    <Modal show={this.state.processing} backdrop="static" keyboard={false}>
                    <Modal.Body>

                    {this.state.showCloseButton ? "Completed" : "Processing"}
                    </Modal.Body>
                    <Modal.Footer>
                    {this.state.showCloseButton && (
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          )}
                    </Modal.Footer>
                </Modal>
                </> :
                <Redirect to='logout' />
        
                        
                );
    }
}

export default AdminPage;
