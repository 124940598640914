import Datetime from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import React from 'react'

function StartTimeField(props) {
    return (
        <div className={"form-group " + (props.required ? 'required' : '')}>
            <label className="control-label" htmlFor="plantName">{props.label}</label>
            <div className="input-group mb-3" style={{ width: "100%" }}>
                <Datetime
                    value={props.startTime}
                    onChange={(value) => {
                        props.saveUpdate("startTime", value);
                    }}
                    inputProps={{
                        placeholder: 'Select date and time'
                    }}
                    dateFormat="MMM DD, YYYY"
                    timeFormat="hh:mm A"
                    isValidDate={(current) => {
                        const twoDaysLater = moment().add(1, 'days').startOf('day');
                        return current.isSameOrAfter(twoDaysLater);
                    }}
                    className="w-100"
                />
            </div></div>
    )
}

export default StartTimeField