import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Services from "../PlantsService";
import { WindPlantMandatoryForm } from "./WindPlantMandatoryForm";
import { SolarPlantMandatoryForm } from "./SolarPlantManfatoryForm";
import { SelectPlantType } from "./SelectPlantType";


function ResponseModal({ show, loading, message, onHide }) {
  return (
    <Modal size="sm" show={show} onHide={onHide}>
      <Modal.Body>
        {loading ? <p>Loading...</p> : <p>{message}</p>}
      </Modal.Body>
    </Modal>
  );
}

export class PlantEditDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTitle: "Create Plant",
      formErrors: null,
      plantType: null,
      plant: {},
      energyDataFile: null,
      step: 0,
      loading: false, // Initialize loading state
      responseModalShow: false, // Initialize response modal visibility
      responseMessage: "",
      openCreateModal: false,
      isChecked: false,
      processing: false,
      showCloseButton: false,
    };
    this.submitData = this.submitData.bind(this);
    this.selectPlantType = this.selectPlantType.bind(this);
  }

  async selectPlantType(value) {
    this.setState({
      plantType: value,
      step: 1,
      formErrors: null,
    });
  }

  missingMandatoryField() {
    let allErrors = {};
    if (this.state.plant.energyDataFile === null || this.state.plant.energyDataFile === undefined ) {
      allErrors["energyDataFile"] = "Please submit a valid file"
    }
    // check if all mandatory fields are filled
    if (this.state.plantType === "wind") {
    console.log(this.state.plant)
      if (this.state.plant.plantName == null || this.state.plant.plantName?.trim() == "") {
        allErrors["plantName"] = "Please enter a plant name.";
      }
      if (this.state.plant.longitude == null) {
        allErrors["longitude"] = "Please enter the longitude.";
      }
      if (this.state.plant.latitude == null) {
        allErrors["latitude"] = "Please enter the latitude.";
      }
      if (this.state.plant.timeZone == null) {
        allErrors["timezone"] = "Please select the timezone.";

      }

      if (this.state.plant.timeZone == "1") {
        allErrors["timezone"] = "Please select the timezone.";
      }
      if (this.state.plant.modelTurbine?.trim() == "") {
        allErrors["modelTurbine"] = "Please enter the model of the turbine.";
      }
      if (this.state.plant.hubHeight == null) {
        allErrors["hubHeight"] = "Please enter the height of the hub";
      }
      if (this.state.plant.altitude == null) {
        allErrors["altitude"] = "Please enter the power rate";
      }
      
      return allErrors;
    }

    if (this.state.plantType === "solar") {
    console.log(this.state.plant)

      if (this.state.plant.plantName == null || this.state.plant.plantName?.trim() === "") {
        allErrors["plantName"] = "Please enter a plant name.";
      }
      if (this.state.plant.timeZone == null) {
        allErrors["timezone"] = "Please select the timezone.";

      }
      if (this.state.plant.timeZone == "1") {
        allErrors["timezone"] = "Please select the timezone.";
      }
      if (this.state.plant.longitude == null) {
        allErrors["longitude"] = "Please enter the longitude.";
      }
      if (this.state.plant.latitude == null) {
        allErrors["latitude"] = "Please enter the latitude.";
      }
      if (this.state.plant.altitude == null) {
        allErrors["altitude"] = "Please enter the altitude.";
      }
      if (this.state.plant.tilt == null) {
        allErrors["tilt"] = "Please enter the tilt.";
      }
      if (this.state.plant.azimuth == null) {
        allErrors["azimuth"] = "Please enter the azimuth";
      }
      console.log(allErrors);
      return allErrors;
    }

    return true;
  }

  async submitData() {
    this.setState({ formErrors: null, loading: true });
    let allErrors = this.missingMandatoryField();
    if (Object.keys(this.missingMandatoryField()).length > 0) {
      this.setState({ formErrors: allErrors }, () => {
        this.setState();
      });
      return;
    }
    this.setState({ processing: true })

    const formData = new FormData();

    // save data if plant type is solar
    if (this.state.plantType === "solar") {
      formData.append('plantType', 'solar');
      formData.append('plantName', this.state.plant.plantName);
      formData.append('timeZone', this.state.plant.timeZone);
      formData.append('longitude', this.state.plant.longitude);
      formData.append('latitude', this.state.plant.latitude);
      formData.append('altitude', this.state.plant.altitude);
      formData.append('tilt', this.state.plant.tilt);
      formData.append('azimuth', this.state.plant.azimuth);
      formData.append('maximumCapacity', this.state.plant.maximumCapacity);
      formData.append('totalOutput', this.state.plant.totalOutput);
      formData.append('efficiencyCoefficient', this.state.plant.efficiencyCoefficient);
      formData.append('moduleSize', this.state.plant.moduleSize);
      formData.append('noOfStrings', this.state.plant.noOfStrings);
      formData.append('modulesPerString', this.state.plant.modulesPerString);
      formData.append('modulePerformance', this.state.plant.modulePerformance);
      formData.append('modulePerformance', this.state.plant.modulePerformance);
      formData.append('surfaceArea', this.state.plant.surfaceArea);
      formData.append('energyDataFile', this.state.plant.energyDataFile);
    }

    if (this.state.plantType == "wind") {
      formData.append('plantType', 'wind');
      formData.append('plantName', this.state.plant.plantName);
      formData.append('timeZone', this.state.plant.timeZone);
      formData.append('longitude', this.state.plant.longitude || 0);
      formData.append('latitude', this.state.plant.latitude || 0);
      formData.append('altitude', this.state.plant.altitude);
      formData.append('maximumCapacity', this.state.plant.maximumCapacity);
      formData.append('modelTurbine', this.state.plant.modelTurbine || '');
      // formData.append('positionWindTurbines', this.state.plant.positionWindTurbines || '');
      formData.append('hubHeight', this.state.plant.hubHeight || 0);
      formData.append('rotorDiameter', this.state.plant.rotorDiameter || 0);
      // formData.append('powerCurve', this.state.plant.powerCurve || 0);
      // formData.append('powerRated', this.state.plant.powerRated || 0);
      formData.append('energyDataFile', this.state.plant.energyDataFile);
    }

    try {
      this.handleClose()
      this.setState({
        loading: false, // Set loading to false after API call
        responseModalShow: true, // Show the response modal
        responseMessage: "Processing... This may take some time", // Store response message
      });
      let response = await Services.createPlant(formData);
      this.setState({
        loading: false, // Set loading to false after API call
        responseModalShow: true, // Show the response modal
        responseMessage: response.message,  // Store response message
        showCloseButton: true,
      });

    } catch (error) {
      console.error("Error submitting forecast data:", error);
      this.setState({ loading: false });
    }
  }

  onShow = async () => {
    //fetch plant data in case of editing
  }

  handleClose = () => {
    // go back to first page
    this.setState({
      plantType: null,
      step: 0,
      formErrors: null,
    });
    this.props.onHide();

  };
  // This is for the modal that appears on sunbmut
  handleCloseModal = () => {
    // TODO: Refersh the page
    this.setState({ processing: false, showCloseButton: false });
    window.location.reload();
  }

  saveUpdate = (field, data) => {
    let plant = this.state.plant;
    plant[field] = data;
    this.setState(plant);
  }

  selectCategory(category) {
    this.setState({
      plantType: category,
      step: 1,
      formErrors: null,
    });
  }

  render() {
    return (
      <form onSubmit={this.submitData}>
        <Modal
          size="lg"
          show={this.props.show}
          onShow={this.onShow}
          onHide={this.handleClose}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton >
            <Modal.Title>{this.state.modalTitle} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.step === 0 ?
              <SelectPlantType selectPlantType={this.selectPlantType}></SelectPlantType>
              :
              <></>
            }


            {(this.state.step === 1 &&
              this.state.plantType === "wind") ?
              <WindPlantMandatoryForm saveUpdate={this.saveUpdate} formErrors={this.state.formErrors}></WindPlantMandatoryForm>
              : <></>
            }

            {(this.state.step === 1 &&
              this.state.plantType === "solar") ?
              <SolarPlantMandatoryForm saveUpdate={this.saveUpdate} formErrors={this.state.formErrors}></SolarPlantMandatoryForm>
              : <></>
            }

            {this.state.formErrors !== null ? 
            <div>
              <div className="text-danger small">{this.state.formErrors["energyDataFile"]}</div>
              <div className="text-danger small">{this.state.formErrors["plantName"]}</div>
              <div className="text-danger small">{this.state.formErrors["timezone"]}</div>
              <div className="text-danger small">{this.state.formErrors["longitude"]}</div>
              <div className="text-danger small">{this.state.formErrors["latitude"]}</div>
              <div className="text-danger small">{this.state.formErrors["modelTurbine"]}</div>
              <div className="text-danger small">{this.state.formErrors["hubHeight"]}</div>
              <div className="text-danger small">{this.state.formErrors["altitude"]}</div>
              <div className="text-danger small">{this.state.formErrors["tilt"]}</div>
              <div className="text-danger small">{this.state.formErrors["azimuth"]}</div>
            </div> : <small></small>}
          </Modal.Body>
          {(
            this.state.step > 0 ? <Modal.Footer>
              <button
                type="button"
                onClick={this.handleClose}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={this.submitData}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer> : <></>
          )}
        </Modal>
        <Modal show={this.state.processing} backdrop="static" keyboard={false}>
          <Modal.Body>
            {this.state.showCloseButton ? "Completed" : "Processing... This may take some time"}
          </Modal.Body>
          <Modal.Footer>
            {this.state.showCloseButton && (
              <Button variant="secondary" onClick={this.handleCloseModal}>
                Close
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </form>
    );
  }
}
