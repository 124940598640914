import axios from "axios";
import { da } from "date-fns/locale";
import { useHistory } from "react-router-dom";

const apiURL = `${process.env.REACT_APP_URL}/api/admin/plants`;
const energyDataFileURL = `${process.env.REACT_APP_URL}/api/energyDataFile`;
const forecast_URL = `${process.env.REACT_APP_URL}/api/forecastadmin`;
const forecastGeneration = `${process.env.REACT_APP_URL}/api/forecastadminGenerate`;

const forecast_plants_table = `${process.env.REACT_APP_URL}/api/forecastPlantsTable`;
const createURL = `${process.env.REACT_APP_URL}/api/forecast`;
const notificationURL = `${process.env.REACT_APP_URL}/api/checkForecast`;



export default {
    getPlants: async () => {
        try {
            const token = localStorage.getItem("token");
            let response = await axios({
                method: "GET",
                url: apiURL,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).catch((error) => {
                return error.response;
            });

            const result = response?.data;
            result?.access_token && localStorage.setItem('token', result?.access_token);
            return response;
        }
        catch (e) {
            return []
        }

    },

    getPlantById: async (id) => {
        try {
            const token = localStorage.getItem("token");
            let response = await axios({
                method: "GET",
                url: apiURL + '/' + id,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).catch((error) => {
                return error.response;
            });

            const result = response?.data;
            result?.access_token && localStorage.setItem('token', result?.access_token);
            return response;
        } catch (e) {
            return null;
        }
    },

    getDataEnergyFile: async (file) => {
        const token = localStorage.getItem("token");
        let response = await axios({
            method: "GET",
            url: energyDataFileURL + '?path=' + file.file_name,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        });

        const result = response.data;
        result.access_token && localStorage.setItem('token', result.access_token);
        return result;
    },

    getAllForecasts: async () => {
        try {
            const token = localStorage.getItem("token");
            let response = await axios({
                method: "GET",
                url: forecast_URL,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).catch((error) => {
                return error.response;
            });

            const result = response?.data;
            result?.access_token && localStorage.setItem('token', result?.access_token);
            return response;
        }
        catch (e) {
            return []
        }

    },

    getForecastsPlantTable: async () => {
        try {
            const token = localStorage.getItem("token");
            let response = await axios({
                method: "GET",
                url: forecast_plants_table,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).catch((error) => {
                return error.response;
            });

            const result = response?.data;
            result?.access_token && localStorage.setItem('token', result?.access_token);
            return response;
        }
        catch (e) {
            return []
        }

    },

    // This is a function which is called when the admin approves the forecast, it calls the api endpoint to gert on weith generating the forecast

    createForecast: async (data) => {
        console.log(data)
        const token = localStorage.getItem("token");
        let response = await axios({
            method: "POST",
            url: forecast_URL,
            data: data,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        const result = response.data;
        result.access_token && localStorage.setItem('token', result.access_token);
        return result;
    },

    generateForecast: async (data) => {
        console.log(data)

        const token = localStorage.getItem("token");
        let response = await axios({
            method: "POST",
            url: forecastGeneration,
            data: data,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        const result = response.data;
        result.access_token && localStorage.setItem('token', result.access_token);
        return result;
    },

    cancelForecast: async (data) => {
        console.log(data)

        const token = localStorage.getItem("token");
        let response = await axios({
            method: "DELETE",
            url: forecast_URL,
            data: data,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        const result = response.data;
        result.access_token && localStorage.setItem('token', result.access_token);
        return result;
    },
    
    checkForecast: async () => {
        try {
            const token = localStorage.getItem("token");
            let response = await axios({
                method: "GET",
                url: notificationURL,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).catch((error) => {
                return error.response;
            });

            const result = response?.data;
            result?.access_token && localStorage.setItem('token', result?.access_token);
            return response;
        }
        catch (e) {
            return []
        }

    },


};
