import React from "react";
import { Route } from "react-router-dom";
import { PlantsLoadingDialog } from "./plants-loading-dialog/PlantsLoadingDialog";
import { PlantDeleteDialog } from "./plant-delete-dialog/PlantDeleteDialog";
import { PlantsDeleteDialog } from "./plants-delete-dialog/PlantsDeleteDialog";
import { PlantsFetchDialog } from "./plants-fetch-dialog/PlantsFetchDialog";
import { PlantEditDialog } from "./plant-edit-dialog/PlantEditDialog";
import { PlantsUpdateStateDialog } from "./plants-update-status-dialog/PlantsUpdateStateDialog";
import { PlantsUIProvider } from "./PlantsUIContext";
import { PlantsCard } from "./PlantsCard";
import GetForecastForm from "./plant-forecast-dialog/PlantForecastDialog";

export function PlantsPage({ history }) {
  const plantsUIEvents = {
    newPlantButtonClick: () => {
      history.push("/plants/new");
    },
    openEditPlantDialog: (id) => {
      history.push(`/plants/${id}/edit`);
    },
    openForecastModal: (id) => {
      history.push(`/plants/${id}/forecast`);
    },
    openDeletePlantDialog: (id) => {
      history.push(`/plants/${id}/delete`);
    },
    openDeletePlantsDialog: () => {
      history.push(`/plants/deletePlants`);
    },
    openFetchPlantsDialog: () => {
      history.push(`/plants/fetch`);
    },
    openUpdatePlantsStatusDialog: () => {
      history.push("/plants/updateStatus");
    },

  };

  return (
    <PlantsUIProvider plantsUIEvents={plantsUIEvents}>
      <PlantsLoadingDialog />
      <Route path="/plants/new">
        {({ history, match }) => (
          <PlantEditDialog
            show={match != null}
            onHide={() => {
              history.push("/plants");
            }}
          />
        )}
      </Route>
      <Route path="/plants/:id/edit">
        {({ history, match }) => (
          <PlantEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/plants");
            }}
          />
        )}
      </Route>
      <Route path="/plants/:id/forecast">
        {({ history, match }) => (
          <GetForecastForm
            show={match != null}
            onHide={() => {
              history.push("/plants");
            }}
            plantId={match?.params.id}
          />
        )}
      </Route>
      <Route path="/plants/deletePlants">
        {({ history, match }) => (
          <PlantsDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/plants");
            }}
          />
        )}
      </Route>
      <Route path="/plants/:id/delete">
        {({ history, match }) => {
          return (<PlantDeleteDialog
            show={history.location.pathname?.includes('delete')}
            id={history.location.pathname?.match(/\/plants\/(.*)\/delete/)?.[1]}
            onHide={() => {
              history.push("/plants");
            }}
          />
          );
        }}
      </Route>
      <Route path="/plants/fetch">
        {({ history, match }) => (
          <PlantsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/plants");
            }}
          />
        )}
      </Route>
      <Route path="/plants/updateStatus">
        {({ history, match }) => (
          <PlantsUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/plants");
            }}
          />
        )}
      </Route>
      <PlantsCard />
    </PlantsUIProvider>
  );
}
