import * as requestFromServer from "./forecastCrud";
import {forecastSlice, callTypes} from "./forecastSlice";

const {actions} = forecastSlice;

export const fetchForecasts = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findForecasts(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.forecastsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find forecasts";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchForecast = id => dispatch => {
  if (!id) {
    return dispatch(actions.forecastFetched({ forecastForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getForecastById(id)
    .then(response => {
      const forecast = response.data;
      dispatch(actions.forecastFetched({ forecastForEdit: forecast }));
    })
    .catch(error => {
      error.clientMessage = "Can't find forecast";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteForecast = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteForecast(id)
    .then(response => {
      dispatch(actions.forecastDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete forecast";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createForecast = forecastForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createForecast(forecastForCreation)
    .then(response => {
      const { forecast } = response.data;
      dispatch(actions.forecastCreated({ forecast }));
    })
    .catch(error => {
      error.clientMessage = "Can't create forecast";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateForecast = forecast => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateForecast(forecast)
    .then(() => {
      dispatch(actions.forecastUpdated({ forecast }));
    })
    .catch(error => {
      error.clientMessage = "Can't update forecast";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateForecastsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForForecasts(ids, status)
    .then(() => {
      dispatch(actions.forecastsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update forecasts status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteForecasts = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteForecasts(ids)
    .then(() => {
      dispatch(actions.forecastsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete forecasts";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
