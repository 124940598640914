import React from 'react'
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ComposedChart,
    Line,
    Bar,
    ResponsiveContainer
  } from "recharts";
  import { Card, CardHeader } from "../../../../../_metronic/_partials/controls";

  import { chartColors } from "../../../../static/ChartColors";
import { utcToZonedTime, format } from 'date-fns-tz';
import { startOfMonth, startOfQuarter } from 'date-fns';
export function MonthlyChart({ chartData }) {
  
    const header = chartData?.[0];
    const rows = chartData?.slice(1) || [];
  
    const timestampIndex = header?.indexOf("timestamp");
    const powerIndex = header?.indexOf("power[kW]");
    const result = rows.map((row) => (
      {
        timestamp:  utcToZonedTime(new Date(row[timestampIndex]), 'UTC'),
        power: parseFloat(row[powerIndex])
    
        
      }));
      console.log(result)
  
     // Group by month
  const monthly = result.reduce((acc, entry) => {
    const monthKey = format(startOfMonth(entry.timestamp), 'MMM-yy');
    if (!acc[monthKey]) {
      acc[monthKey] = { timestamp: startOfMonth(entry.timestamp), power: 0 };
    }
    acc[monthKey].power = acc[monthKey].power+ entry.power;
    return acc;
  }, {});

  const monthlyArray = Object.keys(monthly).map(key => ({
    timestamp: monthly[key].timestamp,
    power: monthly[key].power/1000,
    Month: key
  }));
  console.log(monthlyArray)

    if (chartData === null)
      return (<div>Processing...</div>)
  
    return (
      <Card>
        <CardHeader title="Monthly accumulative power of the plant"/>
      <ResponsiveContainer width="97%" height={500} >
      
      <ComposedChart
        
        data={monthlyArray}
        margin={{ top: 30, right: 30, left: 5, bottom: 2 }}
  
      >
        <XAxis dataKey="Month" label="Timestamp" height={60} tickCount={3} />
        <YAxis yAxisId="left" label="Power" type="number"  unit="MW" width={185} />
  
        <Tooltip />
        <Legend />
        <Bar yAxisId="left" dataKey="power" name="Power" fill="#088F8F" type="monotone" />
        
  
  
      </ComposedChart>
      </ResponsiveContainer>
      </Card>
    );
  
}

