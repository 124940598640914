// Mixed
export { MixedWidget1 } from "./mixed/MixedWidget1";
export { MixedWidget14 } from "./mixed/MixedWidget14";

// Lists
export { ListsWidget1 } from "./lists/ListsWidget1";
export { ListsWidget3 } from "./lists/ListsWidget3";
export { ListsWidget4 } from "./lists/ListsWidget4";
export { ListsWidget8 } from "./lists/ListsWidget8";
export { ListsWidget9 } from "./lists/ListsWidget9";

// Stats
export { StatsWidget11 } from "./stats/StatsWidget11";
export { StatsWidget12 } from "./stats/StatsWidget12";

// Advance tables
export { AdvanceTablesWidget2 } from "./advance-tables/AdvanceTablesWidget2";
export { AdvanceTablesWidget4 } from "./advance-tables/AdvanceTablesWidget4";

// Custom Dashboard Widgets

export { WeatherWidget } from "./dashboard-widgets/WeatherWidget"
export { PlantInformation } from "./dashboard-widgets/PlantInformation"
export { PowerWidget } from "./dashboard-widgets/PowerWidget"
export { ForecastWidget } from "./dashboard-widgets/ForecastWidget"