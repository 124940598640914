/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../../../_metronic/_partials/controls";
import { Col, Row, Button } from "react-bootstrap";
import ForecastsService from "../../forecasts/ForecastsService";
import ForecastChart from "../chart";

export function ForecastWidget({ className, plantName, chartData, plantId }) {




    const handleExportClick = async () => {
        try {
            let forecast = await ForecastsService.getForecastById(plantId);
            const file = forecast.data.data.Item.file_path;
            const response = await ForecastsService.exportForecastsFile(file);
            const responseText = await response.text();

            const blob = new Blob([responseText], { type: "application/octet-stream" });
            const blobUrl = URL.createObjectURL(blob);

            const a = document.createElement("a");
            a.style.display = "none";
            a.href = blobUrl;
            a.download = "Forecast.csv";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error("Error exporting forecast file:", error);
        }
    };


  return (
    <>
<Card>
                    <CardHeader title={`Forecast details for ${plantName}`}>
                    </CardHeader>

                    <CardBody>
                        <Row>
                            <Col sm="10">
                                {chartData != null && <ForecastChart chartData={chartData}></ForecastChart>}
                            </Col>
                            <Col sm="2">
                                <Button variant="info" onClick={handleExportClick}>
                                    <i className="fas fa-download mr-1"></i> Download
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
    </>
  );
}
