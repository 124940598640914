import React, { Component } from "react";
import { InputField } from "./InputField";


export class SolarPlantOptionalForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalOutput: "",
            efficiencyCoefficient: "",
            moduleSize: "",
            noOfStrings: "",
            modulesPerString: "",
            temperatureCoefficient: "",
            modulePerformance: "",
            surfaceArea: "",
            saveUpdate: this.props.saveUpdate,
        };
        console.log(this.state.saveUpdate)
    }

    componentDidMount() {
    }

    updateTimezone(event) {
        this.setState({
            "timeZone": event.target.value,
        });
    }

    render() {
        return (
            <>
                <div className="form-group row">
                    <div className="col-lg-6">
                        <InputField
                            type="number"
                            label="Total output"
                            info="Add total output of the plant (Optional)"
                            name="totalOutput"
                            placeholder="Total output"
                            defaultValue={this.state.totalOutput}
                            icon="kW"
                            onChange={(event) => {
                                this.setState({
                                    "totalOutput": event.target.value,
                                });
                                this.state.saveUpdate(
                                    "totalOutput", event.target.value,
                                );
                            }}
                        />
                    </div>
                    <div className="col-lg-6">
                        <InputField
                            type="number"
                            info="Add efficiency coefficient of the plant (Optional)"
                            label="Efficiency coefficient"
                            name="efficiencyCoefficient"
                            placeholder="Efficiency coefficient"
                            defaultValue={this.state.efficiencyCoefficient}
                            icon="%"
                            onChange={(event) => {
                                this.setState({
                                    "efficiencyCoefficient": event.target.value,
                                });
                                this.state.saveUpdate(
                                    "efficiencyCoefficient", event.target.value,
                                );
                            }}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-lg-6">
                        <InputField
                            type="number"
                            label="No. of strings"
                            info="Add number of strings (Optional)"
                            name="noOfStrings"
                            placeholder="No. of strings"
                            defaultValue={this.state.noOfStrings}
                            icon="°"
                            onChange={(event) => {
                                this.setState({
                                    "noOfStrings": event.target.value,
                                });
                                this.state.saveUpdate(
                                    "noOfStrings", event.target.value,
                                );
                            }}
                        />
                    </div>
                    <div className="col-lg-6">
                        <InputField
                            type="number"
                            label="Modules per string"
                            name="modulesPerString"
                            info="Add modules per each string (Optional)"
                            placeholder="Modules per string"
                            defaultValue={this.state.modulesPerString}
                            icon="°"
                            onChange={(event) => {
                                this.setState({
                                    "modulesPerString": event.target.value,
                                });
                                this.state.saveUpdate(
                                    "modulesPerString", event.target.value,
                                );
                            }}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-lg-6">
                        <InputField
                            type="number"
                            label="Temperature coefficient"
                            name="temperatureCoefficient"
                            info="Add temperature coefficient (Optional)"
                            placeholder="Temperature coefficient"
                            defaultValue={this.state.temperatureCoefficient}
                            icon="%/°C"
                            onChange={(event) => {
                                this.setState({
                                    "temperatureCoefficient": event.target.value,
                                });
                                this.state.saveUpdate(
                                    "temperatureCoefficient", event.target.value,
                                );
                            }}
                        />
                    </div>
                    <div className="col-lg-6">
                        <InputField
                            type="number"
                            label="Module performance"
                            name="modulePerformance"
                            info="Add module performance of the plant (Optional)"
                            placeholder="Module performance"
                            defaultValue={this.state.modulePerformance}
                            icon="kW"
                            onChange={(event) => {
                                this.setState({
                                    "modulePerformance": event.target.value,
                                });
                                this.state.saveUpdate(
                                    "modulePerformance", event.target.value,
                                );
                            }}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-lg-6">
                        <InputField
                            type="number"
                            label="Surface area"
                            info="Add surface area of the plant panel (Optional)"
                            name="surfaceArea"
                            placeholder="Surface area"
                            defaultValue={this.state.surfaceArea}
                            icon="m2"
                            onChange={(event) => {
                                this.setState({
                                    "surfaceArea": event.target.value,
                                });
                                this.state.saveUpdate(
                                    "surfaceArea", event.target.value,
                                );
                            }}
                        />
                    </div>
                    <div className="col-lg-6">
                        <InputField
                            type="number"
                            label="Module size"
                            name="moduleSize"
                            info="Add module size of the plant (Optional)"
                            placeholder="Module size"
                            defaultValue={this.state.moduleSize}
                            icon="m2"
                            onChange={(event) => {
                                this.setState({
                                    "moduleSize": event.target.value,
                                });
                                this.state.saveUpdate(
                                    "moduleSize", event.target.value,
                                );
                            }}
                        />
                    </div>
                </div>
            </>
        );
    }
}