import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  Line,
  Bar,
  Scatter,
  ResponsiveContainer,
  Label
} from "recharts";
import { chartColors } from "../../../app/static/ChartColors";

function ForecastChart({ chartData }) {
  const header = chartData?.[0];
  const rows = chartData?.slice(1) || [];

  const timestampIndex = header?.indexOf("timestamp");
  const millisecondsIndex = header?.indexOf("milliseconds");
  const powerIndex = header?.indexOf("power[kW]");
  const forecastIndex = header?.indexOf("forecast[kW]")

  const customTickFormatter = (timestamp) => {
    const date = new Date(parseInt(timestamp));

    if (!timestamp || !date) {
      return '';
    }

    const formattedDate = `${(date.getDate() < 10 ? '0' : '')}${date.getDate()}.` +
      `${(date.getMonth() + 1 < 10 ? '0' : '')}${date.getMonth() + 1}.` +
      `${date.getFullYear()} ` +
      `${(date.getHours() < 10 ? '0' : '')}${date.getHours()}:` +
      `${(date.getMinutes() < 10 ? '0' : '')}${date.getMinutes()}`;

    return formattedDate;
  };

  const result = rows.map((row) => ({
    timestamp: row[timestampIndex],
    milliseconds: row[millisecondsIndex],
    generated: row[powerIndex],
    forecast: row[forecastIndex],
  }));

  const latestTimestamp = result
    .map((res) => res.timestamp)
    .sort((a, b) => new Date(b) - new Date(a))[0];

  const latestDate = new Date(latestTimestamp);
  latestDate.setHours(latestDate.getHours() - 12);

  const dataInRange = getTimestampsInRange(result, latestDate, new Date(latestTimestamp));

  const maxValue = dataInRange.map((d) => d.power).sort((a, b) => b - a)[0];

  function getTimestampsInRange(data, start, end) {
    return data.filter(
      (row) => new Date(row.timestamp) >= start && new Date(row.timestamp) <= end
    );
  }
  const formatYAxisTick = (value) => Math.ceil(value); // Round down to the nearest whole number

  const maxValueGenerated = Math.max(...dataInRange.map((val) => {
    const parsedValue = parseFloat(val.generated);
    return !isNaN(parsedValue) ? parsedValue : 0;
  }));

  const minValueGenerated = Math.min(...dataInRange.map((val) => {
    const parsedValue = parseFloat(val.generated);
    return !isNaN(parsedValue) ? parsedValue : 0;
  }));

  const maxValueForecast = Math.max(...dataInRange.map((val) => {
    const parsedValue = parseFloat(val.forecast);
    return !isNaN(parsedValue) ? parsedValue : 0;
  }));

  const minValueForecast = Math.min(...dataInRange.map((val) => {
    const parsedValue = parseFloat(val.forecast);
    return !isNaN(parsedValue) ? parsedValue : 0;
  }));


  return (
    <div style={{ width: '100%' }}>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          data={dataInRange}
          margin={{ bottom: 40, left: 40, right: 40, top: 60 }}

        >
          {/* <Tooltip
            labelFormatter={value => {
              return `${customTickFormatter(value)}`;
            }}
          /> */}
          <XAxis dataKey="timestamp" />
          <YAxis yAxisId="generated" dataKey="generated" type="number" domain={[0, maxValueForecast > maxValueGenerated ? maxValueForecast : maxValueGenerated]} tickFormatter={formatYAxisTick} orientation="left">
            {/* Add a Label component to the YAxis */}
            <Label value="Power (kW)" offset={15} position="top" angle={0} />
          </YAxis>
          {/* <YAxis yAxisId="forecast" dataKey="forecast" type="number" domain={[minValueForecast < 0 ? minValueForecast : 0, maxValueForecast > maxValueGenerated ? maxValueForecast : maxValueGenerated]} tickFormatter={formatYAxisTick} orientation="left">
            {/* Add a Label component to the YAxis */}
            {/* <Label value="Predicted (kW)" offset={15} position="top" angle={0} />
          </YAxis> */} 

          <Tooltip />
          <Legend />
          <Area yAxisId="generated" dataKey="generated" name="Generated Power" fill={chartColors['powerGenerated']} stroke={chartColors['powerGenerated']} barSize={5} />
          <Area yAxisId="generated" dataKey="forecast" name="Forecasted Power" fill={chartColors['powerForecast']} stroke=	"#50C878" shape="circle" strokeWidth={0.5} />

        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ForecastChart;
