import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/forecasts/forecastActions";
import { ForecastSendDialogHeader } from "./ForecastSendDialogHeader";
import { ForecastSendForm } from "./ForecastSendForm";
import { useForecastsUIContext } from "../ForecastsUIContext";

export function ForecastSendDialog({ id, show, onHide }) {
  // Forecasts UI Context
  const forecastsUIContext = useForecastsUIContext();
  const forecastsUIProps = useMemo(() => {
    return {
      initForecast: forecastsUIContext.initForecast,
    };
  }, [forecastsUIContext]);

  // Forecasts Redux state
  const dispatch = useDispatch();
  const { actionsLoading, forecastForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.forecasts.actionsLoading,
      forecastForEdit: state.forecasts.forecastForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Forecast by id
    dispatch(actions.fetchForecast(id));
  }, [id, dispatch]);

  // server request for saving forecast
  const saveForecast = (forecast) => {
    if (!id) {
      // server request for creating forecast
      dispatch(actions.createForecast(forecast)).then(() => onHide());
    } else {
      // server request for updating forecast
      dispatch(actions.updateForecast(forecast)).then(() => onHide());
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <ForecastSendDialogHeader id={id} />
      <ForecastSendForm
        saveForecast={saveForecast}
        actionsLoading={actionsLoading}
        forecast={forecastForEdit || forecastsUIProps.initForecast}
        onHide={onHide}
      />
    </Modal>
  );
}
