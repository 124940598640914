import React, { Component } from 'react';

export class SelectDemoPlantType extends Component {


    constructor(props) {
        super(props);
    }

    selectPlant(value) {
        this.props.selectPlantType(value);
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-6">
                    <div className="card cursor-pointer bg-light" onClick={() => this.selectPlant("solar")}  >
                        <div className="card-body" >
                            <h5 className="card-title" >PV Plant</h5>
                            {/* <button onClick={() => this.selectPlant("solar")} className="btn btn-primary">Demo</button> */}
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="card bg-light cursor-pointer"  onClick={() => this.selectPlant("wind")}>
                        <div className="card-body">
                            <h5 className="card-title">Wind Plant</h5>
                            {/* <button onClick={() => this.selectPlant("wind")} className="btn btn-primary">Demo </button> */}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}