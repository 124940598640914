import React from "react";
import { Route } from "react-router-dom";
import { ForecastsLoadingDialog } from "./forecasts-loading-dialog/ForecastsLoadingDialog";
// import { ForecastEditDialog } from "./forecasts-edit-dialog/ForecastEditDialog";
import { ForecastSendDialog } from "./forecasts-sending-dialog/ForecastSendDialog";
import { ForecastStatsDialog } from "./forecasts-stats-dialog/ForecastStatsDialog";
import { ForecastLogsDialog } from "./forecasts-logs-dialog/ForecastLogsDialog";
import { ForecastDeleteDialog } from "./forecasts-delete-dialog/ForecastDeleteDialog";
// import { ForecastsDeleteDialog } from "./forecasts-delete-dialog/ForecastsDeleteDialog";
// import { ForecastsFetchDialog } from "./forecasts-fetch-dialog/ForecastsFetchDialog";
// import { ForecastsUpdateStateDialog } from "./forecasts-update-status-dialog/ForecastsUpdateStateDialog";
import { ForecastsUIProvider } from "./ForecastsUIContext";
import { ForecastsCard } from "./ForecastsCard";


export function ForecastsPage({ history }) {


  const forecastsUIEvents = {
    newForecastButtonClick: () => {
      history.push("/forecasts/new");
    },
    openSendForecastDialog: (id) => {
      history.push(`/forecasts/${id}/sendForecast`);
    },
    openLogsDialog: (id) => {
      history.push(`/forecasts/${id}/showLogs`);
    },
    openStatsDialog: (id) => {
      history.push(`/forecasts/${id}/showStats`);
    },
    openValidateForecastDialog: (id) => {
      history.push(`/forecasts/${id}/delete`);
    },
    openDeleteForecastsDialog: () => {
      history.push(`/forecasts/deleteForecasts`);
    },
    openFetchForecastsDialog: () => {
      history.push(`/forecasts/fetch`);
    },
    openUpdateForecastsStatusDialog: () => {
      history.push("/forecasts/updateStatus");
    }
  }

  return (
    <ForecastsUIProvider forecastsUIEvents={forecastsUIEvents}>
      <ForecastsLoadingDialog />
      <Route path="/forecasts/:id/sendForecast">
        {({ history, match }) => (
          <ForecastSendDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/forecasts");
            }}
          />
        )}
      </Route>

      <Route path="/forecasts/:id/showLogs">
        {({ history, match }) => (
          <ForecastLogsDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/forecasts");
            }}
          />
        )}
      </Route>
      <Route path="/forecasts/:id/showStats">
        {({ history, match }) => (
          <ForecastStatsDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/forecasts");
            }}
          />
        )}
      </Route>
      <Route path="/forecasts/:id/delete">
        {({ history, match }) => (
          <ForecastDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/forecasts");
            }}
          />
        )}
      </Route>
      <ForecastsCard />
    </ForecastsUIProvider>
  );
}
