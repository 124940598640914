import React, { Component } from "react";
import { LineChart, BarChart, Bar, ResponsiveContainer, Cell, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart } from "recharts";
import { chartColors } from "../../../../static/ChartColors";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../../_metronic/_partials/controls";

class PowerChart extends Component {
    constructor(props) {
        super(props);
        // console.log(props.chartData)
        // Get header row and data rows separately
        let header = props.chartData[0];
        let rows = props.chartData.slice(1);

        // Get indexes of timestamp and power columns
        let timestampIndex = header.indexOf('timestamp');
        let powerIndex = header.indexOf('power[kW]');

        // Iterate over rows and create new objects with timestamp and power values
        let result = rows.map(row => {
            return {
                timestamp: row[timestampIndex],
                power: row[powerIndex]
            };
        });

        let _latestTimestamp = result.map((res) => res.timestamp).sort((a, b) => new Date(b) - new Date(a))[0];

        // Subtract 48 hours (in milliseconds):
        const latestDate = new Date(_latestTimestamp);
        latestDate.setHours(latestDate.getHours() - 48);

        const dataInRange = this.getTimestampsInRange(result, latestDate, new Date(_latestTimestamp));
        const maxValue = Math.ceil((dataInRange.map((d) => d.power).sort((a, b) => b - a)[0])/100) * 100;

        this.state = {
            'data': result,
            'dataInRange': dataInRange,
            'maxValue': maxValue,
            'latestTimestamp': new Date(_latestTimestamp),
            'firstTimestamp': new Date(result[0].timestamp),
            'lastTimestamp': new Date(_latestTimestamp),
            // 'lastTimestamp': new Date(result[0].timestamp)
            'earliestTimestamp': latestDate,
        };
        console.log()

    }

    getTimestampsInRange(data, start, end) {
        const dataInRange = [];
        for (let i = 0; i < data.length; i++) {
            const timestamp = data[i].timestamp;
            if (new Date(timestamp) >= start && new Date(timestamp) <= end) {
                dataInRange.push(data[i]);
            }
        }

        return dataInRange;
    }

    handleChange = (event) => {
        const dataInRange = this.getTimestampsInRange(this.state.data, this.state.earliestTimestamp, new Date(event.target.value));
        this.setState({
            'latestTimestamp': new Date(event.target.value),
            'dataInRange': dataInRange,
            'maxValue': Math.ceil((dataInRange.map((d) => d.power).sort((a, b) => b - a)[0])/100) * 100,
        });
    }

    setEarliestTimestamp = (event) => {
        const dataInRange = this.getTimestampsInRange(this.state.data, new Date(event.target.value), this.state.latestTimestamp);
        this.setState({
            'earliestTimestamp': new Date(event.target.value),
            'dataInRange': dataInRange,
            'maxValue': Math.ceil((dataInRange.map((d) => d.power).sort((a, b) => b - a)[0])/100) * 100,
        });

    }

    render() {
        return (
            <div >
            <Card>
                <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 10, marginTop: 40, marginLeft:30 }}>
                    <div style={{ paddingRight: 20, }}>
                        <label htmlFor="date" style={{ marginRight: 10, }}>Start:</label>
                        <input type="date" min={this.state.firstTimestamp.toISOString().substr(0, 10)} max={this.state.lastTimestamp.toISOString().substr(0, 10)} id="date" value={this.state.earliestTimestamp.toISOString().substr(0, 10)} onChange={this.setEarliestTimestamp} />
                    </div>
                    <div>
                        <label htmlFor="date" style={{ marginRight: 10, }}>End:</label>
                        <input type="date" id="date" min={this.state.firstTimestamp.toISOString().substr(0, 10)} max={this.state.lastTimestamp.toISOString().substr(0, 10)} value={this.state.latestTimestamp.toISOString().substr(0, 10)} onChange={this.handleChange} />
                    </div>
                </div>
                <CardHeader title="Historical power of the plant"/>
                
                <ResponsiveContainer width="97%" height={500} >
                <ComposedChart  data={this.state.dataInRange} >
                    <XAxis dataKey="timestamp" label="Timestamp" height={60} />
                    <YAxis type="number" label="Power (kW)" unit="kW" domain={[0, this.state.maxValue]}  width={190} />
                   
                    <Tooltip />
                    <Legend />
                    <Line dataKey="power" name="Power" dot={false} fill={chartColors['powerGenerated']} stroke={chartColors['powerGenerated']} />
                </ComposedChart>
                </ResponsiveContainer>
                </Card>


            </div>
        );
    }
}

export default PowerChart;
