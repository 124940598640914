import React, { Component, useState } from "react";
import { Modal } from "react-bootstrap";
import Services from "../PlantsService";
import { InputField } from "../plant-edit-dialog/InputField";
import StartTimeField from "./StartTimeField";

import { withRouter } from 'react-router-dom';


function ResponseModal({ show, loading, message, onHide }) {
    return (
        <Modal size="sm" show={show} onHide={onHide}>
            <Modal.Body>
                {loading ? <p>Loading...</p> : <p>{message}</p>}
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    onClick={onHide}
                    className="btn btn-light btn-elevate"
                >
                    Close
                </button>
            </Modal.Footer>
            
        </Modal>
    );
}

class GetForecastForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalTitle: "Get Forecast",
            formErrors: null,
            forecast: {},
            loading: false, // Initialize loading state
            responseModalShow: false, // Initialize response modal visibility
            responseMessage: "", // Initialize response message
        };
        this.submitData = this.submitData.bind(this);
    }


    missingMandatoryField() {
        let allErrors = {};

        // check if all mandatory fields are filled
        if (this.state.forecast.startTime == null) {
            allErrors["startTime"] = "Please select Start Time.";
        }
        if (this.state.forecast.interval == null) {
            allErrors["interval"] = "Please select Interval";
        }
        if (this.state.forecast.duration == null) {
            allErrors["duration"] = "Please select forecast duration";
        }
        if (this.state.forecast.updatePeriod == null) {
            allErrors["updatePeriod"] = "Please select forecast update period.";
        }

        return allErrors;
    }

    async submitData() {
        this.setState({ formErrors: null, loading: true });
        let allErrors = this.missingMandatoryField();
        if (Object.keys(this.missingMandatoryField()).length > 0) {
            this.setState({ formErrors: true, loading: false  }, () => {
                this.setState();
            });
            return;
        }

        const data = {
            startTime: this.state.forecast.startTime,
            interval: this.state.forecast.interval,
            duration: this.state.forecast.duration,
            updatePeriod: this.state.forecast.updatePeriod,
            plantId: this.props.plantId
        };

        try {
            this.handleClose()
            this.setState({
                loading: false, // Set loading to false after API call
                responseModalShow: true, // Show the response modal
                responseMessage: "Processing", // Store response message
            });
            let response = await Services.getForecast(data);
            this.setState({
                loading: false, // Set loading to false after API call
                responseModalShow: true, // Show the response modal
                responseMessage: response.message, // Store response message
            });
            
            
        } catch (error) {
            console.error("Error submitting forecast data:", error);
            this.setState({ loading: false });
        }
    }

    saveUpdate = (field, data) => {
        let forecast = this.state.forecast;
        forecast[field] = data;
        this.setState(prevState => ({
            forecast: {
                ...prevState.forecast,
                [field]: data
            }
        }));
    }
    handleClose = () => {
        this.props.onHide();
    }

    handleModalClose = () => {
        window.location.reload()
        this.setState({ responseModalShow: false })
    }

    render() {
        return (
            <form onSubmit={this.submitData}>
                <Modal
                    size="lg"
                    show={this.props.show}
                    onHide={this.handleClose}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton >
                        <Modal.Title>{this.state.modalTitle} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <StartTimeField saveUpdate={this.saveUpdate} startTime={this.state.forecast.startTime} required label='Start Time' />
                        
                        <div className="form-group">
                            <label className="control-label" htmlFor="interval">Forecast Interval</label>
                            <div className="input-group mb-3">
                                <select
                                    className="form-control custom-select"
                                    name="interval"
                                    value={this.state.forecast.interval || ""}
                                    onChange={(event) => {
                                        this.saveUpdate("interval", event.target.value);
                                    }}
                                    required
                                >
                                    <option value="" disabled style={{ color: '#999' }}>Select interval</option> {/* Placeholder */}
                                    <option value="15">15</option>
                                    <option value="60">60</option>
                                </select>
                                <div className="input-group-append">
                                    <span className="input-group-text">min</span>
                                </div>
                            </div>
                        </div>


                        <div className="form-group">
                            <label className="control-label" htmlFor="duration">Forecast Duration</label>
                            <div className="input-group mb-3">
                                <select
                                    className="form-control custom-select"
                                    name="duration"
                                    value={this.state.forecast.duration || ""}
                                    onChange={(event) => {
                                        this.saveUpdate("duration", event.target.value);
                                    }}
                                    required
                                >
                                    <option value="" disabled style={{ color: '#999' }}>Select duration</option> {/* Placeholder */}
                                    <option value="24">24</option>
                                    <option value="48">48</option>
                                </select>
                                <div className="input-group-append">
                                    <span className="input-group-text">hours</span>
                                </div>
                            </div>
                        </div>


                        <InputField
                            type="number"
                            label="Forecast Period"
                            name="period"
                            minValue="1"
                            maxValue="24"
                            step="1"
                            placeholder="Forecast Period"
                            defaultValue={this.state.forecast.period}
                            value={this.state.forecast.period}
                            icon="hours"
                            onChange={(event) => {
                                this.saveUpdate(
                                    "updatePeriod", event.target.value,
                                );
                            }}
                            required
                        />
                        {this.state.formErrors != null ? <small>Please fill out all mandatory fields.</small> : <small></small>}
                    </Modal.Body>

                    <Modal.Footer>
                        <button
                            type="button"
                            onClick={this.handleClose}
                            className="btn btn-light btn-elevate"
                        >
                            Cancel
                        </button>
                        <> </>
                        <button
                            type="submit"
                            onClick={this.submitData}
                            className="btn btn-primary btn-elevate"
                        >
                            Get Forecast
                        </button>
                    </Modal.Footer>
                </Modal>
                <ResponseModal
                    show={this.state.responseModalShow}
                    loading={this.state.loading}
                    message={this.state.responseMessage}
                    onHide={this.handleModalClose}
                />                   

            </form>
        );
    }
}

export default withRouter(GetForecastForm);
