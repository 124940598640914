import React, { Component } from "react";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../../_metronic/_partials/controls";
import { Col, Row, Button } from "react-bootstrap";
import ForecastsService from "../forecasts/ForecastsService";
import ForecastChart from "./chart";
import { PlantInformation } from "./widgets/plantInformation";
import { ForecastWidget } from "./widgets/ForecastWidget"
import { WeatherWidget } from "./widgets/WeatherWidget";
import { PowerWidget } from "./widgets/PowerWidget";
import { HistoricalWidget } from "./widgets/HistoricalWidget"
import PlantsService from "../plants/PlantsService";
import { WindWeatherWidget } from "./widgets/WindWeatherWidget";

class ForecastDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plantId: '',
            plant: null,
            chartData: null,
            plantInfo:null,
            learning_basis_data: null,
            historical_data:null
        };
    }

    async componentDidMount() {

        const searchParams = new URLSearchParams(this.props.location.search);
        const plantId = searchParams.get("id");
        this.setState({ plantId: plantId });

        let plant_info = await ForecastsService.getPlantbyId(plantId);
        const info = plant_info.data.data.Item
        this.setState({plantInfo:info})
        
        let result = await ForecastsService.getForecastById(plantId);
        const file = result.data.data.Item.file_path;
        let data = await ForecastsService.getForecastFile(file);
        this.setState({ plant: result.data.data.Item, chartData: data });

        const historical_file = this.state.plantInfo.validation_path;
        if (historical_file) { // Check if validation_path exists before making the request
            console.log(this.state.plantInfo);
            console.log(historical_file);
            let validation_data = await ForecastsService.getValidationFile(historical_file);
            console.log("here");
            this.setState({ historical_data: validation_data });
        }
        
        const learning_basis_file = this.state.plantInfo.energyDataFile;
        let learning_basis_data = await PlantsService.getDataEnergyFile(learning_basis_file);
        this.setState({ learning_basis_data: learning_basis_data });
        
    }
    
    render() {
        if (!this.state.chartData) {
            return (<div>LOADING</div>)
        }
        
        console.log(this.state.plantInfo.plantType)
        let weatherWidget;
        if (this.state.plantInfo.plantType === "wind") {
            console.log("in wind weather")
            weatherWidget = (
                <WindWeatherWidget
                plant={this.state.plantInfo}
                index={0}
                chartData={this.state.learning_basis_data}
                className="card-stretch gutter-b h-75"
                />
                );
            } else {
            console.log("in solar weather")

            weatherWidget = (
                <WeatherWidget
                    plant={this.state.plantInfo}
                    index={0}
                    chartData={this.state.learning_basis_data}
                    className="card-stretch gutter-b h-75"
                />
            );
        }

        let historicalWidget = null;
        if (this.state.plantInfo.validation_path) {
            historicalWidget = (
                <HistoricalWidget
                    plantId={this.state.plantId}
                    chartData={this.state.historical_data}
                    plant={this.state.plantInfo}
                />
            );
        }
    
        return (
            <div>
                <PlantInformation plant={this.state.plantInfo} className="card-stretch gutter-b h-75" />

                <ForecastWidget plantId={this.state.plantId} plantName = {this.state.plantInfo?.plantName} chartData = {this.state.chartData} />

                {/* <HistoricalWidget plantId={this.state.plantId} chartData = {this.state.historical_data} plant={this.state.plantInfo} /> */}
                {historicalWidget}
                
                {weatherWidget} 

                <PowerWidget plant={this.state.plantInfo} chartData={this.state.learning_basis_data} className="card-stretch gutter-b h-75" />

            </div >
        );
    }
}


export default ForecastDetails;
