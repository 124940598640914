import React from 'react'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { useJsApiLoader } from '@react-google-maps/api'
import { useState } from 'react';

export function Map( {onSelect}) {

    const initialCenter={lat:51.1657, lng:10.4515}
    const [center, setCenter] = useState(initialCenter);
    const [markerPosition, setMarkerPosition] = useState(initialCenter);
    const handleMapClick = (event) => {
        const newCenter = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        }
        setCenter(newCenter);
        setMarkerPosition(newCenter);
        

        console.log(markerPosition)
    }

    const handleSelect =()=>
    {
        onSelect(markerPosition)
    }
    
    const {isLoaded}=useJsApiLoader({
        googleMapsApiKey: "AIzaSyB80fzCzYCDlzOsu5RTlDaqv7VzEP4rY1E",
    })
    
    if(!isLoaded)
return <div>Loading......</div>
return (<div>
       <GoogleMap
                        center={center}
                        zoom={7}
                        mapContainerStyle={{
                            width: '100%', // Set your desired width here
                            height: '800px' // Set your desired height here
                        }}
                        onClick={handleMapClick}
                    >
                        <Marker
                            position={markerPosition}
                            
                        />
        </GoogleMap>
        <div className="d-flex justify-content-end">
        <button
        type="button"
        onClick={handleSelect}
        className="btn btn-primary btn-elevate m-3">
            Select
        </button>
        </div>
    </div>)
}


