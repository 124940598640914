import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

export function PlantMap({ center }) {
    // Define the initial position and zoom level of the map
    const zoomLevel = 13;

    // Create a custom icon for the marker
    const icon = L.icon({
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41],
    });

    return (
        <div style={{ height: "400px", width: "100%" }}>
            <MapContainer center={center} zoom={zoomLevel}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors"
                />
                <Marker position={center} icon={icon}>
                </Marker>
            </MapContainer>
        </div>
    );
}