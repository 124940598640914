import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./ForecastsUIHelpers";

const ForecastsUIContext = createContext();

export function useForecastsUIContext() {
  return useContext(ForecastsUIContext);
}

export const ForecastsUIConsumer = ForecastsUIContext.Consumer;

export function ForecastsUIProvider({forecastsUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initForecast = {
    id: undefined,
    s3URL: "",
    timeInterval: 1,
    forecastName: "",
    focalPersonName: "",
    focalPersonEmail: "",
    focalPersonContact: "",
    numberOfPlants: 0,
    plantType: 0,
    plantDetails: [],
    forecastDateTime: new Date()
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initForecast,
    newForecastButtonClick: forecastsUIEvents.newForecastButtonClick,
    openSendForecastDialog: forecastsUIEvents.openSendForecastDialog,
    openLogsDialog: forecastsUIEvents.openLogsDialog,
    openStatsDialog: forecastsUIEvents.openStatsDialog,
    openValidateForecastDialog: forecastsUIEvents.openValidateForecastDialog,
    // openEditForecastDialog: forecastsUIEvents.openEditForecastDialog,
    // openDeleteForecastDialog: forecastsUIEvents.openDeleteForecastDialog,
    openDeleteForecastsDialog: forecastsUIEvents.openDeleteForecastsDialog,
    openFetchForecastsDialog: forecastsUIEvents.openFetchForecastsDialog,
    openUpdateForecastsStatusDialog: forecastsUIEvents.openUpdateForecastsStatusDialog
  };

  return <ForecastsUIContext.Provider value={value}>{children}</ForecastsUIContext.Provider>;
}