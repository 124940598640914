import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
// import { WeathersFilter } from "./weathers-filter/WeathersFilter";
import { WeathersTable } from "./weathers-table/WeathersTable";
// import { WeathersGrouping } from "./weathers-grouping/WeathersGrouping";
import { useWeathersUIContext } from "./WeathersUIContext";

export function WeathersCard() {
  const weathersUIContext = useWeathersUIContext();
  const weathersUIProps = useMemo(() => {
    return {
      ids: weathersUIContext.ids,
      newWeatherButtonClick: weathersUIContext.newWeatherButtonClick,
    };
  }, [weathersUIContext]);

  return (
    <Card>
      <CardHeader title="Weathers list">
        <CardHeaderToolbar>
          {/* <button
            type="button"
            className="btn btn-primary"
            onClick={weathersUIProps.newWeatherButtonClick}
          >
            New Weather
          </button> */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <WeathersFilter /> */}
        {/* {weathersUIProps.ids.length > 0 && <WeathersGrouping />} */}
        <WeathersTable />
      </CardBody>
    </Card>
  );
}
