import React, { useEffect, useState } from "react";

import {
    WeatherWidget,
    PlantInformation,
    ForecastWidget,
    PowerWidget
} from "../widgets";
import { DemoForecast } from "../widgets/DemoForecast";
import * as actions from "../../../app/modules/EForecast/_redux/plants/plantsActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../../app/modules/Auth/_redux/authCrud";


export function Demo3Dashboard() {
    const dispatch = useDispatch();
    const [selectedPlant, setSelectedPlant] = useState(null);
    const [userInformation, setUserInformation] = useState();
    

    useEffect(() => {
        getCurrentUser()
            .then((data) => {
                setUserInformation(data);
                
            })
            .catch((err) => console.error(err));
    }, []);
    
    
    const { currentState } = useSelector(
        (state) => ({ currentState: state.plants }),
        shallowEqual
    );
    let { totalCount, entities, listLoading } = currentState;
    
   
    console.log(entities)

    useEffect(() => {
        // server call by queryParams
        console.log("Fetching plants data...");
        dispatch(actions.fetchPlants());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    // for demo cases
    let entries = [
        { 'plantName': 'Example Solar Plant', 'id': '55860f24fe584eada891874117290f9e', 'plantType': 'solar', 'latitude': 51.717, 'longitude': 12.421, 'altitude': 225, 'maximumCapacity': 1000, tilt: 15, 'azimuth': 75 },
        // { 'plantName': 'SolarPlant2', 'id': '55860e24fe574eada891774117290f9e', 'plantType': 'solar', 'latitude': 50.71625, 'longitude': 11.3962222, 'altitude': 205, 'maximumCapacity': 1700, tilt: 15, 'azimuth': 165 },
        // { 'plantName': 'SolarPlant3', 'id': '56760e24fe574eada891774117290f9e', 'plantType': 'solar', 'latitude': 50.59295, 'longitude': 11.7386, 'altitude': 451, 'maximumCapacity': 500, tilt: 15, 'azimuth': 230 },
        // { 'plantName': 'SolarPlant4', 'id': '55860e24fe584eada892874117290f9e', 'plantType': 'solar', 'latitude': 52.7363546, 'longitude': 7.029578, 'altitude': 15, 'maximumCapacity': 12270, tilt: 10, 'azimuth': 269 },
        { 'plantName': 'Example Wind Plant', 'id': '55860e24fe574eada992874117291f9e', 'plantType': 'wind', 'modelTurbine': '8x Vestas V150 6.0 MW', 'latitude': 53.112225, 'longitude': 9.463481, 'altitude': 33, 'maximumCapacity': 48000 },
    ];

  
    return (<>
    
    {entities!=null && entities.length!=0? (
        <>
        <div className="row" style={{ marginBottom: 20, }}>
            <div className="col-lg-12 col-xxl-12">
                <div className="card card-custom ">
                    <div className="card-body">
                        {/* Header */}
                        <h1 style={{ paddingTop: 20 }}>
                            Hello {userInformation?.['name']}, nice to see you again! Here is the status of your plant { entities[0]?.plantName}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        <div className="row h-400px">


            <div className="col-lg-6 col-xxl-4">
                {/* Plant Health Widget */}
                <PlantInformation plant={selectedPlant ?? entities?.[0]} allPlants={entities} setSelectedPlant={setSelectedPlant} className="card-stretch gutter-b h-75" />
            </div>

            <div className="col-lg-6 col-xxl-8  ">
                {/* Weather Dettails Widget */}
                <WeatherWidget plant={selectedPlant ?? entities?.[0]} index={0} className="card-stretch gutter-b h-75" />
            </div>


        </div>
        <div className="row ">
            <div className="col-lg-6 col-xxl-6">
                {/* Power Generation Widget */}
                <PowerWidget plant={selectedPlant ?? entities?.[0]} className="card-stretch gutter-b h-75" />
            </div>
            <div className="col-lg-6 col-xxl-6">
                {/* Forecast versus generation widget Details Widget */}
                <ForecastWidget plant={selectedPlant ?? entities?.[0]} className="card-stretch gutter-b h-75" />

            </div>


        </div>
        <DemoForecast></DemoForecast>
    </>):(<>
        <div className="row" style={{ marginBottom: 20, }}>
            <div className="col-lg-12 col-xxl-12">
                <div className="card card-custom ">
                    <div className="card-body">
                        {/* Header */}
                        <h1 style={{ paddingTop: 20 }}>
                            Hello {userInformation?.['name']}, nice to see you again! Here are some visualizations of the demo plants. 
                        </h1>
                        <h6 style={{ paddingTop: 10, color: 'red' }}>You are seeing this because either you are not logged in or you have not added any plant. You can add plant by going to the Plants tab.</h6>
                    </div>
                </div>
            </div>
        </div>
        <div className="row h-400px">


            <div className="col-lg-6 col-xxl-4">
                {/* Plant Health Widget */}
                <PlantInformation plant={selectedPlant ?? entries?.[0]} allPlants={entries} setSelectedPlant={setSelectedPlant} className="card-stretch gutter-b h-75" />
            </div>

            <div className="col-lg-6 col-xxl-8  ">
                {/* Weather Dettails Widget */}
                <WeatherWidget plant={selectedPlant ?? entries?.[0]} index={0} className="card-stretch gutter-b h-75" />
            </div>


        </div>
        <div className="row ">
            <div className="col-lg-6 col-xxl-6">
                {/* Power Generation Widget */}
                <PowerWidget plant={selectedPlant ?? entries?.[0]} className="card-stretch gutter-b h-75" />
            </div>
            <div className="col-lg-6 col-xxl-6">
                {/* Forecast versus generation widget Details Widget */}
                <ForecastWidget plant={selectedPlant ?? entries?.[0]} className="card-stretch gutter-b h-75" />

            </div>


        </div>
        <DemoForecast></DemoForecast>
    </>)}
    
    </>);
}