import React, { Component, useState } from "react";
import { BsInfoCircleFill } from 'react-icons/bs'
import Tooltip from 'react-bootstrap/Tooltip';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Select } from "@material-ui/core";
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton';
export class InputField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            label: props.label,
            type: props.type ?? 'text',
            placeholder: props.placeholder,
            onChange: props.onChange,
            icon: props.icon,
            icon2: props.icon2,
            error: props.error,
            info: props.info,
            onIconChange:props.onIconChange,
            iconValue:props.iconValue

        };
    } 
    
    


    render() {
        return (
            <div className={"form-group " + (this.props.required ? 'required' : '')}>
                <label className="control-label" htmlFor="plantName">{this.state.label}</label>
                <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                        <Tooltip {...props}>
                            {this.state.info}
                        </Tooltip>
                    )}
                    placement="top"
                >
                    <BsInfoCircleFill className="mx-5" />
                </OverlayTrigger>
                <div className="input-group mb-3">
                    <input
                        type={this.props.type}
                        name={this.props.name}
                        className="form-control"
                        placeholder={this.props.placeholder}
                        value={this.props.defaultValue}
                        onChange={this.props.onChange}
                        required
                    />
                    {
                        (this.props.icon != null && this.props.icon2 == null) &&
                        <div className="input-group-append">
                            <span className="input-group-text">{this.props.icon}</span>
                        </div>
                    }
                    {
                        (this.props.icon != null && this.props.icon2 != null) &&
                        <div className="input-group-append">
                            
                            <select className="input-group-text"
                            onChange={this.state.onIconChange}
                            value={this.state.iconValue}
                            
                            
                            >
                                <option value="1">--Select Unit--</option>
                                <option value={this.state.icon}>{this.state.icon}</option>
                                <option value={this.state.icon2}>{this.state.icon2}</option>
                            </select>
                        </div>
                    }
                </div>
                <small>{this.state.error}</small>
            </div>
        );
    }


}