import React from "react";
import { useHistory } from 'react-router-dom';

export function DemoForecast() {

    const history = useHistory();

    function openDemoForecast() {
        history.push(`/forecast-details`);
    }

    return (
        <div onClick={() => openDemoForecast()} style={{ position: 'fixed', bottom: 0, right: 50, padding: 20, zIndex: 10, backgroundColor: '#E1F0FF', color: '#3699FF', borderTopLeftRadius: 10, borderTopRightRadius: 10, cursor: 'pointer', }}>
            <span className="text-primary font-weight-bold font-size-h6 mt-2">Demo Forecast</span>
        </div>
    );
}