import axios from "axios";

const apiURL = `${process.env.REACT_APP_URL}/api/plants`;
const energyDataFileURL = `${process.env.REACT_APP_URL}/api/energyDataFile`;
const demoData = `${process.env.REACT_APP_URL}/api/demoData`;
const weatherData = `${process.env.REACT_APP_URL}/api/WeatherData`;
const ForecastData = `${process.env.REACT_APP_URL}/api/ForecastData`;
const EnergyTradingData = `${process.env.REACT_APP_URL}/api/EnergyTradingData`;

export default {
  getPlants: async () => {
    try {
      const token = localStorage.getItem("token");
      let response = await axios({
        method: "GET",
        url: apiURL,
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).catch((error) => {
        return error.response;
      });

      const result = response?.data;
      result?.access_token && localStorage.setItem('token', result?.access_token);
      return response;
    }
    catch (e) {
      return []
    }

  },

  getPlantById: async (id) => {
    try {
      const token = localStorage.getItem("token");
      let response = await axios({
        method: "GET",
        url: apiURL + '/' + id,
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).catch((error) => {
        return error.response;
      });

      const result = response?.data;
      result?.access_token && localStorage.setItem('token', result?.access_token);
      return response;
    } catch (e) {
      return null;
    }
  },

  createPlant: async (data) => {
    const token = localStorage.getItem("token");
    console.log(data)
    let response = await axios({
      method: "POST",
      url: apiURL,
      data: data,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    const result = response.data;
    result.access_token && localStorage.setItem('token', result.access_token);
    return result;
  },

  getDataEnergyFile: async (file) => {
    const token = localStorage.getItem("token");
    let response = await axios({
      method: "GET",
      url: energyDataFileURL + '?path=' + file.file_path,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    const result = response.data;
    result.access_token && localStorage.setItem('token', result.access_token);
    return result;
  },

  getForecast: async (data) => {
    const forecastApiURL = `${process.env.REACT_APP_URL}/api/forecast`;
    const token = localStorage.getItem("token");
    let response = await axios({
      method: "POST",
      url: forecastApiURL,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(data)
    });

    const result = response.data;
    result.access_token && localStorage.setItem('token', result.access_token);
    return result;
  },

  getDemoData: async (plantId) => {
    const token = localStorage.getItem("token");
    let response = await axios({
      method: "GET",
      url: demoData,
      params: {
        plantId: plantId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    const result = response.data;
    result.access_token && localStorage.setItem('token', result.access_token);
    return result;
  },

  getWeatherData: async (plantId) => {
    const token = localStorage.getItem("token");
    let response = await axios({
      method: "GET",
      url: weatherData,
      params: {
        plantId: plantId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    const result = response.data;
    result.access_token && localStorage.setItem('token', result.access_token);
    return result;
  },
  getEnergyTradingData: async () => {
    const token = localStorage.getItem("token");
    let response = await axios({
      method: "GET",
      url: EnergyTradingData,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    const result = response.data;
    result.access_token && localStorage.setItem('token', result.access_token);
    return result;
  },

  getForecastData: async (plantId) => {
    const token = localStorage.getItem("token");
    let response = await axios({
      method: "GET",
      url: ForecastData,
      params: {
        id: plantId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });

    const result = response.data;
    result.access_token && localStorage.setItem('token', result.access_token);
    return result;
  },
};