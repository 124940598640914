import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import { Power_graph } from '../Components/charts/PowerGraph';
import { Yearly_Power_graph } from '../Components/charts/Yearly_Power_graph';
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { HeatMap1 } from '../Components/charts/Heatmap1';
import { WindPowerGraph } from '../Components/charts/WindPowerGraph';
import { WindCorrelation } from '../Components/charts/WindCorrelation';

const demoFileURL = `${process.env.REACT_APP_URL}/api/DemoDataFile`;
const Papa = require('papaparse');

export function DemoPage(props) {
  const history = useHistory();
  const [data1, setData1] = useState(null);
  const [data3, setData3] = useState(null); // State for Data3
  

  const parseURLParams = (url) => {
    const params = new URLSearchParams(new URL(url).search);
    const plant = {
      capacities: [],
      capacityUnits: [],
      tilts: [],
      azimuths: [],
      azimuthUnits: [],
      longitude: null,
      latitude: null,
      plantType: null,
    };

    for (const [key, value] of params.entries()) {
      const capacityMatch = key.match(/^plantCapacity-(\d+)$/);
      const capacityUnitMatch = key.match(/^capacityUnit-(\d+)$/);
      const tiltMatch = key.match(/^tilt-(\d+)$/);
      const azimuthMatch = key.match(/^azimuth-(\d+)$/);
      const azimuthUnitMatch = key.match(/^azimuthUnit-(\d+)$/);

      if (capacityMatch) {
        const index = parseInt(capacityMatch[1], 10);
        plant.capacities[index] = value;
      } else if (capacityUnitMatch) {
        const index = parseInt(capacityUnitMatch[1], 10);
        plant.capacityUnits[index] = value;
      } else if (tiltMatch) {
        const index = parseInt(tiltMatch[1], 10);
        plant.tilts[index] = value;
      } else if (azimuthMatch) {
        const index = parseInt(azimuthMatch[1], 10);
        plant.azimuths[index] = value;
      } else if (azimuthUnitMatch) {
        const index = parseInt(azimuthUnitMatch[1], 10);
        plant.azimuthUnits[index] = value;
      } else if (key === 'longitude') {
        plant.longitude = value;
      } else if (key === 'latitude') {
        plant.latitude = value;
      } else if (key === 'plantType') {
        plant.plantType = value;
      }
    }

    if (plant.plantType === 'solar') {
      plant.azimuths = plant.azimuths.map(azimuth => azimuth==='' ? 180 : parseInt(azimuth));
      console.log(plant.azimuths)
      plant.tilts = plant.tilts.map(tilt => tilt==='' ? 30 : parseInt(tilt));
      plant.capacities = plant.capacities.map((capacity, index) => {
        return plant.capacityUnits[index] === "kW" ? parseInt(capacity) * 1000 : parseInt(capacity);
      });
      plant.azimuths = plant.azimuths.map((azimuth, index) => {
        if (plant.azimuthUnits[index] === "° from South") {
          return azimuth >= 0 ? azimuth + 180 : 180 + azimuth;
        }
        return azimuth;
      });
    } 
    else {
      plant.capacities = plant.capacities.map((capacity, index) => {
        return plant.capacityUnits[index] === "kW" ? parseInt(capacity) * 1000 : parseInt(capacity);
      });
    }
   
   console.log(plant)
    return plant;
  };

  const url = window.location.href;
  const plantData = parseURLParams(url);

  console.log(plantData)

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/${plantData.plantType === "solar" ? "demo" : "WindPlantdemo"}`,
        plantData
      );
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  };


  const parsemultipleTheopowers = (csvData) => {
    const header = csvData?.[0];
    const rows = csvData?.slice(1) || [];

    const timestampIndex = header.indexOf("timestamp");
    const temperatureIndex = header.indexOf("temperature");
    const totalPowerIndex=header.indexOf("theo_power[W]");

    const theoPowerIndices = header
      .map((col, index) => ({ col, index }))
      .filter(item => item.col.startsWith("theo_power[W]_"))
      .map(item => item.index);

    const timestamps = [];
    const temperatures = [];
    const theoPowers = theoPowerIndices.map(() => []);
    const totalPower=[];

    rows.forEach(row => {
      timestamps.push(row[timestampIndex]);
      temperatures.push(row[temperatureIndex]);
      totalPower.push(row[totalPowerIndex]);
      theoPowerIndices.forEach((index, i) => {
        theoPowers[i].push(row[index]);

      });

    });

    return { timestamps, temperatures, totalPower, theoPowers };
  };




  const parsemultipleWindpowers = (csvData) => {
    const header = csvData?.[0];
    const rows = csvData?.slice(1) || [];

    const timestampIndex = header.indexOf("timestamp");
    const temperatureIndex = header.indexOf("temperature");
    const totalPowerIndex=header.indexOf("WPD");

    const theoPowerIndices = header
      .map((col, index) => ({ col, index }))
      .filter(item => item.col.startsWith("WPD_"))
      .map(item => item.index);

    const timestamps = [];
    const temperatures = [];
    const theoPowers = theoPowerIndices.map(() => []);
    const totalPower=[];

    rows.forEach(row => {
      timestamps.push(row[timestampIndex]);
      temperatures.push(row[temperatureIndex]);
      totalPower.push(row[totalPowerIndex]);
      theoPowerIndices.forEach((index, i) => {
        theoPowers[i].push(row[index]);

      });

    });

    return { timestamps, temperatures, totalPower, theoPowers };
  };

  useEffect(() => {
    const fetchDataAndSetData = async () => {
      const fetchedData = await fetchData();
      if (fetchedData) {
        if (plantData.plantType === "solar") {
          setData1(fetchedData["correlation"]);
          setData3(parsemultipleTheopowers(fetchedData.graph3_data));
          console.log(data3)
        } else {
          setData1(fetchedData["correlation"]);
          setData3(parsemultipleWindpowers(fetchedData.graph3_data));
          console.log(data3)
        }
      }
    };

    fetchDataAndSetData();
  }, []);
  console.log(data3)


  return (
    <div>
      {plantData.plantType === "solar" && data3 && data1 && (
        <div>
          {data3.theoPowers.map((theoPowerData, index) => (
            <div key={index}>
              <h1>Plant {index + 1}</h1>
              <p>Maximum Capacity: {plantData.capacities[index]} W</p>
              <p>Azimuth: {plantData.azimuths[index]}°</p>
              <p>Tilt: {plantData.tilts[index]}°</p>
              <Power_graph 
                chartData={{
                  timestamps: data3.timestamps,
                  temperatures: data3.temperatures,
                  theoPower: theoPowerData
                }} 
              />
            </div>
          ))}
        
        <div>
        <h1>Total Yearly Power</h1>
        <Yearly_Power_graph 
        chartData={{
                  timestamps: data3.timestamps,
                  temperatures: data3.temperatures,
                  theoPower: data3.totalPower
                }}/>
      </div>


      <div>
        <h1>Corelation Heatmap</h1>
        <HeatMap1 data={data1} />
      </div>
      </div>
      )}

      {plantData.plantType === "wind" && data3 && data1 && (
        
        <div>
          {data3.theoPowers.map((theoPowerData, index) => (
            <div key={index}>
              <h1>Plant {index + 1}</h1>
              <p>Maximum Capacity: {plantData.capacities[index]} W</p>
              <Power_graph 
                chartData={{
                  timestamps: data3.timestamps,
                  temperatures: data3.temperatures,
                  theoPower: theoPowerData
                }} 
              />
            </div>
          ))}
        
        <div>
        <h1>Total Yearly Power</h1>
        <Yearly_Power_graph chartData={{
                  timestamps: data3.timestamps,
                  temperatures: data3.temperatures,
                  theoPower: data3.totalPower
                }}/>

        
      </div>


      <div>
        <h1>Corelation Heatmap</h1>
        <WindCorrelation data={data1} />
      </div>
      </div>
      )}
    </div>
  );
}
