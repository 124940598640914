import axios from "axios";
import dotenv from 'dotenv';

dotenv.config();

const ROOT_URL = `${process.env.REACT_APP_URL}/`
export const LOGIN_URL = `${process.env.REACT_APP_URL}/login`;
export const REGISTER_URL = ROOT_URL.concat("api/auth/register");
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = `${process.env.REACT_APP_URL}/api/me`;

export async function login(email, password) {
  let response = axios.post(LOGIN_URL, { email, password });
  localStorage.setItem('token', (await response)?.data?.access_token);
  return response;
}

export function register(email, fullname, password) {
  return axios.post(REGISTER_URL, { email, fullname, password });
}

export async function getCurrentUser() {
  const token = localStorage.getItem("token");
  return axios({
    method: "GET",
    url: ME_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })
    .then((result) => result.data)
    .catch((error) => {
      return error.response;
    });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.

  var result = axios.get(ME_URL);
  return result;
}
