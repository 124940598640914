import React, { Component } from "react";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, AreaChart, Area} from "recharts";
import { chartColors } from "../../../../static/ChartColors";

class ForecastChart extends Component {
    constructor(props) {
        super(props);

        // Get header row and data rows separately
        let header = props.chartData[0];
        let rows = props.chartData.slice(1);

        // Get indexes of timestamp and power columns
        // let timestampIndex = header.indexOf('timestamp');
        let timestampIndex = header.findIndex(col => col.toLowerCase().includes('timestamp'));
        let powerIndex = header.findIndex(col => col.toLowerCase().includes('predicted_power'));
        // console.log(powerIndex)
        // powerIndex = header.indexOf('Predicted_Power');

        let powerUnit = 'kW'; // Default value
        if (header[powerIndex]) {
            const splitHeader = header[powerIndex].split('[');
            if (splitHeader.length > 1) {
                powerUnit = splitHeader[1].split(']')[0];
            }
        }

        let timestampUnit = 'UTC'; // Default value
        if(header[timestampIndex]) {
            const splitHeader = header[timestampIndex].split('[');
            if (splitHeader.length > 1) {
                timestampUnit = splitHeader[1].split(']')[0];
            }
        }

        // let sunheightIndex = header.indexOf('sunheight');
        // Iterate over rows and create new objects with timestamp and power values
        let result = rows.map(row => {
            return {
                timestamp: row[timestampIndex],
                power: row[powerIndex],
                // sunheight: row[sunheightIndex],
            };
        });

        let _latestTimestamp = result.map((res) => res.timestamp).sort((a, b) => new Date(b) - new Date(a))[0];

        // Subtract 48 hours (in milliseconds):
        const latestDate = new Date(_latestTimestamp);
        latestDate.setHours(latestDate.getHours() - 48);

        const dataInRange = this.getTimestampsInRange(result, latestDate, new Date(_latestTimestamp));
        // const maxValue = Math.ceil((result.map((d) => d.power).sort((a, b) => b - a)[0])/100) * 100;
        // const maxValue = Math.max(...result.map((d) => d.power));

        function calculateMaxValue(data) {
            let maxDataValue = Math.max(...data.map((d) => d.power));
            if (maxDataValue < 10) {
                // If max value is less than 10, round up to the nearest integer
                return Math.ceil(maxDataValue);
            } else {
                // If max value is 10 or more, round up to the nearest multiple of 10, 100, 1000, etc.
                let magnitude = Math.pow(10, Math.floor(Math.log10(maxDataValue)));
                return Math.ceil(maxDataValue / magnitude) * magnitude;
                // return Math.ceil((result.map((d) => d.power).sort((a, b) => b - a)[0])/100) * 100;
            }
        }

        // Usage:
        const maxValue = calculateMaxValue(result);




        this.state = {
            'data': result,
            'dataInRange': dataInRange,
            'maxValue': maxValue,
            'latestTimestamp': new Date(_latestTimestamp),
            'earliestTimestamp': latestDate,
            'powerUnit': powerUnit,
            timestampUnit: timestampUnit,
        };
    }
    // formatYAxisTick = (value) => Math.ceil(value);
    formatYAxisTick = (value) => {
        if (value >= 10) {
            return Math.ceil(value);
        } else {
            return value;
        }
    };


    getTimestampsInRange(data, start, end) {
        const dataInRange = [];
        for (let i = 0; i < data.length; i++) {
            const timestamp = data[i].timestamp;
            if (new Date(timestamp) >= start && new Date(timestamp) <= end) {
                dataInRange.push(data[i]);
            }
        }

        return dataInRange;
    }


    handleChange = (event) => {
        const dataInRange = this.getTimestampsInRange(this.state.data, this.state.earliestTimestamp, new Date(event.target.value));
        this.setState({
            'latestTimestamp': new Date(event.target.value),
            'dataInRange': dataInRange,
            'maxValue': Math.ceil((dataInRange.map((d) => d.power).sort((a, b) => b - a)[0])/100) * 100,
        });
    }

    setEarliestTimestamp = (event) => {
        const dataInRange = this.getTimestampsInRange(this.state.data, new Date(event.target.value), this.state.latestTimestamp);
        this.setState({
            'earliestTimestamp': new Date(event.target.value),
            'dataInRange': dataInRange,
            'maxValue': Math.ceil((dataInRange.map((d) => d.power).sort((a, b) => b - a)[0])/100) * 100,
        });
    }

    render() {
        // console.log(this.state.maxValue)
  
        return (
            <>
                {/* <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 10, marginTop: 40, }}>
                    <div style={{ paddingRight: 20, }}>
                        <label htmlFor="date" style={{ marginRight: 10, }}>Start:</label>
                        <input type="date" id="date" value={this.state.earliestTimestamp.toISOString().substr(0, 10)} onChange={this.setEarliestTimestamp} />
                    </div>
                    <div>
                        <label htmlFor="date" style={{ marginRight: 10, }}>End:</label>
                        <input type="date" id="date" value={this.state.latestTimestamp.toISOString().substr(0, 10)} onChange={this.handleChange} />
                    </div>
                </div> */}
                {/* <LineChart width={800} height={400} data={this.state.dataInRange} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <XAxis dataKey="timestamp" />
                    <YAxis type="number" domain={['dataMin', 1000]} />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="power" stroke="#8884d8" name="Predicted Power" />
                </LineChart> */}
                <div style={{ width: '100%' }}>
                    <ResponsiveContainer width="100%" height={500}>
                        <AreaChart
                            width={580}
                            height={300}
                            data={this.state.data}
                            margin={{ top: 20, right: 30, left: 5, bottom: 20 }}
                        >
                            <XAxis dataKey="timestamp" label={{ value: `Timestamp (${this.state.timestampUnit})`, position: 'insideBottom', dy: 10 }} />
                            <YAxis type="number" domain={[0, this.state.maxValue]} tickFormatter={this.formatYAxisTick} label={{ value: `Power (${this.state.powerUnit})`, angle: -90, position: 'insideLeft' }} />
                            {/* <YAxis type="number" domain={[0, this.state.maxValue]} tickFormatter={this.formatYAxisTick} label={{ value: `Power (${powerUnit})`, angle: -90, position: 'insideLeft' }} />           */}
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend />
                            <Area dataKey="power" fill="#99cc00" stroke="#99cc00" name="Predicted Power" />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </>
        );
    }
}

export default ForecastChart;