import { first, toInteger } from "lodash";
import React, { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  Line,
  Bar,
  ResponsiveContainer
} from "recharts";
import { chartColors } from "../../../app/static/ChartColors";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../controls";

export function EnergyChart({ chartData }) {

  const header = chartData?.[0];
  const rows = chartData?.slice(1) || [];

  const [interval, setInterval] = useState("None");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedWeek, setSelectedWeek] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())

  const timestampIndex = header?.indexOf("timestamp");
  const solarIndex = header?.indexOf("Solar");
  const loadIndex = header?.indexOf("Load");
  const Day_Ahead_AuctionIndex = header?.indexOf("Day_Ahead_Auction");
  const WindOnshoreIndex=header?.indexOf("Wind_onshore");
  const WindOffshoreIndex=header?.indexOf("Wind_offshore");


  const result = rows.map((row) => ({
    timestamp: row[timestampIndex],
    Solar: row[solarIndex]/1000,
    WindOffshore:row[WindOffshoreIndex]/1000,
    WindOnshore:row[WindOnshoreIndex]/1000,
    Load: row[loadIndex]/1000 ,
    Auction: row[Day_Ahead_AuctionIndex],
  }));



  const latestTimestamp = result
    .map((res) => res.timestamp)
    .sort((a, b) => new Date(b) - new Date(a))[0];

  const firstTimestamp = result
    .map((res) => res.timestamp)
    .sort((a, b) => new Date(a) - new Date(b))[0];


  const firstDate = new Date(firstTimestamp);
  const latestDate = new Date(latestTimestamp);
  let endingDate = new Date(selectedDate);
  console.log(endingDate)
  endingDate.setHours(0, 0, 0, 0)
  let startingDate = new Date(selectedDate);
  console.log(startingDate)
  startingDate.setHours(0, 0, 0, 0)
  let initialStartingDate = new Date(latestTimestamp);
  initialStartingDate.setHours(0, 0, 0, 0)
  initialStartingDate.setHours(initialStartingDate.getHours() - 96)
  let initialEndingDate= new Date(latestTimestamp);
  // initialEndingDate.setHours(initialEndingDate.getHours()-34)
  let endingWeek = new Date(selectedWeek);
  endingWeek.setHours(0, 0, 0, 0)
  let startingWeek = new Date(selectedWeek);
  startingWeek.setHours(0, 0, 0, 0)
  let endingofMonth = new Date(selectedYear, selectedMonth - 1, 31);
  endingofMonth.setHours(23, 0, 0, 0)
  let startingofMonth = new Date(selectedYear, selectedMonth - 1, 1);
  startingofMonth.setHours(0, 0, 0, 0)
  let endingofYear = new Date(selectedYear, 11, 31);
  endingofYear.setHours(23, 0, 0, 0)
  let startingofYear = new Date(selectedYear, 0, 1);
  startingofYear.setHours(1, 0, 0, 0)
  let dataInRange = getTimestampsInRange(result, initialStartingDate, latestDate);
  const currentYear = new Date().getFullYear();
  const startingYear = 2022;
  const years = Array.from({ length: currentYear - startingYear + 1 }, (_, index) => startingYear + index);


  if (interval === "day") {

    endingDate.setHours(endingDate.getHours() + 24)
    dataInRange = getTimestampsInRange(result, startingDate, endingDate);



  }
  else if (interval === "week") {
    startingWeek.setHours(selectedWeek.getHours() - 168)
    endingWeek.setHours(endingDate.getHours() + 24)
    dataInRange = getTimestampsInRange(result, startingWeek, endingWeek);


  }
  else if (interval === "month") {

    dataInRange = getTimestampsInRange(result, startingofMonth, endingofMonth);



  }
  else if (interval === "year") {

    dataInRange = getTimestampsInRange(result, startingofYear, endingofYear);



  }





  function getTimestampsInRange(data, start, end) {
    return data.filter(
      (row) => new Date(row.timestamp) >= start && new Date(row.timestamp) <= end
    );
  }


  const handleIntervalChange = (event) => {
    setInterval(event.target.value);
  };

  const handleDayPickerChange = (date) => {

    if (date > latestDate) {
      // If the selected date is beyond the maximum date, set it to the maximum date
      setSelectedDate(latestDate);
    } else {
       
      setSelectedDate(date);
    }

  };

  const handleWeekPickerChange = (date) => {


    if (date > latestDate) {
      // If the selected date is beyond the maximum date, set it to the maximum date
      setSelectedWeek(latestDate);

    } else {
      setSelectedWeek(date);
    }
  };


  const handleMonthPickerChange = (month) => {
    setSelectedMonth(month);
  };

  const handleYearPickerChange = (year) => {
    if (year > latestDate.getFullYear()) {
      // If the selected date is beyond the maximum date, set it to the maximum date
      setSelectedYear(latestDate.getFullYear());

    }
    else if (year < firstDate.getFullYear()) {
      // If the selected date is beyond the maximum date, set it to the maximum date
      setSelectedYear(firstDate.getFullYear());

    }
    else {
      setSelectedYear(year);
    }
  };


  return (
    <Card>
      <CardHeader title="Electricity production, load and day ahead auction prices in Germany">
        <CardHeaderToolbar></CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '20px' }}>
          <select
            value={interval}
            onChange={handleIntervalChange}
            style={{
              padding: '8px 12px',
              fontSize: '16px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              outline: 'none',
            }}
          >
            <option value="Select">Select</option>
            <option value="day">Day</option>
            <option value="week">Week</option>
            <option value="month">Month</option>
            <option value="year">Year</option>
          </select>

          {interval === 'day' && (
            <input
              type="date"
              value={selectedDate.toISOString().split('T')[0]}
              max="2024-03-20"
              onChange={(e) => 
                {
                //  let choosendate=new Date(e.target.value)
                //  choosendate.setHours(choosendate.getHours()+24)
                  handleDayPickerChange(new Date(e.target.value))}}
              style={{
                padding: '8px 12px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                outline: 'none',
              }}
            />
          )}

          {interval === 'week' && (
            <input
              type="date"
              value={selectedWeek.toISOString().split('T')[0]}
              max="2023-11-30"
              onChange={(e) => handleWeekPickerChange(new Date(e.target.value))}
              style={{
                padding: '8px 12px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                outline: 'none',
              }}
            />
          )}

          {interval === 'month' && (
            <div style={{ display: 'flex', gap: '10px' }}>
              <select
                value={selectedYear}
                onChange={(e) => handleYearPickerChange(parseInt(e.target.value))}
                style={{
                  padding: '8px 12px',
                  fontSize: '16px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  outline: 'none',
                }}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              <select
                value={selectedMonth}
                onChange={(e) => handleMonthPickerChange(parseInt(e.target.value))}
                style={{
                  padding: '8px 12px',
                  fontSize: '16px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  outline: 'none',
                }}
              >
                {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
          )}

          {interval === 'year' && (
            <select
              value={selectedYear}
              onChange={(e) => handleYearPickerChange(parseInt(e.target.value))}
              style={{
                padding: '8px 12px',
                fontSize: '16px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                outline: 'none',
              }}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          )}
        </div>

        {/* Chart */}
        <div style={{ width: '100%' }}>
          <ResponsiveContainer width="100%" height={500}>
            <ComposedChart
              data={dataInRange}
            >
              <XAxis dataKey="timestamp" label="TimeStamp" tick={false} />
              <YAxis yAxisId="left" type="number" label="Load / Solar" width={250} unit="GW"   />
              <YAxis yAxisId="right" orientation="right" type="number" label="Day Ahead Auction" domain={[-100, 550]} width={210} unit="€" />
              <Tooltip />
              <Legend />
              <Area
                yAxisId="left"
                type="monotone"
                dataKey="Load"
                label="Load"
                stroke={chartColors['load']}
                dot={false}
              />
             
              
             
              <Area
                yAxisId="left"
                dataKey="Solar"
                fill={chartColors['solar']}
                stroke={chartColors['solar']}
                label="Solar"

              />
              <Area
                yAxisId="left"
                dataKey="WindOnshore"
                name="Wind Onshore"
                fill="#006400"
                stroke="#006400"
                label="Wind OnShore"

              />
              <Area
                yAxisId="left"
                dataKey="WindOffshore"
                name="Wind Offshore"
                fill="#581845 "
                stroke="#581845 "
                label="Wind OffShore"

              />
               
               
              
              <Line
             
                yAxisId="right"
                type="monotone"
                dataKey="Auction"
                name="Day Ahead Auction Price"
                stroke="#FF0000"
                dot={false}
              />


            </ComposedChart>
          </ResponsiveContainer>
          <div className="text-center">Last Update at {initialEndingDate.toLocaleString()}</div>
          <div className="text-center text-primary py-3">
          Data extracted from Energy-charts.info
          </div>
        </div>
      </CardBody>
    </Card >
  );

}
