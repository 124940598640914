// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/plants/plantsActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../PlantsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { usePlantsUIContext } from "../PlantsUIContext";
import { Button } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import AdminService from "../../admin/AdminService";

export function PlantsTable() {


  const [forecastStatuses, setForecastStatuses] = useState({});
  const history = useHistory();

  function showForecast(id) {
    history.push(`/forecast-details?id=${id}`);
  }

  const plantsUIContext = usePlantsUIContext();
  const plantsUIProps = useMemo(() => {
    return {
      ids: plantsUIContext.ids,
      setIds: plantsUIContext.setIds,
      queryParams: plantsUIContext.queryParams,
      setQueryParams: plantsUIContext.setQueryParams,
      openEditPlantDialog: plantsUIContext.openEditPlantDialog,
      openDeletePlantDialog: plantsUIContext.openDeletePlantDialog,
      openForecastModal: plantsUIContext.openForecastModal,

    };
  }, [plantsUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.plants }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Customers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    plantsUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchPlants(plantsUIProps.queryParams));

  }, [plantsUIProps.queryParams, dispatch]);



  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "plantName",
      text: "Plant Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "plantType",
      text: "Plant Type",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cell, row) => {
        // Custom transformation function
        const plantType = row.plantType;

        // Function to capitalize the first letter of each word
        const capitalizeWords = (str) => {
          return str
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
        };

        // Apply transformation
        const transformedPlantType = capitalizeWords(plantType);

        return transformedPlantType;
      },
    },
    {
      dataField: "maximumCapacity",
      text: "Max Capacity(kW)",
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "forecast_status",
      text: "Forecast Status",
      sort: true,
      sortCaret: sortCaret,

      headerSortingClasses,
    },
    {
      dataField: "get",
      text: "Action",
      formatter: (cell, row) => {
        const forecastStatus = row.forecast_status;
        const isDisabled = forecastStatus === "waiting for approval";

        return (
          <Button style={{
            backgroundColor: "#736F90", // Dark shade of blue
            color: "white", // Text color
          }} onClick={() => {
            plantsUIProps.openForecastModal(row.id)
          }}
            disabled={isDisabled}
          >
            Get Forecast
          </Button>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
    {
      dataField: "view",
      text: "Forecast",
      formatter: (cell, row) => {
        const forecastStatus = row.forecast_status;
        const isDisabled = forecastStatus !== "Forecast Generated";

        return (
          <Button style={{
            backgroundColor: "#99cc00",
            color: "black", // Text color
          }} onClick={() => {
            showForecast(row.id)
          }}
            disabled={isDisabled}          >
            View Forecast
          </Button>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: plantsUIProps.queryParams.pageSize,
    page: plantsUIProps.queryParams.pageNumber,
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (e.target.tagName.toLowerCase() === 'button') {
        e.preventDefault();
        return;
      }
      if (plantsUIProps.ids.includes(row['id'])) {
        plantsUIProps.setIds([]);
        return;
      }
      plantsUIProps.setIds([row['id']]);
    },
  };

  function rowStyle(row, index) {
    const style = {};
    if (plantsUIProps.ids.includes(row.id)) {
      style.backgroundColor = 'var(--primary)';
    }
    return style;
  }


  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                rowEvents={rowEvents}
                columns={columns}
                rowStyle={(row, index) => rowStyle(row, index)}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  plantsUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
