import React from "react";
import { Route } from "react-router-dom";
// import { WeathersLoadingDialog } from "./weathers-loading-dialog/WeathersLoadingDialog";
// import { WeatherEditDialog } from "./weathers-edit-dialog/WeatherEditDialog";
// import { WeatherSendDialog } from "./weathers-sending-dialog/WeatherSendDialog";
// import { WeatherStatsDialog } from "./weathers-stats-dialog/WeatherStatsDialog";
// import { WeatherDeleteDialog } from "./weathers-delete-dialog/WeatherDeleteDialog";
// import { WeathersDeleteDialog } from "./weathers-delete-dialog/WeathersDeleteDialog";
// import { WeathersFetchDialog } from "./weathers-fetch-dialog/WeathersFetchDialog";
// import { WeathersUpdateStateDialog } from "./weathers-update-status-dialog/WeathersUpdateStateDialog";
import { WeathersUIProvider } from "./WeathersUIContext";
import { WeathersCard } from "./WeathersCard";

export function WeathersPage({ history }) {
  const weathersUIEvents = {
    newWeatherButtonClick: () => {
      history.push("/weathers/new");
    },
    openSendWeatherDialog: (id) => {
      history.push(`/weathers/${id}/sendWeather`);
    },
    openLogsDialog: (id) => {
      history.push(`/weathers/${id}/delete`);
    },
    openStatsDialog: (id) => {
      history.push(`/weathers/${id}/showStats`);
    },
    openValidateWeatherDialog: (id) => {
      history.push(`/weathers/${id}/delete`);
    },
    // openEditWeatherDialog: (id) => {
    //   history.push(`/weathers/${id}/edit`);
    // },
    // openDeleteWeatherDialog: (id) => {
    //   history.push(`/weathers/${id}/delete`);
    // },
    openDeleteWeathersDialog: () => {
      history.push(`/weathers/deleteWeathers`);
    },
    openFetchWeathersDialog: () => {
      history.push(`/weathers/fetch`);
    },
    openUpdateWeathersStatusDialog: () => {
      history.push("/weathers/updateStatus");
    }
  }

  return (
    <WeathersUIProvider weathersUIEvents={weathersUIEvents}>
      {/* <WeathersLoadingDialog /> */}
      {/* <Route path="/weathers/new">
        {({ history, match }) => (
          <WeatherEditDialog
            show={match != null}
            onHide={() => {
              history.push("/weathers");
            }}
          />
        )}
      </Route> */}
      <Route path="/weathers/:id/sendWeather">
        {/* {({ history, match }) => (
          <WeatherSendDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/weathers");
            }}
          />
        )} */}
      </Route><Route path="/weathers/:id/showStats">
        {/* {({ history, match }) => (
          <WeatherStatsDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/weathers");
            }}
          />
        )} */}
      </Route>
      <Route path="/weathers/:id/edit">
        {/* {({ history, match }) => (
          <WeatherEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/weathers");
            }}
          />
        )} */}
      </Route>
      {/* <Route path="/weathers/deleteWeathers">
        {({ history, match }) => (
          <WeathersDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/weathers");
            }}
          />
        )}
      </Route> */}
      <Route path="/weathers/:id/delete">
        {/* {({ history, match }) => (
          <WeatherDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/weathers");
            }}
          />
        )} */}
      </Route>
      {/* <Route path="/weathers/fetch">
        {({ history, match }) => (
          <WeathersFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/weathers");
            }}
          />
        )}
      </Route> */}
      {/* <Route path="/weathers/updateStatus">
        {({ history, match }) => (
          <WeathersUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/weathers");
            }}
          />
        )}
      </Route> */}
      <WeathersCard />
    </WeathersUIProvider>
  );
}
