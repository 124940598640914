/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import React, { useMemo, useLayoutEffect, useEffect, useState } from "react";
import objectPath from "object-path";
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { BreadCrumbs } from "./components/BreadCrumbs";
import { getBreadcrumbsAndTitle, useSubheader } from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout"
import { Checkbox } from "@material-ui/core";
import { getCurrentUser } from "../../../../app/modules/Auth/_redux/authCrud";

import { FormControlLabel } from '@material-ui/core';
import { InputDialogue } from "../../../../app/modules/EForecast/pages/Demo/Components/InputDialogue";


export function SubHeader() {
  let DisplayButton= true
 
  if(window.location.pathname=="/demo")
  {
    DisplayButton=false
  }
  const history = useHistory()


  const [userInformation, setUserInformation] = useState();

  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      )
    };
  }, [uiService]);

  useEffect(() => {
    getCurrentUser()
      .then((data) => {
        console.log('data', data);
        setUserInformation(data);
      })
      .catch((err) => console.error(err));
  }, []);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    subheader.setBreadcrumbs(aside.breadcrumbs || header.breadcrumbs);
    subheader.setTitle(aside.title || header.title);
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => { }, [subheader]);

  const [showPopup, setShowPopup] = useState(true);
  const [showDemoInput, setShowDemoInput] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);

  };



  const handleButtonClick = () => {
    setShowPopup(false);
  };


  return (
    <div>
      <div
        id="kt_subheader"
        className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
      >
        <div
          className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
        >
          {/* Info */}
          <div className="d-flex align-items-center flex-wrap mr-1">
            {layoutProps.subheaderMobileToggle && (
              <button
                className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
                id="kt_subheader_mobile_toggle"
              >
                <span />
              </button>
            )}

            <div className="d-flex align-items-baseline mr-5">
              <h5 className="text-dark font-weight-bold my-2 mr-5">
                <>
                  {subheader.title}
                </>
                {/*<small></small>*/}
              </h5>

            </div>


            <BreadCrumbs items={subheader.breadcrumbs} />
          </div>

          {/* Terms and conditions Popup */}
          {!showPopup &&
            <>
              <div className="fixed mt-20 inset-0 z-10 overflow-y-auto">
                <div
                  className="fixed inset-0 w-full h-full bg-black opacity-40">
                </div>
                <div className="flex items-center min-h-screen px-4 py-8">
                  <div className="relative w-full max-w-lg p-4 mx-auto bg-white rounded-md shadow-lg">
                    <div className="sm:flex">
                      <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-red-100 rounded-full">

                      </div>
                      <div className="mt-30 text-center sm:ml-4 sm:text-left">
                        <h2 className="text-lg font-medium text-gray-808">
                          Accept terms and conditions
                        </h2>
                        <p className="mt-2 text-[15px] leading-relaxed">
                          Before proceeding, we kindly ask our customers to ensure that they have read the terms and conditions in the contract.
                          These terms are essential to ensure a secure and seamless experience for all users.
                          By checking the checkbox, you acknowledge that you have reviewed and accepted our guidelines in the contract.
                        </p>
                        <FormControlLabel required control={<Checkbox onChange={handleChange} />} aria-required label="I agree to the terms and conditions." />
                        <div className="items-center gap-2 mt-3 sm:flex">

                          <button onClick={() => {
                            if (checked === true) {

                              setShowPopup(true); setShowDemoInput(true)
                              setChecked(false);
                            }
                            else {

                              alert("Please accept terms and conditions")
                            }
                          }} type="button" className="btn button-primary" style={{ backgroundColor: '#FF5C5C', color: 'white', marginRight: '10px' }}> Accept</button>

                          <button onClick={() => { setShowPopup(true) }} type="button" className="btn button-primary" style={{ marginRight: '10px' }}> Cancel</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </>}
          {/* Toolbar */}

          <div className="d-flex align-items-center">

            {showPopup && DisplayButton &&
              <button onClick={handleButtonClick} type="button"
                color="red"
                className="btn button-primary"
                style={{ backgroundColor: '#FF5C5C', color: 'white', marginRight: '10px' }}>
                Get Demo</button>}

            {showDemoInput && <InputDialogue show={showDemoInput} onHide={() => { setShowDemoInput(false) }} history={history}>


            </InputDialogue>}
          </div>

        </div>

      </div>

    </div>
  );
}
