import * as requestFromServer from "./plantsCrud";
import { plantsSlice, callTypes } from "./plantsSlice";

const { actions } = plantsSlice;
 
export const fetchPlants = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPlants(queryParams)
    .then((response) => {
      if (response && response.status == 200) {
        const { totalCount, entities } = response.data.data;
        dispatch(actions.plantsFetched({ totalCount, entities }));
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find plants";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchPlant = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.plantFetched({ plantForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getPlantById(id)
    .then((response) => {
      const plant = response.data.response.Item;
      dispatch(actions.plantFetched({ plantForEdit: plant }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find customer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deletePlant = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deletePlant(id)
    .then((response) => {
      dispatch(actions.plantDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete plant";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createPlant = (plantForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createPlant(plantForCreation)
    .then((response) => {
      if (response.status == 200) {
        const id = response.data.response;
        plantForCreation["id"] = id;
        const plant = plantForCreation;
        dispatch(actions.plantCreated({ plant }));
      } else {
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't create plant";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updatePlant = (plant) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updatePlant(plant)
    .then(() => {
      dispatch(actions.plantUpdated({ plant }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update plant";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCustomersStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForPlants(ids, status)
    .then(() => {
      dispatch(actions.plantsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update plants status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deletePlants = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deletePlants(ids)
    .then(() => {
      dispatch(actions.plantsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete plants";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
