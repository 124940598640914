/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import WeatherChart from "../../Charts/WeatherChart";
import PlantsService from "../../../../app/modules/EForecast/pages/plants/PlantsService";


export function WeatherWidget({ className, plant }) {

  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await PlantsService.getWeatherData(plant?.id);
        setChartData(data);

      } catch (error) {
        console.error("Failed to fetch chart data:", error);
      }
    };

    fetchData();
  }, [plant]);

  return (
    <div className={`card card-custom bg-gray-100 ${className}`} >
      {/* Header */}
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark">Weather</h3>
      </div>

      {/* Body */}
      <div className="card-body p-1" style={{ height: "400px" }}>
        {/* Chart */}

        <div style={{ height: "100%" }}>
          {chartData && <WeatherChart chartData={chartData} />}
        </div>


        {/* Resize */}
        <div className="resize-triggers">
          <div className="expand-trigger">
            <div style={{ width: "411px", height: "461px" }} />
          </div>
          <div className="contract-trigger" />
        </div>
      </div>
    </div>
  );
}
