import React, { useState } from "react";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ComposedChart,
    Line,
    Bar,
    Scatter
} from "recharts";

export function WindPowerGraph({ chartData }) {

    const header = chartData?.[0];
    const rows = chartData?.slice(1) || [];

    const timestampIndex = header?.indexOf("timestamp");
    const powerIndex = header?.indexOf("WPD");
    const windspeedIndex = header?.indexOf("windspeed[m/s]")


    const result = rows.map((row) => (


        {
            timestamp: row[timestampIndex],
            power: row[powerIndex] / 1000,
            WindSpeed: row[windspeedIndex],
        }));

    const latestTimestamp = result
        .map((res) => res.timestamp)
        .sort((a, b) => new Date(b) - new Date(a))[0];

    const latestDate = new Date(latestTimestamp);
    let startingDate = new Date(latestTimestamp);
    startingDate.setHours(latestDate.getHours() - 48)



    function getTimestampsInRange(data, start, end) {
        return data.filter(
            (row) => new Date(row.timestamp) >= start && new Date(row.timestamp) <= end
        );
    }

    let dataInRange = getTimestampsInRange(result, startingDate, latestDate);

    console.log(dataInRange)
    
  
    let newValue = 10
    let maxValue = dataInRange.map((d) => (d.Power / 1000)).sort((a, b) => b - a)[0]
    if (maxValue < 1) {
        newValue = 1
    }
    if (maxValue < 10) {
        newValue = 10
    }
    if (maxValue < 100) {
        newValue = 100
    }
    else if (maxValue < 1000) {
        newValue = 100000
    }
    else if (maxValue < 10000) {
        newValue = 10000
    }
    else if (maxValue < 100000) {
        newValue = 100000
    }
    else if (maxValue < 1000000) {
        newValue = 1000000
    }
    else if (maxValue < 10000000) {
        newValue = 10000000
    }

    const formatYAxisTick = (value) => Math.ceil(value); // Round down to the nearest whole number



    if (chartData === null)
        return (<div>Processing...</div>)

    return (
        <ComposedChart
            width={1000}
            height={500}
            data={dataInRange}
            margin={{ top: 20, right: 30, left: 5, bottom: 20 }}

        >
            <XAxis dataKey="timestamp" label="Timestamp" height={100} tickCount={3} />
            <YAxis yAxisId="left" label="Power" type="number" domain={[0, 'dataMax']} dot={false} tickFormatter={formatYAxisTick} unit="kW" width={185} />
            <YAxis yAxisId="right" orientation="right" label="Wind Speed" dot={false} unit="m/s" domain={[0, 60]} width={180} />

            {/* <CartesianGrid strokeDasharray="" /> */}
            <Tooltip />
            <Legend />
            <Area yAxisId="left" dataKey="power" fill="#99cc00" stroke="#99cc00" type="monotone" />
            <Line yAxisId="right" dataKey="WindSpeed" dot={false} name="Wind Speed" stroke="#736F90" type="monotone" />


        </ComposedChart>
    );
}



