import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { useHistory,  useNavigate  } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import {Map} from "./Map"
import { Link, Redirect } from "react-router-dom";
import Services from "../../plants/PlantsService";
import { DemoWindRequirementForm } from "./DemoInputFeildWind";
import { DemoRequirementForm } from "./DemoRequirementForm";
import { SelectDemoPlantType } from "../Components/SelectDemoPlantType";
import { useLoadScript } from "@react-google-maps/api";


export class InputDialogue extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      modalTitle: "Enter Plant Details",
      formErrors: null,
      plantType: null,
      step:0,
      plant: {
        
      },

    };
    this.submitData = this.submitData.bind(this);
    this.selectPlantType = this.selectPlantType.bind(this);
  }

 selectPlantType(value) {
    this.setState({
      plantType: value,
      step: 1,
      formErrors: null,
    });
  }

  missingMandatoryField() {
    let allErrors = [];


    if (this.state.plantType == "solar") {
      // if (this.state.plant.maximumCapacity == null) {
      //   allErrors["maximumCapacity"] = "Please enter maximum capacity.";
      // }
      if (this.state.plant.longitude == null) {
        allErrors["longitude"] = "Please enter the longitude.";
      }
      if (this.state.plant.latitude == null) {
        allErrors["latitude"] = "Please enter the latitude.";
      }
      if (this.state.plant.longitude <6.0 || this.state.plant.longitude>15.0 || this.state.plant.latitude <47 || this.state.plant.latitude>55.0) {
        allErrors["coordinatesExceed"] = "Please pay for subscription to access all the locations. For now you only have access to Germany.";
      }
      this.state.plant.capacities.forEach((capacity, index) => {
        console.log(capacity.maximumCapacity)
        if (capacity.maximumCapacity=='') {
          allErrors["maximumCapacity"] = "Please enter the maximum capacity.";
        }
        if (capacity.capacityUnit== '') {
          allErrors["capacityUnit"] = "Please select the capacity unit.";
        }
        // if (capacity.azimuth=='') {
        //   allErrors["azimuth"] = "Please enter the azimuth.";
        // }
        if (capacity.azimuth!='' &&capacity.azimuthUnit== '') {
          allErrors["azimuthUnit"] = "Please select the azimuth unit.";
        }
        // if (capacity.tilt=='') {
        //   allErrors["tilt"] = "Please enter the tilt.";
        // }
        if (capacity.maximumCapacity <3000 && capacity.capacityUnit == "W" ) {
      allErrors["maximumCapacity"] = "Please enter maximum capacity greater than 3000 W.";
        }
         if (capacity.maximumCapacity <3 && capacity.capacityUnit == "kW" ) {
           allErrors["maximumCapacity"] = "Please enter maximum capacity greater than 3 kW.";
        }
      });
      
      console.log(allErrors)
      // if (this.state.plant.capacityUnit == null) {
      //   allErrors["capacityUnit"] = "Please select capacity Unit.";
      // }
      // if (this.state.plant.capacityUnit == "1") {
      //   allErrors["capacityUnit"] = "Please select capacity Unit.";
      // }
    //   if (this.state.plant.azimuth!= null && this.state.plant.azimuthUnit == null) {
    //     allErrors["azimuthUnit"] = "Please select azimuth Unit.";
    //   }
    //   if (this.state.plant.azimuth!= null && this.state.plant.azimuthUnit == "1") {
    //     allErrors["azimuthUnit"] = "Please select azimuth Unit.";
    //   }
      
   
    
    
   
     

      return allErrors;
    }

  

  else if (this.state.plantType == "wind") {
    if (this.state.plant.longitude == null) {
      allErrors["longitude"] = "Please enter the longitude.";
    }
    if (this.state.plant.latitude == null) {
      allErrors["latitude"] = "Please enter the latitude.";
    }
    if (this.state.plant.longitude <6.0 || this.state.plant.longitude>15.0 || this.state.plant.latitude <47 || this.state.plant.latitude>55.0) {
      allErrors["coordinatesExceed"] = "Please pay for subscription to access all the locations. For now you only have access to Germany.";
    }
    this.state.plant.capacities.forEach((capacity, index) => {
      console.log(capacity.maximumCapacity)
      if (capacity.maximumCapacity=='') {
        allErrors["maximumCapacity"] = "Please enter the maximum capacity.";
      }
      if (capacity.capacityUnit== '') {
        allErrors["capacityUnit"] = "Please select the capacity unit.";
      }
     
      if (capacity.maximumCapacity <3000 && capacity.capacityUnit == "W" ) {
    allErrors["maximumCapacity"] = "Please enter maximum capacity greater than 3000 W.";
      }
       if (capacity.maximumCapacity <3 && capacity.capacityUnit == "kW" ) {
         allErrors["maximumCapacity"] = "Please enter maximum capacity greater than 3 kW.";
      }
    });
    
    console.log(allErrors)
   

    return allErrors;
  }

  return true;
}


  
  handleClose = () => {
    this.setState({
      plantType: null,
      formErrors: null,
      step: 0,
    });
    this.props.onHide();
  };
   
  navigateToDemo = (queryString) => {
    this.props.history.push(`/demo?${queryString}`);
  };

   submitData() {
    this.setState({ formErrors: null });
    let allErrors = this.missingMandatoryField();
    if (Object.keys(this.missingMandatoryField()).length > 0) {
      this.setState({ formErrors: allErrors }, () => {
        this.setState();
        
      }); 
    
    
      return;
    }


    const formData = new FormData();

  if(this.state.plantType=="solar"){
    console.log(this.state.plant)
    this.state.plant.capacities.forEach((plant, index) => {
      formData.append(`plantCapacity-${index}`, plant.maximumCapacity);
      formData.append(`capacityUnit-${index}`, plant.capacityUnit);
      formData.append(`tilt-${index}`, plant.tilt);
      formData.append(`azimuth-${index}`, plant.azimuth);
      formData.append(`azimuthUnit-${index}`, plant.azimuthUnit);
  });

      formData.append('plantType', 'solar');
      // formData.append('plantCapacity', this.state.plant.maximumCapacity);
      // formData.append('capacityUnit', this.state.plant.capacityUnit);
      formData.append('longitude', this.state.plant.longitude);
      formData.append('latitude', this.state.plant.latitude);
      // formData.append('altitude', this.state.plant.altitude);
      // formData.append('tilt', this.state.plant.tilt);
      // formData.append('azimuth', this.state.plant.azimuth);
      // formData.append('azimuthUnit', this.state.plant.azimuthUnit);
      const queryString = new URLSearchParams(formData).toString();
      
    this.navigateToDemo(queryString);
    this.handleClose();
  }
  if(this.state.plantType=="wind"){
    this.state.plant.capacities.forEach((plant, index) => {
      formData.append(`plantCapacity-${index}`, plant.maximumCapacity);
      formData.append(`capacityUnit-${index}`, plant.capacityUnit);
  });

      formData.append('plantType', 'wind');
      // formData.append('plantCapacity', this.state.plant.maximumCapacity);
      // formData.append('capacityUnit', this.state.plant.capacityUnit);
      formData.append('longitude', this.state.plant.longitude);
      formData.append('latitude', this.state.plant.latitude);
      // formData.append('altitude', this.state.plant.altitude);
      // formData.append('tilt', this.state.plant.tilt);
      // formData.append('azimuth', this.state.plant.azimuth);
      // formData.append('azimuthUnit', this.state.plant.azimuthUnit);
      const queryString = new URLSearchParams(formData).toString();
      
    this.navigateToDemo(queryString);
    this.handleClose();
  }
    
  }

    
  
  



  saveUpdate = (field, data) => {
    let plant = this.state.plant;
    plant[field] = data;
    this.setState(plant);
  }

  
  render() {
    return (
      <form onSubmit={this.submitData}>
        <Modal
          size="lg"
          show={this.props.show}
          onShow={this.onShow}
          onHide={this.handleClose}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton >
            <Modal.Title>{this.state.modalTitle} </Modal.Title>
          </Modal.Header>
          <Modal.Body >
            
          {this.state.step == 0 ?
              <SelectDemoPlantType selectPlantType={this.selectPlantType}></SelectDemoPlantType>
              :
              <></>
            }


            {(this.state.step == 1 &&
              this.state.plantType == "wind") ?
              <DemoWindRequirementForm saveUpdate={this.saveUpdate} formErrors={this.state.formErrors}></DemoWindRequirementForm>
              : <></>
            }

            {(this.state.step == 1 &&
              this.state.plantType == "solar") ?
              <DemoRequirementForm saveUpdate={this.saveUpdate} formErrors={this.state.formErrors}></DemoRequirementForm>
              : <></>
            }

              

            {this.state.formErrors != null ?<div> <div className="text-danger small">{this.state.formErrors["maximumCapacity"]}</div>
            <div className="text-danger small">{this.state.formErrors["longitude"]}</div>
            <div className="text-danger small">{this.state.formErrors["latitude"]}</div>
            <div className="text-danger small">{this.state.formErrors["capacityUnit"]}</div>
            <div className="text-danger small">{this.state.formErrors["azimuthUnit"]}</div>
            <div className="text-danger large">{this.state.formErrors["coordinatesExceed"]}</div></div> : <small></small>}
          </Modal.Body>
          
            <Modal.Footer>
            
              <button
                type="button"
                onClick={this.handleClose}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              
              <button
                type="submit"
                onClick={this.submitData}
                className="btn btn-primary btn-elevate"
                
              >
                
                Proceed
              </button>
              
            </Modal.Footer> 
          
        </Modal>
      </form>
    );
  }
}

