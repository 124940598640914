import axios from "axios";

const energyDataFileURL = `${process.env.REACT_APP_URL}/api/forecastFile`;
const ForecastDataURl = `${process.env.REACT_APP_URL}/api/forecast`;
const ForecastFileUrl = `${process.env.REACT_APP_URL}/api/forecastDataFile`;
const ValidationFileUrl = `${process.env.REACT_APP_URL}/api/validationDataFile`;
const apiURL = `${process.env.REACT_APP_URL}/api/plants`;

export default {

    exportForecastsFile: async (path) => {
        const token = localStorage.getItem("token");
        let response = await axios({
            method: "GET",
            url: energyDataFileURL + '?path=' + path,
            headers: {
                Authorization: `Bearer ${token}`,

            },
            responseType: "blob"
        });

        const result = response.data;
        result.access_token && localStorage.setItem('token', result.access_token);
        return result;

    },
    getForecastById: async (id) => {
        try {
            const token = localStorage.getItem("token");
            let response = await axios({
                method: "GET",
                url: ForecastDataURl + '/' + id,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).catch((error) => {
                return error.response;
            });

            const result = response?.data;
            result?.access_token && localStorage.setItem('token', result?.access_token);
            return response;
        } catch (e) {
            return null;
        }
    },

    getForecastFile: async (file) => {
        const token = localStorage.getItem("token");
        let response = await axios({
            method: "GET",
            url: ForecastFileUrl + '?path=' + file,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        });

        const result = response.data;
        result.access_token && localStorage.setItem('token', result.access_token);
        return result;
    },

    getValidationFile: async (file) => {
        const token = localStorage.getItem("token");
        let response = await axios({
            method: "GET",
            url: ValidationFileUrl + '?path=' + file,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        });

        const result = response.data;
        result.access_token && localStorage.setItem('token', result.access_token);
        return result;
    },


    getPlantbyId: async (id) => {
        try {
            const token = localStorage.getItem("token");
            let response = await axios({
                method: "GET",
                url: apiURL + '/' + id,
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).catch((error) => {
                return error.response;
            });

            const result = response?.data;
            result?.access_token && localStorage.setItem('token', result?.access_token);
            return response;
        } catch (e) {
            return null;
        }
    }


};
