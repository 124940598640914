import React from 'react'
import { EnergyChart } from "../../../../../../_metronic/_partials/Charts/EnergyChart"
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { PieChart } from '../../../../../../_metronic/_partials/Charts/PieChart';
import { useMemo, useEffect, useState } from "react";
import PlantsService from "../../../pages/plants/PlantsService"
import { useHtmlClassService } from "../../../../../../_metronic/layout"

export function EnergyForecast() {
  const uiService = useHtmlClassService();
  const [chartData, setChartData] = useState(null);

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseDanger: objectPath.get(
        uiService.config,
        "js.colors.theme.base.danger"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily")
    };
  }, [uiService]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await PlantsService.getEnergyTradingData();
        setChartData(data);

      } catch (error) {
        console.error("Failed to fetch chart data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
    <EnergyChart chartData={chartData} />
    <div className='d-flex flex-wrap justify-content-center align-items-center mt-5' style={{ background: 'white'}}></div>
    <PieChart/>
    <div/>
    
</>
  )
}