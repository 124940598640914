/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../../../../_metronic/_partials/controls";
import { Col, Row, Button } from "react-bootstrap";

import HistoricalChart from "./HistoricalChart";


export function HistoricalWidget({ className, plantName, chartData, plantId, plant }) {




  return (
    <>
<Card>
                    <CardHeader title={`Forecast validation NRMSE: ${parseFloat(plant.nrmse).toFixed(3)} %`}>
                   
                    </CardHeader>   

                    <CardBody>
                        <Row>
                            <Col>
                                {chartData != null && <HistoricalChart chartData={chartData}></HistoricalChart>}
                            </Col>
                            
                        </Row>
                    </CardBody>
                </Card>
    </>
  );
}
