// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/weathers/weatherActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../WeathersUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useWeathersUIContext } from "../WeathersUIContext";

export function WeathersTable() {
  // Weathers UI Context
  const weathersUIContext = useWeathersUIContext();
  const weathersUIProps = useMemo(() => {
    return {
      ids: weathersUIContext.ids,
      setIds: weathersUIContext.setIds,
      queryParams: weathersUIContext.queryParams,
      setQueryParams: weathersUIContext.setQueryParams,
      openSendWeatherDialog: weathersUIContext.openSendWeatherDialog,
      openLogsDialog: weathersUIContext.openLogsDialog,
      openStatsDialog: weathersUIContext.openStatsDialog,
      openValidateWeatherDialog: weathersUIContext.openValidateWeatherDialog,
      // openEditWeatherDialog: weathersUIContext.openEditWeatherDialog,
      // openDeleteWeatherDialog: weathersUIContext.openDeleteWeatherDialog,
    };
  }, [weathersUIContext]);

  // Getting curret state of weathers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.weathers }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Weathers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    weathersUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchWeathers(weathersUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weathersUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "plantId",
      text: "Plant ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "customerName",
      text: "Customer Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    // {
    //   dataField: "email",
    //   text: "Email",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    // {
    //   dataField: "contact",
    //   text: "Contact",
    //   sort: false,
    //   sortCaret: sortCaret,
    // },
    {
      dataField: "weatherStatus",
      text: "Weather Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,
      headerSortingClasses,
    },
    // {
    //   dataField: "type",
    //   text: "Type",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   formatter: columnFormatters.TypeColumnFormatter,
    // },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openSendWeatherDialog: weathersUIProps.openSendWeatherDialog,
        openLogsDialog: weathersUIProps.openLogsDialog,
        openStatsDialog: weathersUIProps.openStatsDialog,
        openValidateWeatherDialog: weathersUIProps.openValidateWeatherDialog,
        // openEditWeatherDialog: weathersUIProps.openEditWeatherDialog,
        // openDeleteWeatherDialog: weathersUIProps.openDeleteWeatherDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: weathersUIProps.queryParams.pageSize,
    page: weathersUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  weathersUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: weathersUIProps.ids,
                  setIds: weathersUIProps.setIds,
                })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
