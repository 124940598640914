import axios from "axios";
 
export const FORECASTS_URL = `${process.env.REACT_APP_URL}/api/forecasts"`;

// CREATE =>  POST: add a new forecast to the server
export function createForecast(forecast) {
  return axios.post(FORECASTS_URL, { forecast });
}

// READ
export function getAllForecasts() {

  return axios.get(FORECASTS_URL);
}

export function getForecastById(forecastId) {
  return axios.get(`${FORECASTS_URL}/${forecastId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findForecasts(queryParams) {

  return axios.post(`${FORECASTS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the forecast on the server
export function updateForecast(forecast) {
  return axios.put(`${FORECASTS_URL}/${forecast.id}`, { forecast });
}

// UPDATE Status
export function updateStatusForForecasts(ids, status) {
  return axios.post(`${FORECASTS_URL}/updateStatusForForecasts`, {
    ids,
    status
  });
}

// DELETE => delete the forecast from the server
export function deleteForecast(forecastId) {
  return axios.delete(`${FORECASTS_URL}/${forecastId}`);
}

// DELETE Forecasts by ids
export function deleteForecasts(ids) {
  return axios.post(`${FORECASTS_URL}/deleteForecasts`, { ids });
}
