import React from 'react'
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ComposedChart,
    Line,
    Bar,
    ResponsiveContainer
  } from "recharts";
  import { Card, CardHeader } from "../../../../../_metronic/_partials/controls";

  import { chartColors } from "../../../../static/ChartColors";
import { utcToZonedTime, format } from 'date-fns-tz';
import { startOfMonth, startOfQuarter } from 'date-fns';

export function QuarterlyChart({ chartData }) {
  
    const header = chartData?.[0];
    const rows = chartData?.slice(1) || [];
  
    const timestampIndex = header?.indexOf("timestamp");
    const powerIndex = header?.indexOf("power[kW]");
    const result = rows.map((row) => (
      {
        timestamp:  utcToZonedTime(new Date(row[timestampIndex]), 'UTC'),
        power: parseFloat(row[powerIndex])
    
        
      }));
      

  const quarterly = result.reduce((acc, entry) => {
    const quarterKey = format(startOfQuarter(entry.timestamp), 'Qo-yyyy');
    if (!acc[quarterKey]) {
      acc[quarterKey] = { timestamp: startOfQuarter(entry.timestamp), power: 0 };
      console.log(quarterKey)
      console.log(acc[quarterKey])
    }
    acc[quarterKey].power = acc[quarterKey].power+ entry.power;
    
    return acc;
  }, {});

  const quarterlyArray = Object.keys(quarterly).map(key => ({
    timestamp: quarterly[key].timestamp,
    power: quarterly[key].power/1000,
    Quarter: key
  }));
  console.log(quarterlyArray)


 
    if (chartData === null)
      return (<div>Processing...</div>)
  
    return (
      <Card>
        <CardHeader title="Quarterly accumulative power of the plant"/>
      <ResponsiveContainer width="97%" height={500} >
      
      <ComposedChart
        
        data={quarterlyArray}
        margin={{ top: 30, right: 30, left: 5, bottom: 2 }}
  
      >
        <XAxis dataKey="Quarter" label="Timestamp" height={60} tickCount={3} />
        <YAxis yAxisId="left" label="Power" type="number"  unit="MW" width={190} />
  
        <Tooltip />
        <Legend />
        <Bar yAxisId="left" dataKey="power" name="Power" fill="#99cc00" type="monotone" />
        
  
  
      </ComposedChart>
      </ResponsiveContainer>
      </Card>
    );
  
}

