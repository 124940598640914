import React, { useEffect, useState } from 'react';
import dotenv from 'dotenv';

dotenv.config();

const WeatherData = ({ location }) => {
    const [weatherData, setWeatherData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const apiKey = process.env.REACT_APP_WEATHER_API;

            const apiUrl = `https://api.openweathermap.org/data/2.5/forecast?lat=${location.latitude}&lon=${location.longitude}&appid=${apiKey}&units=metric`;

            try {
                const response = await fetch(apiUrl);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setWeatherData(data);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, []);

    function getUrl(icon) {
        return `https://openweathermap.org/img/wn/${icon}@2x.png`;
    }

    function getDateString(datetime) {
        const date = new Date(datetime * 1000);

        const options = { month: 'long', day: 'numeric' };
        const monthDay = date.toLocaleString('en-US', options);

        return `${monthDay}, ${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`;
    }


    return (
        <div>
            <h2 style={{ paddingBottom: 20 }}>Weather information for <b>{weatherData?.city.name}</b></h2>

            <div style={{ display: 'flex', overflow: 'hidden' }}>
                {weatherData?.list.slice(0, 3).map((item) => (
                    <div key={item.dt} style={{ flex: 1, flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                        <p>{getDateString(item.dt)}</p>
                        <img src={getUrl(item.weather[0].icon)} alt="Weather Icon" />
                        <h1 style={{ fontSize: 20 }}>{parseInt(item.main.temp)} °C</h1>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WeatherData;