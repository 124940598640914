import React, { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  Line,
  Bar,
  Scatter
} from "recharts";
import { chartColors } from "../../../../../../static/ChartColors";


export function Yearly_Power_graph({ chartData, onLowPower }) {

  const { timestamps, temperatures, theoPower } = chartData;
             
        
            // Combine data into an array of objects and ensure proper date formatting
            const result = timestamps.map((Timestamp, index) => ({
                timestamp: Timestamp, 
                Power: theoPower[index] / 1000,
                Temperature: temperatures[index]}));
  
    
    const total_power = result.reduce((accumulator, currentValue) => {
      console.log(accumulator)
      return accumulator + currentValue.Power;

    }, 0); // Initialize total_power with 0
    if (Math.ceil(total_power) / 1000 < 0) { // Convert total_power to MWh and check condition
      onLowPower && onLowPower(); // Call the callback function if provided
    }

   
  


  let newValue = 10
  let maxValue = result.map((d) => (d.Power / 1000)).sort((a, b) => b - a)[0]
  if (maxValue < 1) {
    newValue = 1
  }
  if (maxValue < 10) {
    newValue = 10
  }
  if (maxValue < 100) {
    newValue = 100
  }
  else if (maxValue < 1000) {
    newValue = 100000
  }
  else if (maxValue < 10000) {
    newValue = 10000
  }
  else if (maxValue < 100000) {
    newValue = 100000
  }
  else if (maxValue < 1000000) {
    newValue = 1000000
  }
  else if (maxValue < 10000000) {
    newValue = 10000000
  }

  const formatYAxisTick = (value) => Math.ceil(value); // Round down to the nearest whole number


  if (chartData === null)
    return (<div>Processing...</div>)

  return (
    <div>
      <br/>
      
      <h5>Estimated Yearly Production: { Math.ceil(total_power) / 1000} MWh </h5>
    <br/>
    <br/>
    <ComposedChart
      width={1000}
      height={500}
      data={result}
      margin={{ top: 20, right: 30, left: 5, bottom: 20 }}

    >
      <XAxis dataKey="timestamp" label="Timestamp" height={100} tickCount={3} />
      <YAxis yAxisId="left" label="Power" type="number" domain={[0, 'dataMax']} tickFormatter={formatYAxisTick} unit="kW" width={185} />
      
      {/* <CartesianGrid strokeDasharray="" /> */}
      <Tooltip />
      <Legend />
      <Area yAxisId="left" dataKey="Power" fill="#99cc00" stroke="#99cc00" type="monotone" />
      

    </ComposedChart>
    </div>
  );
}



