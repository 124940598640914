import React from "react";
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { chartColors } from "../../../../../static/ChartColors";

function WeatherChart({ chartData }) {
  const header = chartData?.[0];
  console.log(header)
  const rows = chartData?.slice(1) || [];

  const timestampIndex = header?.indexOf("timestamp");
  const millisecondsIndex = header?.indexOf("milliseconds");
  const temperatureIndex = header?.indexOf("temperature");
  const cloudCoverIndex = header?.indexOf("cloudcover");
  const humidityIndex = header?.indexOf("humidity");
  const pressureIndex = header?.indexOf("pressure");

  const result = rows.map((row) => ({
    timestamp: row[timestampIndex],
    milliseconds: row[millisecondsIndex],
    temperature: parseFloat(row[temperatureIndex]), // Parse temperature as a float
    cloudCover: parseFloat(row[cloudCoverIndex]), // Parse cloudCover as a float
    humidity: parseFloat(row[humidityIndex]), // Parse humidity as a float
    pressure: parseFloat(row[pressureIndex]), // Parse pressure as a float
  }));

  // Calculate the min and max values for temperature, humidity, and cloudCover
  
  
  
  const latestTimestamp = result
  .map((res) => res.timestamp)
  .sort((a, b) => new Date(b) - new Date(a))[0];
  
  const latestDate = new Date(latestTimestamp);
  latestDate.setHours(latestDate.getHours() - 48);
  
  const dataInRange = getTimestampsInRange(
      result,
      latestDate,
      new Date(latestTimestamp)
      );
      const minTemperature = Math.min(...dataInRange.map((data) => data.temperature));
      const maxTemperature = Math.max(...dataInRange.map((data) => data.temperature));

  function getTimestampsInRange(data, start, end) {
    return data.filter(
      (row) => new Date(row.timestamp) >= start && new Date(row.timestamp) <= end
    );
  }

  const customTickFormatter = (timestamp) => {
    const date = new Date(parseInt(timestamp));

    if (!timestamp || !date) {
      return "";
    }

    const formattedDate =
      `${date.getDate() < 10 ? "0" : ""}${date.getDate()}.` +
      `${date.getMonth() + 1 < 10 ? "0" : ""}${date.getMonth() + 1}.` +
      `${date.getFullYear()} ` +
      `${date.getHours() < 10 ? "0" : ""}${date.getHours()}:` +
      `${date.getMinutes() < 10 ? "0" : ""}${date.getMinutes()}`;

    return formattedDate;
  };

  if (!chartData) {
    return(<div> Loading </div>)
  }
  return (
    <div style={{ width: "100%" }}>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          width={780}
          height={300}
          data={dataInRange}
          margin={{ bottom: 20, left: 30, right: 40, top: 60 }}
        >
          <XAxis dataKey="timestamp" />
          <YAxis yAxisId="temperature" dataKey="temperature" domain={[minTemperature, maxTemperature]}>
            {/* Add a Label component to the YAxis */}
            <Label value="Temperature (°C)" offset={15} position="top" angle={0} paddingLeft={10} />
          </YAxis>
          {humidityIndex > -1 && (
            <YAxis yAxisId="humidity" dataKey="humidity" domain={[0, 'dataMax']}>
              {/* Add a Label component to the YAxis */}
              <Label value="Humidity (%)" offset={15} position="top" angle={0} />
            </YAxis>
          )}
          <YAxis yAxisId="cloudCover" dataKey="cloudCover" orientation="right">
            {/* Add a Label component to the YAxis */}
            <Label value="Cloud Cover (%)" offset={15} position="top" angle={0} />
          </YAxis>
          <Tooltip />
          <Legend />
          <Bar
            yAxisId="cloudCover"
            dataKey="cloudCover"
            name="Cloud Cover"
            fill={chartColors["cloudCover"]}
            barSize={5}
          />
          <Line
            yAxisId="temperature"
            type="monotone"
            name="Temperature"
            dataKey="temperature"
            stroke={chartColors["temperature"]}
          />
          {humidityIndex > -1 && (
            <Line
              yAxisId="humidity"
              type="monotone"
              name="Humidity"
              dataKey="humidity"
              stroke={chartColors["humidity"]}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default WeatherChart;
