// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
// import {DateTimePicker} from 'formik-mui-lab';
import * as Yup from "yup";
import {
  Input,
  Select,
  DatePickerField,
} from "../../../../../../_metronic/_partials/controls";

// Validation schema
const ForecastSendSchema = Yup.object().shape({
  customerName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Forecast Name is required"),
    focalPersonName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Focal Person Name is required"),
    focalPersonEmail: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
    focalPersonContact: Yup.string().required("Focal Person Contact is required"),
    numberOfPlants: Yup.number().required("Number of plants is required"),
  // dateOfBbirth: Yup.mixed()
  //   .nullable(false)
  //   .required("Date of Birth is required"),
  // ipAddress: Yup.string().required("IP Address is required"),
});

export function ForecastSendForm({
  saveForecast,
  forecast,
  actionsLoading,
  onHide,
}) {
  return (
    <>
        
        <Formik
            enableReinitialize={true}
            initialValues={forecast}
            validationSchema={ForecastSendSchema}
            onSubmit={(values) => {
            saveForecast(values);
            }}
        >
            {({ handleSubmit, values }) => (
            <>
                <Modal.Body className="overlay overlay-block">
                {actionsLoading && (
                    <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                    </div>
                )}
                <Form className="form form-label-right">
                    <div className="form-group row">
                    {/* S3 URL */}
                        <div className="col-lg-4">
                            <Field
                            name="s3URL"
                            component={Input}
                            placeholder="S3 URL"
                            label="S3"
                            />
                        </div>
                        {/* Date and time */}
                        <div className="col-lg-4">
                            {/* <Field
                                component={DateTimePicker}
                                name="dateTime"
                                label="Date Time"
                            /> */}
                            <DatePickerField 
                                name="forecastDateTime" 
                                label="Date Time"
                                id="forecastDateTime"
                            ></DatePickerField>
                        </div>
                        {/* Time interval in hours */}
                        <div className="col-lg-4">
                            <Field
                            type="number"
                            name="timeInterval"
                            component={Input}
                            label="Interval"
                            />
                        </div>
                    </div>
                </Form>
                </Modal.Body>
                <Modal.Footer>
                <button
                    type="button"
                    onClick={onHide}
                    className="btn btn-light btn-elevate"
                >
                    Cancel
                </button>
                <> </>
                <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    className="btn btn-primary btn-elevate"
                >
                    Save
                </button>
                </Modal.Footer>
            </>
            )}
        </Formik>
    </>
  );
}
