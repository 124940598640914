import * as requestFromServer from "./weatherCrud";
import {weatherSlice, callTypes} from "./weatherSlice";

const {actions} = weatherSlice;

export const fetchWeathers = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findWeathers(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.weathersFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find weathers";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchWeather = id => dispatch => {
  if (!id) {
    return dispatch(actions.weatherFetched({ weatherForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getWeatherById(id)
    .then(response => {
      const weather = response.data;
      dispatch(actions.weatherFetched({ weatherForEdit: weather }));
    })
    .catch(error => {
      error.clientMessage = "Can't find weather";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteWeather = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteWeather(id)
    .then(response => {
      dispatch(actions.weatherDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete weather";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createWeather = weatherForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createWeather(weatherForCreation)
    .then(response => {
      const { weather } = response.data;
      dispatch(actions.weatherCreated({ weather }));
    })
    .catch(error => {
      error.clientMessage = "Can't create weather";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateWeather = weather => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateWeather(weather)
    .then(() => {
      dispatch(actions.weatherUpdated({ weather }));
    })
    .catch(error => {
      error.clientMessage = "Can't update weather";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateWeathersStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForWeathers(ids, status)
    .then(() => {
      dispatch(actions.weathersStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update weathers status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteWeathers = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteWeathers(ids)
    .then(() => {
      dispatch(actions.weathersDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete weathers";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
