import * as React from "react";
import * as ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import HeatMap from "react-heatmap-grid"
// import { HeatMapComponent, Inject, Legend, Tooltip, Adaptor } from '@syncfusion/ej2-react-heatmap';
export function HeatMap1( props) {
  
const xLabels = ["Visibility","Pressure","Sunshine","Temperature","Sunheight","Power"]
const yLabels = ["Visibility","Pressure","Sunshine","Temperature","Sunheight","Power"]
const data = new Array(yLabels.length)
  .fill(0)
  .map(() =>
    new Array(xLabels.length)
      .fill(0)
      .map(() => Math.floor(Math.random() * (-7 + 5)))
  );
  
   
    if(props.data==null)
    return(<div>Processing...</div>)
    return ( <HeatMap data={props.data}
      xLabels={xLabels}
      yLabels={yLabels}
      yLabelWidth={90}
      xLabelWidth={100}
      xLabelsLocation={"bottom"} 
      sqaures
      height={80}
      cellStyle={(background, value, min, max, data, x, y) => ({
        background: `rgb(226, 124, 124, ${1 - (max - value) / (max - min)})`,
        fontSize: "11.5px",
        color: "#A0E7E5"
      })}/> );
}
