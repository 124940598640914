import React, { useEffect, useState, Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
//import {WeatherPage} from "./pages/WeatherPage";
// import {ForecastPage} from "./pages/ForecastPage";
import { CustomersPage } from "./modules/EForecast/pages/customers/CustomersPage";
import { ForecastsPage } from "./modules/EForecast/pages/forecasts/ForecastsPage";
import { WeathersPage } from "./modules/EForecast/pages/weathers/WeathersPage";
import PlantDetails from "./modules/EForecast/pages/plant-details/PlantDetails";
import ForecastDetails from "./modules/EForecast/pages/forecast-details/ForecastDetails";
import { PlantsPage } from "./modules/EForecast/pages/plants/PlantsPage";
import { EnergyForecast } from "./modules/EForecast/pages/forecasts/Energy/EnergyForecast";
import AdminPage from "./modules/EForecast/pages/admin/AdminPage";
import { DemoPage } from "./modules/EForecast/pages/Demo/Pages/Demo1Page";
import { getCurrentUser } from "../app/modules/Auth/_redux/authCrud";
import { Snackbar } from "@material-ui/core";

export default function BasePage() {
  const [userInformation, setUserInformation] = useState();

  useEffect(() => {
    getCurrentUser()
      .then((data) => {
        setUserInformation(data);
      })
      .catch((err) => console.error(err));
  }, []);

  const isAdmin = userInformation?.isAdmin === "true";

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {userInformation?.id === "72c61a94b86a4076a15f209754a0c643" && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className="alert alert-danger" role="alert">
            Your free trial period has ended. The services may stop soon. Please
            contact the NAECO Blue team for extension of your access
          </div>
        </Snackbar>
      )}

      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute
          path="/customers"
          component={isAdmin ? CustomersPage : DashboardPage}
        />
        <ContentRoute path="/weather" component={WeathersPage} />
        <ContentRoute path="/forecasts" component={ForecastsPage} />
        <ContentRoute path="/Energy" component={EnergyForecast} />
        <ContentRoute
          path="/admin"
          component={isAdmin ? AdminPage : DashboardPage}
        />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <ContentRoute path="/plants" component={PlantsPage} />
        <ContentRoute
          path="/plant-details"
          render={(props) => <PlantDetails {...props} />}
        />
        <ContentRoute
          path="/forecast-details"
          render={(props) => <ForecastDetails {...props} />}
        />
        <ContentRoute path="/demo" component={DemoPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}