import axios from "axios";

export const FORECASTS_URL = "api/weathers";

// CREATE =>  POST: add a new weather to the server
export function createWeather(weather) {
  return axios.post(FORECASTS_URL, { weather });
}

// READ
export function getAllWeathers() {

  return axios.get(FORECASTS_URL);
}

export function getWeatherById(weatherId) {
  return axios.get(`${FORECASTS_URL}/${weatherId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findWeathers(queryParams) {

  return axios.post(`${FORECASTS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the weather on the server
export function updateWeather(weather) {
  return axios.put(`${FORECASTS_URL}/${weather.id}`, { weather });
}

// UPDATE Status
export function updateStatusForWeathers(ids, status) {
  return axios.post(`${FORECASTS_URL}/updateStatusForWeathers`, {
    ids,
    status
  });
}

// DELETE => delete the weather from the server
export function deleteWeather(weatherId) {
  return axios.delete(`${FORECASTS_URL}/${weatherId}`);
}

// DELETE Weathers by ids
export function deleteWeathers(ids) {
  return axios.post(`${FORECASTS_URL}/deleteWeathers`, { ids });
}
