import React from "react";
import {
  ComposedChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { chartColors } from "../../../../../static/ChartColors";

function WindWeatherChart({ chartData }) {
  const header = chartData?.[0];
  console.log(header)
  const rows = chartData?.slice(1) || [];

  const timestampIndex = header?.indexOf("timestamp");
  const windGustSpeedIndex = header?.indexOf("wind_gust_speed");
  const windSpeedIndex = header?.indexOf("windspeed");
  const windDirectionIndex = header?.indexOf("winddirection");


  const result = rows.map((row) => ({
    timestamp: row[timestampIndex],
    windGust: row[windGustSpeedIndex],
    windSpeed: row[windSpeedIndex],
    windDirection: row[windDirectionIndex]
  }));


  const latestTimestamp = result
    .map((res) => res.timestamp)
    .sort((a, b) => new Date(b) - new Date(a))[0];

  const latestDate = new Date(latestTimestamp);
  latestDate.setHours(latestDate.getHours() - 48);

  const dataInRange = getTimestampsInRange(
    result,
    latestDate,
    new Date(latestTimestamp)
  );

  const maxGust = Math.max(...dataInRange.map((data) => data.windGust));
  const maxSpeed = Math.max(...dataInRange.map((data) => data.windSpeed));
  const maxDirection = Math.max(...dataInRange.map((data) => data.windDirection));


  function getTimestampsInRange(data, start, end) {
    return data.filter(
      (row) => new Date(row.timestamp) >= start && new Date(row.timestamp) <= end
    );
  }

  const customTickFormatter = (timestamp) => {
    const date = new Date(parseInt(timestamp));

    if (!timestamp || !date) {
      return "";
    }

    const formattedDate =
      `${date.getDate() < 10 ? "0" : ""}${date.getDate()}.` +
      `${date.getMonth() + 1 < 10 ? "0" : ""}${date.getMonth() + 1}.` +
      `${date.getFullYear()} ` +
      `${date.getHours() < 10 ? "0" : ""}${date.getHours()}:` +
      `${date.getMinutes() < 10 ? "0" : ""}${date.getMinutes()}`;

    return formattedDate;
  };

  if (!chartData) {
    return (<div> Loading </div>)
  }
  return (
    <div style={{ width: "100%" }}>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          width={780}
          height={300}
          data={dataInRange}
          margin={{ bottom: 20, left: 30, right: 40, top: 60 }}
        >
          <XAxis dataKey="timestamp" />
          <YAxis yAxisId="windGust" dataKey="windGust" domain={[0, maxGust]}>
            {/* Add a Label component to the YAxis */}
            <Label value="Wind Speed (m/s) /" offset={35} position="top" angle={0} paddingLeft={10} style={{ transform: 'translateX(10px)' }} />

            <Label value="Wind Gust (m/s) " offset={15} position="top" angle={0} paddingLeft={10} style={{ transform: 'translateX(7px)' }} />
          </YAxis>



          <YAxis yAxisId="windDirection" dataKey="windDirection" orientation="right" domain={[0, maxDirection]}>
            {/* Add a Label component to the YAxis */}
            <Label value="Wind Direction (°)" offset={15} position="top" angle={0} />
          </YAxis>
          <Tooltip />
          <Legend />
          <Bar
            yAxisId="windGust"
            dataKey="windGust"
            name="Wind Gust"
            fill={chartColors["cloudCover"]}
            barSize={5}
          />
          <Line
            yAxisId="windGust"
            type="monotone"
            name="Wind Speed"
            dataKey="windSpeed"
            stroke={chartColors["temperature"]}
          />
          <Line
            yAxisId="windDirection"
            type="monotone"
            name="Wind Direction"
            dataKey="windDirection"
            stroke={chartColors["humidity"]}
          />

        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

export default WindWeatherChart;
