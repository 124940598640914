import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { PlantActions } from "./plant-action/PlantActions";
import { PlantsTable } from "./plants-table/PlantsTable";
import { usePlantsUIContext } from "./PlantsUIContext";

export function PlantsCard() {
  const plantsUIContext = usePlantsUIContext();
  const plantsUIProps = useMemo(() => {
    return {
      ids: plantsUIContext.ids,
      setIds: plantsUIContext.setIds,
      queryParams: plantsUIContext.queryParams,
      setQueryParams: plantsUIContext.setQueryParams,
      openEditCustomerDialog: plantsUIContext.openEditCustomerDialog,
      openDeleteCustomerDialog: plantsUIContext.openDeleteCustomerDialog,
      newPlantButtonClick: plantsUIContext.newPlantButtonClick,
    };
  }, [plantsUIContext]);

  return (
    <Card>
      <CardHeader title="Plants list">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={plantsUIProps.newPlantButtonClick}
          >
            New Plant
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {plantsUIProps.ids.length > 0 && <PlantActions ids={plantsUIProps.ids} />}
        <PlantsTable />
      </CardBody>
    </Card>
  );
}
