import React, { Component } from "react";
import { Button, Modal } from 'react-bootstrap';
import PowerChart from "./chart";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { PlantMap } from "./PlantMap";
import { Col, Row } from "react-bootstrap";
import WeatherData from "./WeatherData";
import PlantsService from "../plants/PlantsService";
import { MonthlyChart } from "./MonthlyChart";
import { QuarterlyChart } from "./QuarterlyChart";

class PlantDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plantId: '',
            plant: null,
            chartData: null,
            plantData: null,
            showModal: false
        }
    }

    handleShowModal = () => {
        this.setState({ showModal: true });
    }

    handleCloseModal = () => {
        this.setState({ showModal: false });
    }

    async componentDidMount() {
        const searchParams = new URLSearchParams(this.props.location.search);
        const plantId = searchParams.get("id");
        this.setState({ plantId: plantId });

        let result = await PlantsService.getPlantById(plantId);
        const file = result.data.data.Item.energyDataFile;
        const plantData = result.data.data.Item

        let data = await PlantsService.getDataEnergyFile(file);

        this.setState({ plant: result.data.data.Item, chartData: data, plantData: plantData });
        console.log(this.state.plantData)
    }

    async handleFileUpload(event) {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = async () => {
            const content = reader.result;
            const lines = content.split('\n') ?? [];
            let headers = lines[0].split(',');
            headers = headers.map(header => header.trim());


            if (!headers.includes('timestamp') || !headers.includes('power[kW]')) {
                // invalid file, show error message to user
                alert('Invalid file. File must have "timestamp" and "power[kW]" columns.');
                return;
            }

            // check if each row has correct number of columns
            for (let i = 1; i < lines.length-1; i++) {
                const values = lines[i].split(",");
                if (values.length !== headers.length) {
                    alert(`Invalid row length in line ${i}. (Value: ${values})`);
                    return;
                }

                // check if timestamp column has valid date format
                const timestamp = new Date(values[headers.indexOf("timestamp")]);
                if (isNaN(timestamp.getTime())) {
                    alert(`No valid timestamp in line ${i}. (Value: ${values[headers.indexOf("timestamp")]})`);
                    return;
                }

                // check if power column has numeric values
                const power = Number(values[headers.indexOf("power[kW]")]);
                if (isNaN(power)) {
                    alert(`No valid power in line ${i} (Value: ${values[headers.indexOf("power[kW]")]}).`);
                    return;
                }
            }

            // valid file, set state to file contents
            this.state.saveUpdate(
                "energyDataFile", file,
            );
        };
        reader.readAsText(file);
    }
   



    render() {
        if (!this.state.chartData) {
            return (<div>Processing...</div>)
        }
        return (
            <div>
                {(this.state.plantData.plantType == 'solar')&&
                <div>
                    <Card>
                        <CardHeader title="Plant Details">

                                {/* <button
                                    type="button"
                                    className="btn btn-primary"
                                    style={{paddingTop: '10px', paddingBottom: '10px'}}
                                    onClick={this.handleShowModal}

                                   
                                >
                                    upload validation data
                                </button>
                                <Modal show={this.state.showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Upload File</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input type="file" onChange={this.handleFileUpload}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.handleCloseModal}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal> */}
                        </CardHeader>
                        <CardBody>
                            <CardBody>
                                <div><h1> {this.state.plantData.plantName}</h1> </div>
                                <div><h6>Plant Type: Solar</h6> </div>
                                
                                
                                <div><h6>Longitude: {this.state.plantData.longitude}°</h6> </div>

                                <div><h6>Latitude: {this.state.plantData.latitude}°</h6></div>
                                <div><h6>Altitude: {this.state.plantData.altitude}m</h6></div>

                                <div><h6>Maximum Capacity: {this.state.plantData.maximumCapacity}kW</h6></div>

                                <div><h6>Tilt: {this.state.plantData.tilt}°</h6></div>

                                <div><h6>Azimuth: {this.state.plantData.azimuth}° from North</h6></div>

                            </CardBody>
                        </CardBody>



                    </Card>
                </div>
    }


{(this.state.plantData.plantType == 'wind')&&
                <div>
                    <Card>
                        <CardHeader title="Plant Details">
                        </CardHeader>
                        <CardBody>
                            <CardBody>
                                <div className="my-3"><h1> {this.state.plantData.plantName}</h1> </div>
                                
                                <div><h6>Plant Type: Wind</h6> </div>
                                <div><h6>Turbine Model: {this.state.plantData.modelTurbine}</h6> </div>
                                <div><h6>Longitude: {this.state.plantData.longitude}°</h6> </div>

                                <div><h6>Latitude: {this.state.plantData.latitude}°</h6></div>
                                <div><h6>Altitude: {this.state.plantData.altitude}m</h6></div>

                                <div><h6>Maximum Capacity: {this.state.plantData.maximumCapacity}kW</h6></div>

                                

                            </CardBody>
                        </CardBody>



                    </Card>
                </div>
    }



                   <Card>
                    <CardBody>
                    <Row>
                    
                        <Col sm="6" >
                            <WeatherData location={{ 'latitude': this.state.plant.latitude, 'longitude': this.state.plant.longitude }}></WeatherData>
                        </Col>
                        <Col sm="6">
                            <PlantMap center={[this.state.plant.latitude, this.state.plant.longitude]}></PlantMap>
                        </Col>
                    </Row>
                    </CardBody>
                    </Card>
                    <PowerChart chartData={this.state.chartData}></PowerChart>
                    <MonthlyChart chartData={this.state.chartData} />
                    <QuarterlyChart chartData={this.state.chartData} />
               


            </div >
        );
    }
}


export default PlantDetails;