/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import WeatherChart from "./WeatherChart";
import PlantsService from "../../plants/PlantsService";


export function WeatherWidget({ className, plant, chartData }) {
    

//   const [chartData, setChartData] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const learning_basis_file = plant.energyDataFile;
//         console.log(learning_basis_file)
//         const data = await PlantsService.getDataEnergyFile(learning_basis_file);
//         setChartData(data);

//       } catch (error) {
//         console.error("Failed to fetch chart data:", error);
//       }
//     };

//     fetchData();
//   }, [plant]);

  return (
    <div className={`card card-custom bg-gray-100 ${className}`} >
      {/* Header */}
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark">Historical weather</h3>
      </div>

      {/* Body */}
      <div className="card-body p-1" style={{ height: "400px" }}>
        {/* Chart */}

        <div style={{ height: "100%" }}>
          {chartData && <WeatherChart chartData={chartData} />}
        </div>


        {/* Resize */}
        <div className="resize-triggers">
          <div className="expand-trigger">
            <div style={{ width: "411px", height: "461px" }} />
          </div>
          <div className="contract-trigger" />
        </div>
      </div>
    </div>
  );
}
