import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {ModalProgressBar} from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/forecasts/forecastActions";
import {useForecastsUIContext} from "../ForecastsUIContext";
import {
    MixedWidget1,
    MixedWidget14,
    StatsWidget11
} from "../../../../../../_metronic/_partials/widgets";

export function ForecastStatsDialog({ id, show, onHide }) {
  // Forecasts UI Context
  const forecastsUIContext = useForecastsUIContext();
  const forecastsUIProps = useMemo(() => {
    return {
      setIds: forecastsUIContext.setIds,
      queryParams: forecastsUIContext.queryParams
    };
  }, [forecastsUIContext]);

  // Forecasts Redux state
  const dispatch = useDispatch();
  const { isLoading, forecastForEdit } = useSelector(
    (state) => ({ 
      isLoading: state.forecasts.actionsLoading,
      forecastForEdit: state.forecasts.forecastForEdit 
    }),
    shallowEqual
  );
  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // server call for getting Forecast by id
    dispatch(actions.fetchForecast(id));
  }, [id, dispatch]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteForecast = () => {
    // server request for deleting forecast by id
    dispatch(actions.deleteForecast(id)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchForecasts(forecastsUIProps.queryParams));
      // clear selections list
      forecastsUIProps.setIds([]);
      // closing delete modal
      onHide();
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Statistical Analysis - {}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="row">
            <div className="col-lg-6 col-xxl-4">
              <MixedWidget1 className="card-stretch gutter-b"/>
            </div>
            <div className="col-lg-6 col-xxl-4">
              <MixedWidget14 className="card-stretch gutter-b"/>
            </div>
            <div className="col-lg-6 col-xxl-4">
              <StatsWidget11 className="card-stretch card-stretch-half gutter-b"/>
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          {/* <button
            type="button"
            onClick={deleteForecast}
            className="btn btn-primary btn-elevate"
          >
            Delete
          </button> */}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
