import React, { Component } from "react";
import { InputField } from "./InputField";

export class WindPlantOptionalForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            installationDate: "",
            gridExportLimit: "",
            numberOfTurbines: "",
            thrustCoefficientCurve: "",
            saveUpdate: this.props.saveUpdate,
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <>
                <div className="form-group row">
                    <div className="col-lg-6">
                        <InputField
                            type="number"
                            label="Thrust coefficient curve"
                            name="thrustCoefficientCurve"
                            placeholder="Thrust coefficient curve"
                            defaultValue={this.state.thrustCoefficientCurve}
                            icon="kW"
                            info="Add thrust coefficient curve (Optional)"
                            onChange={(event) => {
                                this.setState({
                                    "thrustCoefficientCurve": event.target.value,
                                });
                                this.state.saveUpdate(
                                    "thrustCoefficientCurve", event.target.value,
                                );
                            }}
                        />
                    </div>
                    <div className="col-lg-6">
                        <InputField
                            type="number"
                            label="Number of turbines"
                            name="numberOfTurbines"
                            placeholder="Number of Turbines"
                            defaultValue={this.state.numberOfTurbines}
                            icon=""
                            info="Add the number of turbines in wind plant (Optional)"
                            onChange={(event) => {
                                this.setState({
                                    "numberOfTurbines": event.target.value,
                                });
                                this.state.saveUpdate(
                                    "numberOfTurbines", event.target.value,
                                );
                            }}
                        />
                    </div>
                </div>
            </>
        );
    }
}