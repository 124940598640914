import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./PlantsUIHelpers";

const PlantsUIContext = createContext();

export function usePlantsUIContext() {
  return useContext(PlantsUIContext);
}

export const PlantsUIPlant = PlantsUIContext.Consumer;

export function PlantsUIProvider({ plantsUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [id, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids: id,
    setIds,
    setQueryParams,
    newPlantButtonClick: plantsUIEvents.newPlantButtonClick,
    openEditPlantDialog: plantsUIEvents.openEditPlantDialog,
    openForecastModal: plantsUIEvents.openForecastModal,
    openDeletePlantDialog: plantsUIEvents.openDeletePlantDialog,
    openDeletePlantsDialog: plantsUIEvents.openDeletePlantsDialog,
    openFetchPlantsDialog: plantsUIEvents.openFetchPlantsDialog,
    openUpdatePlantsStatusDialog:
      plantsUIEvents.openUpdatePlantsStatusDialog,
  };

  return (
    <PlantsUIContext.Provider value={value}>
      {children}
    </PlantsUIContext.Provider>
  );
}
