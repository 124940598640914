import React, { Component } from 'react';

export class SelectPlantType extends Component {


    constructor(props) {
        super(props);
    }

    selectPlant(value) {
        this.props.selectPlantType(value);
    }

    render() {
        return (
            <div className="row">
                <div className="col-sm-6">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">PV Plant</h5>
                            <p className="card-text">Choose us for unrivaled PV plant forecasts. Our advanced algorithms leverage comprehensive data to predict energy outputs accurately. Make informed decisions, optimize operations, and maximize efficiency with our reliable forecasts, ensuring your PV plant's success in every light and weather condition. </p>
                            <button onClick={() => this.selectPlant("solar")} className="btn btn-primary">Create PV plant</button>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Wind Plant</h5>
                            <p className="card-text">Empower your wind energy venture with our precision wind plant forecasts. Harnessing cutting-edge technology and extensive data analysis, we provide actionable insights for optimal turbine performance. Trust our expertise to navigate changing winds and enhance your wind plant's productivity and profitability. </p>
                            <button onClick={() => this.selectPlant("wind")} className="btn btn-primary">Create wind plant</button>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}