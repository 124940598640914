import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./WeathersUIHelpers";

const WeathersUIContext = createContext();

export function useWeathersUIContext() {
  return useContext(WeathersUIContext);
}

export const WeathersUIConsumer = WeathersUIContext.Consumer;

export function WeathersUIProvider({weathersUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initWeather = {
    id: undefined,
    weatherName: "",
    focalPersonName: "",
    focalPersonEmail: "",
    focalPersonContact: "",
    numberOfPlants: 0,
    plantType: 0,
    plantDetails: []
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initWeather,
    newWeatherButtonClick: weathersUIEvents.newWeatherButtonClick,
    openSendWeatherDialog: weathersUIEvents.openSendWeatherDialog,
    openLogsDialog: weathersUIEvents.openLogsDialog,
    openStatsDialog: weathersUIEvents.openStatsDialog,
    openValidateWeatherDialog: weathersUIEvents.openValidateWeatherDialog,
    // openEditWeatherDialog: weathersUIEvents.openEditWeatherDialog,
    // openDeleteWeatherDialog: weathersUIEvents.openDeleteWeatherDialog,
    openDeleteWeathersDialog: weathersUIEvents.openDeleteWeathersDialog,
    openFetchWeathersDialog: weathersUIEvents.openFetchWeathersDialog,
    openUpdateWeathersStatusDialog: weathersUIEvents.openUpdateWeathersStatusDialog
  };

  return <WeathersUIContext.Provider value={value}>{children}</WeathersUIContext.Provider>;
}