/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { getCurrentUser } from "../../../../../app/modules/Auth/_redux/authCrud";

export function AsideMenuList({ layoutProps }) {
  const [userInformation, setUserInformation] = useState();

  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? " menu-item-active menu-item-open "
      : "";
  };

  useEffect(() => {
    getCurrentUser()
      .then((data) => {
        setUserInformation(data);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}

        {userInformation?.['isAdmin'] && <li 
          className={`menu-item ${getMenuItemActive("/customers")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/customers">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Customers</span>
          </NavLink>
        </li>}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/plants")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/plants">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Plants</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/forecasts")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/forecasts">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Forecast</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/Energy")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/Energy">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Energy Trading</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {userInformation?.['isAdmin'] && <li
          className={`menu-item ${getMenuItemActive("/admin")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admin">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">Admin</span>
          </NavLink>
        </li>}
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
