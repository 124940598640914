import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
// import { ForecastsFilter } from "./forecasts-filter/ForecastsFilter";
import { ForecastsTable } from "./forecasts-table/ForecastsTable";
// import { ForecastsGrouping } from "./forecasts-grouping/ForecastsGrouping";
import { useForecastsUIContext } from "./ForecastsUIContext";
// import PowerChart from "C:/Users/jibra/naeco/adminpanel/frontend/src/app/modules/EForecast/pages/plant-details/chart.js";

export function ForecastsCard() {
  const forecastsUIContext = useForecastsUIContext();
  const forecastsUIProps = useMemo(() => {
    return {
      ids: forecastsUIContext.ids,
      newForecastButtonClick: forecastsUIContext.newForecastButtonClick,
    };
  }, [forecastsUIContext]);

  return (
    <Card>
      <CardHeader title="Forecasts list">
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ForecastsTable />
      </CardBody>

    </Card>
  );
}
