import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { chartColors } from "../../../app/static/ChartColors";

function DemoChart({ chartData }) {
  const header = chartData?.[0];
  const rows = chartData?.slice(1) || [];

  const timestampIndex = header?.indexOf("timestamp");
  const millisecondsIndex = header?.indexOf("milliseconds");
  const powerIndex = header?.indexOf("power[kW]");

  const result = rows.map((row) => ({
    timestamp: row[timestampIndex],
    milliseconds: row[millisecondsIndex],
    power: row[powerIndex],
  }));

  const latestTimestamp = result
    .map((res) => res.timestamp)
    .sort((a, b) => new Date(b) - new Date(a))[0];

  const latestDate = new Date(latestTimestamp);
  latestDate.setHours(latestDate.getHours() - 48);

  const dataInRange = getTimestampsInRange(result, latestDate, new Date(latestTimestamp));



  function getTimestampsInRange(data, start, end) {
    return data.filter(
      (row) => new Date(row.timestamp) >= start && new Date(row.timestamp) <= end
    );
  }
  const formatYAxisTick = (value) => Math.ceil(value); // Round down to the nearest whole number

  const customTickFormatter = (timestamp) => {
    const date = new Date(parseInt(timestamp * 1));

    if (!timestamp || !date) {
      return '';
    }

    const formattedDate = `${(date.getDate() < 10 ? '0' : '')}${date.getDate()}.` +
      `${(date.getMonth() + 1 < 10 ? '0' : '')}${date.getMonth() + 1}.` +
      `${date.getFullYear()} ` +
      `${(date.getHours() < 10 ? '0' : '')}${date.getHours()}:` +
      `${(date.getMinutes() < 10 ? '0' : '')}${date.getMinutes()}`;

    return formattedDate;
  };

  const maxValuePower = Math.max(...dataInRange.map((val) => {
    const parsedValue = parseFloat(val.power);
    return !isNaN(parsedValue) ? parsedValue : 0;
  }));

  const minValuePower = Math.min(...dataInRange.map((val) => {
    const parsedValue = parseFloat(val.power);
    return !isNaN(parsedValue) ? parsedValue : 0;
  }));


  return (
    <div style={{ width: '100%' }}>
      <ResponsiveContainer width="100%" height={300}>
        <AreaChart
          width={580}
          height={300}
          data={dataInRange}
          margin={{ bottom: 40, left: 40, right: 40, top: 40 }}
        >
          {/* <Tooltip
            labelFormatter={value => {
              return `${customTickFormatter(value)}`;
            }}
          /> */}
          <XAxis dataKey="timestamp"/>
          <YAxis yAxisId="power" dataKey="power" type="number" domain={[minValuePower < 0 ? minValuePower : 0, maxValuePower]} tickFormatter={formatYAxisTick} >
            {/* Add a Label component to the YAxis */}
            <Label value="Power (kW)" offset={15} position="top" angle={0} />
          </YAxis>
          <Tooltip />
          <Legend />
          <Area yAxisId="power" dataKey="power" name="Power" stroke={chartColors['powerGenerated']} fill={chartColors['powerGenerated']} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default DemoChart;
