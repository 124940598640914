import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from 'react-router-dom';

export function PlantActions({ ids }) {

  const history = useHistory();

  function showDetails() {
    history.push(`/plant-details?id=${ids[0]}`);
  }

  function deletePlant() {
    history.push(`/plants/${ids[0]}/delete`)
  }

  


  return (
    <>
      <div className="form-group row justify-content-end">
        <div style={{ paddingRight: 20, }}>
          <Button onClick={() => showDetails()}>View Details</Button>
        </div>
        <div>
          <Button variant="danger" onClick={() => deletePlant()}>Delete</Button>
        </div>
      </div>
    </>
  );
}
