import * as React from "react";
import * as ReactDOM from 'react-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import HeatMap from "react-heatmap-grid"

export function WindCorrelation( props) {
  
const xLabels = ["Power", "Temperature","Wind speed","Wind direction","Dew point"]
const yLabels = ["Power", "Temperature","Wind speed","Wind direction","Dew point"]
const data = new Array(yLabels.length)
  .fill(0)
  .map(() =>
    new Array(xLabels.length)
      .fill(0)
      .map(() => Math.floor(Math.random() * (-7 + 5)))
  );
  
   
    if(props.data==null)
    return(<div>Processing...</div>)
    return ( <HeatMap data={props.data}
      xLabels={xLabels}
      yLabels={yLabels}
      yLabelWidth={120}
      xLabelWidth={100}
      xLabelsLocation={"bottom"} 
      sqaures
      height={80}
      cellStyle={(background, value, min, max, data, x, y) => ({
        background: `rgb(226, 124, 124, ${1 - (max - value) / (max - min)})`,
        fontSize: "11.5px",
        color: "#A0E7E5"
      })}/> );
}
