/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { getCurrentUser } from "../../../../../app/modules/Auth/_redux/authCrud";
import { toAbsoluteUrl } from "../../../../_helpers";
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import axios from 'axios';

export function QuickUser() {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false); 

  const [email, setEmail] = useState(''); 
  const [password, setPassword] = useState(''); 
  const [confirmPassword, setConfirmPassword] = useState(''); 
  const [isLoading, setIsLoading] = useState(false);

  const apiURL = `${process.env.REACT_APP_URL}/api/changePassword`;
  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    localStorage.setItem('currentUser', null);
    history.push("/logout");
  };

  const ChangePasswordClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false); // Step 4
  };

  const handleSaveChanges = async () => {
    if (password !== confirmPassword) { // Step 1
      alert('Password and confirm password do not match.'); // Step 2
      return;
    }
    console.log(email, password, confirmPassword);  
    setIsLoading(true);
    
    try {
      const token = localStorage.getItem('token');
      console.log(token);
      let response = await axios({
        method: 'POST',
        url: apiURL,
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: { email, password }
      }).catch((error) => {
        return error.response;
      });
      console.log(response);
      const result = response?.data;
      result?.access_token && localStorage.setItem('token', result?.access_token);
      alert(result?.message);
      handleClose();
    }
    catch (e) {
      return [];
    }
    finally {
      setIsLoading(false); // Set loading to false when response is received
    }
  };

  const [userInformation, setUserInformation] = useState();

  useEffect(() => {
    getCurrentUser()
      .then((data) => {
        setUserInformation(data);
      })
      .catch((err) => console.error(err));
  }, []);
  const isAdmin = userInformation?.isAdmin === "true";


  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          User Profile
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div
        className="offcanvas-content pr-5 mr-n5"
      >
        <div className="d-flex align-items-center mt-5">
          <div
            className="symbol symbol-100 mr-5"
          >
          </div>
          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {userInformation?.['name']}
            </a>
            <div className="text-muted mt-1">
            {userInformation?.['isAdmin'] ? "Administrator" : "Customer"}
            </div>
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail-notification.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {userInformation?.['email']}
                  </span>
                </span>
              </a>
            </div>
            <button className="btn btn-light-primary btn-bold" onClick={logoutClick}>Sign out</button>
            {userInformation?.isAdmin ? (
                <button className="btn btn-light-primary btn-bold" onClick={ChangePasswordClick}>Change password</button>
            ) : null}
             <Modal show={isLoading} backdrop="static" keyboard={false}>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        </Modal.Body>
      </Modal>

            <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password - Admin Only</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address of User</Form.Label>
                <Form.Control type="email" placeholder="Enter user email" onChange={e => setEmail(e.target.value)} />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>New Password</Form.Label>
                <Form.Control type="password" placeholder="New Password" onChange={e => setPassword(e.target.value)} />
              </Form.Group>

              <Form.Group controlId="formBasicConfirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control type="password" placeholder="Confirm Password" onChange={e => setConfirmPassword(e.target.value)} />
              </Form.Group>
            </Form>
          </Modal.Body>
        </Modal.Body>
        
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
          </div>
        </div>
        <div className="separator separator-dashed mt-8 mb-5" />
      </div>
    </div>
  );
}
