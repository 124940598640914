import React, { useEffect, useState } from 'react';

function PlantDropdown({ allPlants, selectedPlant, setSelectedPlant }) {
    const [plantOptions, setPlantOptions] = useState(['Please select']);
    const [selectedOption, setSelectedOption] = useState('Please select');

    useEffect(() => {
        if (allPlants == null) {
            return;
        }
        setPlantOptions([...allPlants]);
        setSelectedOption(JSON.stringify(selectedPlant)); // Convert selectedPlant to string
    }, [allPlants, selectedPlant]);

    const handlePlantChange = (event) => {
        const selectedPlantObject = JSON.parse(event.target.value);
        setSelectedPlant(selectedPlantObject);
        setSelectedOption(event.target.value); // Update selectedOption as a string
    };

    return (
        <div style={{ position: "relative", display: "inlineBlock", paddingBottom: 20 }}>
            {selectedPlant && <h2 style={{ backgroundColor: "#ffffff", padding: 10, border: "1px solid #cccccc", borderRadius: 4, margin: 0 }}>{selectedPlant.plantName}</h2>}
            <select value={selectedOption} onChange={handlePlantChange} style={{ position: "absolute", top: 0, left: 0, opacity: 0, width: "100%", height: "100%", cursor: "pointer", zIndex: 1 }}>
                {[...plantOptions?.map((plant) => (
                    <option key={plant.plantName} value={JSON.stringify(plant)}>
                        {plant.plantName}
                    </option>
                ))]}
            </select>
        </div>
    );
}

export default PlantDropdown;
