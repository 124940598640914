/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { getCurrentUser } from "../../../../Auth/_redux/authCrud";

export function PlantInformation({ className, plant }) {


  const [userInformation, setUserInformation] = useState();

  useEffect(() => {
    getCurrentUser()
      .then((data) => {
        setUserInformation(data);
      })
      .catch((err) => console.error(err));
  }, []);


  const capitalizeFirstLetter = (word) => {
    // Check if the word is not empty
    if (word.length === 0) {
      return word;
    }

    // Capitalize the first letter and concatenate the rest of the string
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header border-0">
          <h2 style={{ paddingTop: 20 }}>
            Welcome {userInformation?.['name']}, you are looking at forecast details for {plant.plantName}
          </h2>
        </div>
          <h3 style={{ paddingLeft: 30 }}>
            Plant information:
          </h3>
        <div className="card-body">
           <table>
            <tbody>
              <tr key="type">
                <td style={{ fontWeight: 800, }}>Type</td>
                <td style={{ paddingLeft: 10, }}>{capitalizeFirstLetter(plant?.plantType) ?? 'Solar'}</td>

                {/* <td style={{ paddingLeft: 10, }}>{plant?.plantType ?? 'Solar'}</td> */}
              </tr>
              <tr key="location">
                <td style={{ fontWeight: 800, }}>Location</td>
                <td style={{ paddingLeft: 10, }}>({plant?.latitude ?? 82.123}, {plant?.longitude ?? 11.331})</td>
              </tr>
              {plant?.tilt != null && <tr key="tilt">
                <td style={{ fontWeight: 800, }}>Tilt</td>
                <td style={{ paddingLeft: 10, }}>{plant?.tilt ?? 15} °</td>
              </tr>}
              {plant?.azimuth != null && <tr key="azimuth">
                <td style={{ fontWeight: 800, }}>Azimuth</td>
                <td style={{ paddingLeft: 10, }}>{plant?.azimuth ?? 15} °</td>
              </tr>}
              {plant?.modelTurbine != null && <tr key="modelTurbine">
                <td style={{ fontWeight: 800, }}>Turbine model</td>
                <td style={{ paddingLeft: 10, }}>{plant?.modelTurbine} </td>
              </tr>}
              <tr key="maximumCapacity">
                <td style={{ fontWeight: 800, }}>Maximum Capacity</td>
                <td style={{ paddingLeft: 10, }}>{plant?.maximumCapacity ?? 100000} kW</td>
              </tr>
              <tr key="altitude">
                <td style={{ fontWeight: 800, }}>Altitude</td>
                <td style={{ paddingLeft: 10, }}>{plant?.altitude ?? 20} m</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Body */}

      </div>
    </>
  );
}
