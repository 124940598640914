import React, { Component } from 'react';
import { InputField } from '../Components/DemoInputFeild';
import { Button } from 'react-bootstrap';
import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers';
import { Map } from "./Map";

export class DemoWindRequirementForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            capacities: [{
                maximumCapacity: null,
            
            }],
            longitude: null,
            latitude: null,
            openMap: false,
            formErrors: this.props.formErrors,
            saveUpdate: this.props.saveUpdate,
            isLoaded: false
        };
    }

    handleCapacityChange = (index, value, field) => {
        const updatedCapacities = this.state.capacities.map((item, idx) => {
            if (idx === index) {
                return { ...item, [field]: value };
            }
            return item;
        });

        this.setState({ capacities: updatedCapacities });
        this.state.saveUpdate("capacities", updatedCapacities);
    };

    addCapacityField = () => {
        this.setState(prevState => ({
            capacities: [...prevState.capacities, { maximumCapacity: null, capacityUnit: null }]
        }));
    };

    toggleMap = (open) => {
        this.setState({ openMap: open });
    };

    handleMapSelect = (center) => {
        this.setState({
            longitude: center.lng,
            latitude: center.lat,
            openMap: false
        });
        this.state.saveUpdate("longitude", center.lng);
        this.state.saveUpdate("latitude", center.lat);
    };

    renderCapacityFields() {
        
        return this.state.capacities.map((capacity, index) => (
           
            <div key={index} className="form-group row">
                <div className="col-lg-4">
                    <InputField
                        type="number"
                        label="Maximum Capacity"
                        name={`maximumCapacity-${index}`}
                        placeholder="Maximum Capacity"
                        defaultValue={capacity.maximumCapacity}
                        icon="W"
                        icon2="kW"
                        iconValue={capacity.capacityUnit}
                        info="Maximum Capacity of Plant. Must be greater than 3000W"
                        onChange={(event) => this.handleCapacityChange(index, event.target.value, 'maximumCapacity')}
                        onIconChange={(event) => this.handleCapacityChange(index, event.target.value, 'capacityUnit')}
                        required
                    />
                </div>
                
            </div>
        ));
    }

    render() {
        return (
            <>
                {!this.state.openMap &&
                    <div>
                        {this.renderCapacityFields()}
                        <div className="form-group row">
                            <div className="col-lg-12">
                                <Button onClick={() => this.addCapacityField()} style={{ marginTop: '10px' }}>
                                    + Add Another Plant
                                </Button>
                            </div>
                        </div>

                        <div className="form-group row">
                            <InputField
                                type="number"
                                label="Latitude"
                                name="latitude"
                                placeholder="Latitude"
                                value={this.state.latitude}
                                defaultValue={this.state.latitude}
                                onChange={(event) => {
                                    this.state.saveUpdate("latitude", event.target.value);
                                    this.setState({ latitude: event.target.value })
                                }}
                                required
                            />
                            <InputField
                                type="number"
                                label="Longitude"
                                name="longitude"
                                placeholder="Longitude"
                                value={this.state.longitude}
                                defaultValue={this.state.longitude}
                                onChange={(event) => {
                                    this.state.saveUpdate("longitude", event.target.value);
                                    this.setState({ longitude: event.target.value })
                                }}
                                required
                            />
                        </div>

                        <div className="form-group row">
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>---OR---</div>
                        </div>
                        <div className="form-group row">
                            <p style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <b>Select location (Longitude, Latitude) from Map</b>
                            </p>
                        </div>
                        <div className="form-group row" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                            <img alt="Map" height={150} width={200} src={toAbsoluteUrl("/media/svg/social-icons/maps.jpg")} onClick={() => this.toggleMap(true)} style={{ cursor: 'pointer' }} />
                        </div>
                    </div>
                }

                {this.state.openMap &&
                    <Map onSelect={this.handleMapSelect} />
                }
            </>
        );
    }
}
