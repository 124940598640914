import React from 'react'
import { Chart } from "react-google-charts";

export function PieChart() {
   const data = [
        ["Task", "Public net electricity generation in Germany"],
        ["Wind on Shore", 649.5],
        ["Wind off Shore", 204.54],
        ["Biomass", 205.64],
        ["Solar", 21.50], 
        ["Fossil brown coal", 487.6],
        ["Fossil oil", 31.46],
        ["Fossil hard coal", 350.68],
        ["Fossil gas", 662.11],
        ["Others", 54.19],
        ["Waste non-renewable", 21.18],
        
      ];
      
       const options = {
        
        pieHole: 0.3,
        is3D: false,
        colors: ['#13345E', '#7dc4a6', '#7faf6a', '#ffa600', '#cea21b', '#c3cb71', '#1b85b8','#ae5a41','#90a1d0','#ec6064']
      };
      
  return (
    <div  style={{  borderRadius: '10px', width:'100%', height:'30%', paddingTop:'50px', background:"white"}}>
      <div className='text-center'><h4>Public net electricity generation in Germany</h4></div>
        <Chart
      chartType="PieChart"
      width="100%"
      height="500px"
      data={data}
      options={options}
    />
    </div>
  )
}
