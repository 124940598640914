import axios from "axios";

export const PLANTS_URL = `${process.env.REACT_APP_URL}/api/plants`;

// CREATE =>  POST: add a new customer to the server
export function createPlant(plant) {
  return axios.post(PLANTS_URL, plant);
}

// READ
export function getAllPlants() {
  return axios.get(PLANTS_URL);
}

export function getPlantById(plantId) {
  return axios.get(`${PLANTS_URL}/${plantId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findPlants(queryParams) {
  const token = localStorage.getItem("token");
  var response = axios.get(`${PLANTS_URL}`, {
    queryParams, headers: {
      Authorization: `Bearer ${token}`,
    }
  });
  return response;
}

// UPDATE => PUT: update the customer on the server
export function updatePlant(plant) {
  return axios.put(`${PLANTS_URL}/${plant.id}`, plant);
}

// UPDATE Status
export function updateStatusForPlants(ids, status) {
  return axios.post(`${PLANTS_URL}/updateStatusForPlants`, {
    ids,
    status,
  });
}

// DELETE => delete the customer from the server
export function deletePlant(plantId) {
  return axios.delete(`${PLANTS_URL}/${plantId}`);
}

// DELETE Customers by ids
export function deletePlants(ids) {
  return axios.post(`${PLANTS_URL}/deletePlants`, { ids });
}
