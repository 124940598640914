import React from "react";
import {
    MixedWidget14,
    AdvanceTablesWidget2,
    AdvanceTablesWidget4,
    ListsWidget3,
    ListsWidget4,
    ListsWidget8
} from "../widgets";
import {
    Button,
    ButtonToolbar
} from "react-bootstrap";
export function Forecast() {
    return (<>
        <div className="row">




            <div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">
                <ButtonToolbar>
                    <Button variant="primary">Primary</Button>
                    <Button variant="secondary">Secondary</Button>
                    <Button variant="success">Success</Button>
                    <Button variant="warning">Warning</Button>
                    <Button variant="danger">Danger</Button>
                    <Button variant="info">Info</Button>
                    <Button variant="light">Light</Button>
                    <Button variant="dark">Dark</Button>
                    <Button variant="link">Link</Button>
                </ButtonToolbar>
            </div>
            <div className="col-xxl-8 order-2 order-xxl-1">
                <AdvanceTablesWidget2 className="card-stretch gutter-b" />
            </div>
            <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                <ListsWidget3 className="card-stretch gutter-b" />
            </div>
            <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
                <ListsWidget4 className="card-stretch gutter-b" />
            </div>
            <div className="col-lg-12 col-xxl-4 order-1 order-xxl-2">
                <ListsWidget8 className="card-stretch gutter-b" />
            </div>
        </div>
        <div className="row">
            <div className="col-lg-4">
                <MixedWidget14 className="card-stretch gutter-b" />
            </div>
            <div className="col-lg-8">
                <AdvanceTablesWidget4 className="card-stretch gutter-b" />
            </div>
        </div>
    </>);
}
