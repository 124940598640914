import React, { Component } from "react";
import { BsInfoCircleFill } from 'react-icons/bs'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
export class InputField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            label: props.label,
            type: props.type ?? 'text',
            placeholder: props.placeholder,
            onChange: props.onChange,
            icon: props.icon,
            error: props.error,
            info: props.info,
            
        };
    }


    render() {
        return (
            <div className={"form-group " + (this.props.required ? 'required' : '')}>
                <label className="control-label" htmlFor="plantName">{this.state.label}</label>
                <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                        <Tooltip {...props}>
                            {this.state.info}
                        </Tooltip>
                    )}
                    placement="top"
                >
                    <BsInfoCircleFill className="mx-5" />
                </OverlayTrigger>
                <div className="input-group mb-3">
                    <input
                        type={this.props.type}
                        name={this.props.name}
                        className="form-control"
                        placeholder={this.props.placeholder}
                        value={this.props.defaultValue}
                        onChange={this.props.onChange}
                        required
                    />
                    {
                        this.props.icon != null &&
                        <div className="input-group-append">
                            <span className="input-group-text">{this.props.icon}</span>
                        </div>
                    }
                </div>
                <small>{this.state.error}</small>
            </div>
        );
    }


}