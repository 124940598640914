import React, { Component } from "react";
import { InputField } from "./InputField";
import { Accordion, Button, Card } from "react-bootstrap";
import { SolarPlantOptionalForm } from "./SolarPlantOptionalForm";
import { BsInfoCircleFill } from 'react-icons/bs'
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

export class SolarPlantMandatoryForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            plantName: "",
            timeZone: "",
            longitude: "",
            latitude: "",
            altitude: "",
            tilt: "",
            azimuth: "",
            maximumCapacity: "",
            energyDataFile: undefined,
            saveUpdate: this.props.saveUpdate,
            formErrors: this.props.formErrors,
        };
        this.fileChangeHandler = this.fileChangeHandler.bind(this);
        console.log(this.state);

    }

    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    async fileChangeHandler(event) {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = async () => {
            const content = reader.result;
            const lines = content.split('\n') ?? [];
            let headers = lines[0].split(',');
            headers = headers.map(header => header.trim());


            if (!headers.includes('timestamp') || !headers.includes('power[kW]')) {
                // invalid file, show error message to user
                alert('Invalid file. File must have "timestamp" and "power" columns.');
                return;
            }

            // check if each row has correct number of columns
            for (let i = 1; i < lines.length - 1; i++) {
                const values = lines[i].split(",");
                if (values.length !== headers.length) {
                    alert(`Invalid row length in line ${i}. (Value: ${values})`);
                    return;
                }

                // check if timestamp column has valid date format
                const timestamp = new Date(values[headers.indexOf("timestamp")]);
                if (isNaN(timestamp.getTime())) {
                    alert(`No valid timestamp in line ${i}. (Value: ${values[headers.indexOf("timestamp")]})`);
                    return;
                }

                // check if power column has numeric values
                const power = Number(values[headers.indexOf("power[kW]")]);
                if (isNaN(power)) {
                    alert(`No valid power in line ${i} (Value: ${values[headers.indexOf("power[kW]")]}).`);
                    return;
                }
            }

            // valid file, set state to file contents
            this.state.saveUpdate(
                "energyDataFile", file,
            );
        };
        reader.readAsText(file);
    }

    componentDidMount() {
    }

    render() {
        return (
            <>
                <div className="form-group row">
                    <div className="col-lg-12">
                        <InputField
                            label="Plant name (MaSTR-number)"
                            name="plantName"
                            info="Add plant name"
                            placeholder="Plant name"
                            defaultValue={this.state.plantName}
                            value={this.state.plantName}
                            onChange={(event) => {  
                                this.setState({
                                    "plantName": event.target.value,
                                
                                })
                                this.state.saveUpdate(
                                    "plantName", event.target.value,
                                );
                            }}
                            required
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-lg-6">
                        <InputField
                            type="number"
                            label="Latitude"
                            name="latitude"
                            placeholder="Latitude"
                            defaultValue={this.state.latitude}
                            value={this.state.latitude}
                            info="Latitude of the location of Plant"
                            icon="°"
                            onChange={(event) => {
                                this.setState({
                                    "latitude": event.target.value,
                                })
                                this.state.saveUpdate(
                                    "latitude", event.target.value,
                                );
                            }}
                            required
                        />
                    </div>
                    <div className="col-lg-6">
                        <InputField
                            type="number"
                            label="Longitude"
                            name="longitude"
                            placeholder="Longitude"
                            info="Add Longitude of the location of Plant"
                            defaultValue={this.state.longitude}
                            value={this.state.longitude}
                            icon="°"
                            onChange={(event) => {
                                this.setState({
                                    "longitude": event.target.value,
                                })
                                this.state.saveUpdate(
                                    "longitude", event.target.value,
                                );
                            }}
                            required
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-lg-6">
                        <label htmlFor="timeZone">Time zone</label>
                        <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                                <Tooltip {...props}>
                                    Select Your TimeZone
                                </Tooltip>
                            )}
                            placement="top"
                        >
                            <BsInfoCircleFill className="mx-5" />
                        </OverlayTrigger>
                        <div className="input-group mb-3">
                            <select className="custom-select"
                                id="timeZone"
                                value={this.state.timeZone}
                                onChange={
                                    (event) => {
                                        this.setState({
                                            "timeZone": event.target.value,
                                        });
                                        this.state.saveUpdate(
                                            "timeZone", event.target.value,
                                        );
                                    }}>
                                <option value="1">--Select Timezone--</option>
                                <option value="-12:00">-12:00 (Baker Island Time)</option>
                                <option value="-11:00">-11:00 (American Samoa Standard Time)</option>
                                <option value="-10:00">-10:00 (Hawaii-Aleutian Standard Time)</option>
                                <option value="-09:30">-09:30 (Marquesas Islands Time)</option>
                                <option value="-09:00">-09:00 (Alaska Standard Time)</option>
                                <option value="-08:00">-08:00 (Pacific Standard Time (North America))</option>
                                <option value="-07:00">-07:00 (Mountain Standard Time (North America))</option>
                                <option value="-06:00">-06:00 (Central Standard Time (North America))</option>
                                <option value="-05:00">-05:00 (Eastern Standard Time (North America))</option>
                                <option value="-04:30">-04:30 (Venezuelan Standard Time)</option>
                                <option value="-04:00">-04:00 (Atlantic Standard Time)</option>
                                <option value="-03:30">-03:30 (Newfoundland Standard Time)</option>
                                <option value="-03:00">-03:00 (Amazon Standard Time, Central Greenland Time)</option>
                                <option value="-02:00">-02:00 (Fernando de Noronha Time, South Georgia & the South Sandwich Islands Time)</option>
                                <option value="-01:00">-01:00 (Azores Standard Time, Cape Verde Time)</option>
                                <option value="+00:00">+00:00 (Western European Time, Greenwich Mean Time)</option>
                                <option value="+01:00">+01:00 (Central European Time, West African Time)</option>
                                <option value="+02:00">+02:00 (Eastern European Time, Central African Time)</option>
                                <option value="+03:00">+03:00 (Moscow Standard Time, Eastern African Time)</option>
                                <option value="+03:30">+03:30 (Iran Standard Time)</option>
                                <option value="+04:00">+04:00 (Gulf Standard Time, Samara Standard Time)</option>
                                <option value="+04:30">+04:30 (Afghanistan Time)</option>
                                <option value="+05:00">+05:00 (Pakistan Standard Time, Yekaterinburg Standard Time)</option>
                                <option value="+05:30">+05:30 (Indian Standard Time, Sri Lanka Time)</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <InputField
                            type="number"
                            label="Altitude"
                            name="altitude"
                            placeholder="Altitude"
                            info="Add Altitude of the location of the plant"
                            defaultValue={this.state.altitude}
                            value={this.state.altitude}
                            icon="m"
                            onChange={(event) => {
                                this.setState({
                                    "altitude": event.target.value,
                                })

                                this.state.saveUpdate(
                                    "altitude", event.target.value,
                                );
                            }}
                            required
                        />
                    </div>
                </div>
                <div className="form-group row">

                    <div className="col-lg-6">
                        <InputField
                            type="number"
                            label="Tilt"
                            name="tilt"
                            placeholder="Tilt"
                            info="Add tilt of the panel"
                            defaultValue={this.state.tilt}
                            value={this.state.tilt}
                            icon="°"
                            onChange={(event) => {
                                this.setState({
                                    "tilt": event.target.value,
                                })
                                this.state.saveUpdate(
                                    "tilt", event.target.value,
                                );
                            }}
                            required
                        />
                    </div>
                    <div className="col-lg-6">
                        <InputField
                            type="number"
                            label="Azimuth"
                            name="azimuth"
                            placeholder="Azimuth"
                            info="Add Azimuth of the plant Range ( 0 , 360 )"
                            defaultValue={this.state.azimuth}
                            value={this.state.azimuth}
                            icon="° from North"
                            onChange={(event) => {
                                this.setState({
                                    "azimuth": event.target.value,
                                })
                                this.state.saveUpdate(
                                    "azimuth", event.target.value,
                                );
                            }}
                            required
                        />
                    </div>
                </div>
                <div className="form-group row">

                    <div className="col-lg-6">
                        <InputField
                            type="number"
                            label="Maximum Capacity"
                            name="maximumCapacity"
                            placeholder="Maximum Capacity"
                            defaultValue={this.state.maximumCapacity}
                            value={this.state.maximumCapacity}
                            icon="kW"
                            info="Add Maximum capacity of Plant"
                            onChange={(event) => {
                                this.setState({
                                    "maximumCapacity": event.target.value,
                                })
                                this.state.saveUpdate(
                                    "maximumCapacity", event.target.value,
                                );
                            }}
                            required
                        />
                    </div>
                    <div className="col-lg-6">
                    </div>
                </div>

                <div className="form-group row">
                    <div className="col-lg-12">
                        <input
                            type="file"
                            value={this.state.energyDataFile}
                            className="form-control"
                            name={`energyDataFile`}
                            label="Energy Data File"
                            onChange={this.fileChangeHandler}
                            accept=".csv"
                        />
                    </div>
                </div>

                <Accordion>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                Optional information
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <SolarPlantOptionalForm saveUpdate={this.state.saveUpdate} />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </>
        );
    }
}